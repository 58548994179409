import React from "react";


import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";

import HeaderPanel from "components/user/stats/HeaderPanel";
import AvatarCardList from "components/session/AvatarCardList";
import PointingStats from "components/session/complete/PointingStats";
import ShowLoading from "components/processing/ShowLoading";

import useSetPageTitle from "hooks/use-set-page-title";
import { useGetSessionStatsQuery } from "api/sessions";

const MyStatsPage = () => {
  useSetPageTitle("My Stats");
  //@todo change name of poll query if not polling or do we leaving polling, but instead of 3 sec its 10 min to ensure we always have good data??????
  const { data, isLoading, isError } = useGetSessionStatsQuery({ id: "13" });

  const stats = !isLoading && data ? data : {};

  // console.log("SessionCompletePage", data);

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error Loading Session Data</Alert>
      </Grid>
    );
  }

  /**
   *
   *  // remove "start story"
   * // we will have a special drag/drop place for this
   *
   *
   *
   *  If pointing control
   * - can start session, start story
   * - can rearange story order
   * - multiple people...?
   *
   * if pointing
   * - can point when story is up
   * - can view stories
   * - can edit stories (but not order?)
   *
   * have stast for users accuracy???
   *
   */

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 1 }} justifyContent="center">
        <Grid item xs={10}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={10}>
              <HeaderPanel />
            </Grid>
            <Grid item xs={12}>
              <PointingStats data={stats} />
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" sx={{ fontSize: 28, mt: 4 }} variant="h4">
                Session Members
              </Typography>
              <AvatarCardList users={stats.users} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MyStatsPage;
