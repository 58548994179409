import React from "react";

import Avatar from "@mui/material/Avatar";

interface MyAvatarProps {
  src: string;
  width: number;
  height: number;
  inView: boolean;
}

const MyAvatar = ({ inView, src, width, height }: MyAvatarProps) => {
  return <>{inView && <Avatar sx={{ width, height }} src={src} />}</>;
};

export default MyAvatar;
