import React from "react";
import { Controller, Control, FieldValues, FormState } from "react-hook-form";

import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

import { OrgDialogForm } from "./types";

interface OrgDialogProps {
  control: Control<OrgDialogForm>;
  formState: FormState<FieldValues>;
}

const OrgDialogContent = ({ control, formState }: OrgDialogProps) => {
  const nameError = formState?.errors?.["name"]?.message?.toString() || "";

  return (
    <DialogContent classes={{ root: "p-24" }}>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mb-16"
            label="Org Name"
            autoFocus
            type="text"
            error={nameError != ""}
            helperText={nameError}
            variant="outlined"
            fullWidth
          />
        )}
      />
    </DialogContent>
  );
};

export default OrgDialogContent;
