import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useCompleteRegistrationMutation } from "api/common";
import SubmitButton from "components/inputs/SubmitButton";
import PasswordInput from "components/inputs/PasswordInput";
import { ErrorAPI } from "common/types";

type FormValues = {
  password: string;
  verify_password: string;
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  verify_password: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues: FormValues = {
  password: "",
  verify_password: "",
};

function OutputError(msg: string) {

  if (!msg || msg == "") {
    return <></>
  }
  if (msg == "invalid-token") {
    return (
      <Alert severity="error">
        Registration token was invalid. &nbsp;
        <Link className="font-normal" to="/complete-registration">
          Click Here
        </Link>&nbsp;
         to request a new one.
      </Alert>
    )
  }
  return <Alert severity="error">{msg}</Alert>
}

const SubmitCodeForm = () => {
  const routeParams = useParams();
  const { registrationCode } = routeParams;
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [formError, setFormError] = useState("");
  const { control, formState, handleSubmit } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [completeRegister, { isLoading }] = useCompleteRegistrationMutation();
  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      setWasSubmitted(true);
      await completeRegister({
        code: registrationCode,
        password: model.password,
      }).unwrap();
    } catch (e) {
      if ((e as ErrorAPI).data?.error == "invalid register token" ) {
        setFormError("invalid-token")
      } else {
        setFormError("Error setting new password");
      }
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Typography gutterBottom>Set account password</Typography>
      {wasSubmitted && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {OutputError(formError)}
            {!formError && <Alert severity="success">Password has been saved</Alert>}
          </Grid>
        </Grid>
      )}
      {!wasSubmitted && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PasswordInput
              control={control}
              label="Password"
              variant="standard"
              formState={formState}
              name="password"
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              control={control}
              label="Verify Password"
              variant="standard"
              formState={formState}
              name="verify_password"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <SubmitButton
                title="Submit"
                isLoading={isLoading}
                formState={formState}
                variant="contained"
                sx={{ mt: 3, ml: 1 }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SubmitCodeForm;
