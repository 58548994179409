import React from "react";

import Button from "@mui/material/Button";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StopCircle from "@mui/icons-material/StopCircle";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { useStartActiveStoryMutation, useStopActiveStoryMutation } from "api/sessions";

import { showSetPointsForm } from "store/dialogs";
import { Story } from "common/types";
import { useAppDispatch } from "store";

interface ButtonToOutput {
  story: Story;
}

const ButtonToOutput = ({ story }: ButtonToOutput) => {
  const dispatch = useAppDispatch();
  const [startStory] = useStartActiveStoryMutation();
  const [stopStory] = useStopActiveStoryMutation();

  const handleStop = async () => {
    try {
      await stopStory({ sessionId: story.sessionId });
    } catch (e) {
      console.log("error starting session story: ", e);
    }
  };

  const handleStart = async () => {
    try {
      await startStory({ sessionId: story.sessionId });
    } catch (e) {
      console.log("error stopping session story: ", e);
    }
  };

  const handleSetPoints = async () => {
    // @todo get points from popup ??
    // maybe move this api thre?

    dispatch(
      showSetPointsForm({
        sessionId: story.sessionId,
        storyId: story.storyId,
        min: story.minPoints || -1,
        max: story.maxPoints || -1,
        mode: story.modePoints || -1,
        median: story.medianPoints || -1,
        mean: story.meanPoints || -1,
      })
    );

    // try {
    //   await setStoryPoints({ sessionId, points });
    // } catch (e) {
    //   console.log("error setting session story points: ", e);
    // }
  };

  if (story.status == "pointed" || story.status == "finished") {
    return (
      <>
        <Button aria-label="Repoint" onClick={handleStart} startIcon={<RestartAltIcon />} variant="contained" color="secondary">
          Repoint
        </Button>
        {story.status == "finished" && (
          <Button aria-label="Set Points" onClick={handleSetPoints} startIcon={<CheckCircleOutlineIcon />} variant="contained" color="secondary" sx={{ ml: 2 }}>
            Save
          </Button>
        )}
      </>
    );
  }

  if (story.status == "pending") {
    return (
      <>
        <Button aria-label="Start" onClick={handleStart} startIcon={<AlarmOnIcon />} variant="contained">
          Start
        </Button>
        {/* <span>timer???</span> */}
      </>
    );
  }

  return (
    <Button aria-label="Start" onClick={handleStop} startIcon={<StopCircle />} variant="contained" color="error">
      Stop
    </Button>
  );
};

export default ButtonToOutput;
