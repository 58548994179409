import React from "react";
import { Controller, Control } from "react-hook-form";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";

import { SetPointsDialogForm } from "./types";

interface PointInputCustomProps {
  control: Control<SetPointsDialogForm>;
}

const PointInputCustom = ({ control }: PointInputCustomProps) => {
  return (
    <>
      <FormHelperText id="radio-custom">Other</FormHelperText>
      <FormControlLabel
        value="custom"
        control={<Radio name="usePoints" id="radio-custom" />}
        label={
          <FormControl>
            <Controller
              name="customPoints"
              control={control}
              render={({ field }) => {
                return <TextField {...field} size="small" />;
              }}
            />
          </FormControl>
        }
      />
    </>
  );
};

export default PointInputCustom;
