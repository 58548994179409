import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import ForgotPasswordLink from "./inputs/ForgotPasswordLink";
import SubmitButton from "components/inputs/SubmitButton";
import PasswordInput from "components/inputs/PasswordInput";
import RememberMeInput from "./inputs/RememberMeInput";
import EmailInput from "components/inputs/EmailInput";
import { useAppSelector } from "store";
import { useLoginMutation } from "api/auth";
import { clearLoginRedirect, getLastEmail, getLoginRedirect, setLastEmail } from "common/auth/storage";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email("You must enter a valid email").required("You must enter a email"),
  password: yup.string().required("Please enter your password.").min(8, "Password is too short - should be 8 chars minimum."),
});

type LoginFormValues = {
  email: string;
  password: string;
  remember: boolean;
};

const defaultValues: LoginFormValues = {
  email: getLastEmail() || "",
  password: "",
  remember: true,
};

interface LoginForm {
  token?: string;
}

/**
 * Render Login Form
 * @returns
 */
const LoginForm = (props: LoginForm) => {
  const navigate = useNavigate();
  const redirectPath = getLoginRedirect() || "/dashboard";
  const sessionToken = props?.token || "";

  const { control, formState, handleSubmit, setError } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: defaultValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const [showLogout, setShowLogout] = useState("");

  useEffect(() => {
    const didLogout = localStorage.getItem("logoutAction");
    if (didLogout === "show" || didLogout === "auto") {
      if (didLogout === "auto") {
        setShowLogout("You have logged out do to inactivity");
      } else {
        setShowLogout("You have successfully logged out!");
      }

      setTimeout(() => {
        setShowLogout("");
      }, 6000);

      localStorage.setItem("logoutAction", "hide");
    }
  }, [setShowLogout]);

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const [login, { isLoading, error: apiError }] = useLoginMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    if (model.remember) {
      setLastEmail(model.email);
    } else {
      // clear email if not set
      setLastEmail("");
    }

    try {
      await login({ ...model, sessionToken }).unwrap();
      // console.log("redirectPath", redirectPath)
      clearLoginRedirect();
      navigate(redirectPath, { replace: true });
    } catch (e) {
      setError("email", { type: "string", message: "Error logging in" });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(redirectPath, { replace: true });
      clearLoginRedirect();
    }
  }, [isLoggedIn, History]);

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      {apiError && <p>Login API Error</p>}
      {showLogout != "" && <p>{showLogout}</p>}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmailInput control={control} label="E-Mail" formState={formState} />
        </Grid>

        <Grid item xs={12}>
          <PasswordInput control={control} label="Password" formState={formState} />
        </Grid>

        <RememberMeInput control={control} />

        <ForgotPasswordLink />

        <Grid item xs={12}>
          <SubmitButton title="Login" formState={formState} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
