import React from 'react';

import Alert from "@mui/material/Alert";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import ShowLoading from "components/processing/ShowLoading";
import { useGetOrgTeamsListQuery } from 'api/common';
import { Team } from 'common/types';
import { formatDate } from 'common/helper'
import { useAppDispatch } from 'store';
import { showEditTeamForm, showTeamMemberListDialog, showInviteMemberDialog } from 'store/dialogs';

interface DisplayTeamProps { 
  orgId: number;
  isOpen: boolean ;
  onJoinTeam: (orgId: number, teamId: number) => void,
  onLeaveTeam: (orgId: number, teamId: number) => void
}

const DisplayTeams = ({ orgId, isOpen, onJoinTeam, onLeaveTeam }: DisplayTeamProps) => {
  const dispatch = useAppDispatch();

  const { data, isLoading, isError } = useGetOrgTeamsListQuery({ orgId, withExtra: true }, { skip: !isOpen });
  const teams: Team[] = data?.data || [];

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error Loading Session Stories</Alert>
      </Grid>
    );
  }

  return (
    <Box sx={{ margin: 1, px: 2, backgroundColor: "#f7f7f5" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Table size="small" aria-label="teams" sx={{ maxWidth: 600 }} >
        <TableHead>
          <TableRow>
            <TableCell align='center'>Team Name</TableCell>
            <TableCell align='center'>Users</TableCell>
            <TableCell align='center'>Created</TableCell>
            <TableCell align='center'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teams.map((entry) => (
            <TableRow key={`org-team-table-${entry.teamId}`}>
              <TableCell component="th" scope="row">
                {entry.name}
              </TableCell>
              <TableCell align='center'>{entry.totalUsers || 0}</TableCell>
              <TableCell align='center'>{formatDate(entry.createdAt as string)}</TableCell>
              <TableCell align="right">
                <Stack direction="row">

                  <Tooltip title="Edit Team">
                    <IconButton aria-label="Edit Team" onClick={() => { dispatch(showEditTeamForm({teamId: entry.teamId, orgId: orgId})) }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Members">
                    <IconButton aria-label="Members" onClick={() => { dispatch(showTeamMemberListDialog({orgId: entry.orgId, teamId: entry.teamId, name: entry.name})) }}>
                      <PeopleIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Invite/Add to Team">
                    <IconButton aria-label="Invite/Add to Team" onClick={() => { dispatch(showInviteMemberDialog({orgId: entry.orgId, teamId: entry.teamId, name: entry.name})) }}>
                      <PersonAddIcon />
                    </IconButton>
                  </Tooltip>
                  {entry.isMember && (
                    <Tooltip title="Leave Team">
                      <IconButton aria-label="Leave Team" onClick={() => { onLeaveTeam(orgId, entry.teamId) }}>
                        <LogoutIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!entry.isMember && (
                    <Tooltip title="Join Team">
                      <IconButton aria-label="Join Team" onClick={() => { onJoinTeam(orgId, entry.teamId) }}>
                        <LoginIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>

  )
}

export default DisplayTeams;