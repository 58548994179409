import React from "react";
import { useDrag, useDrop } from 'react-dnd'

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";

import ActionIcons from "./ActionIcons";
import { MoveableItem, Story } from "common/types";

const getItemStyle = (isDragging: boolean) => ({
  background: isDragging ? "#F7F7F7" : "white",
  border: isDragging ? "outset 1px silver" : "",
  opacity: isDragging ? 0 : 1
});

interface StoryListItemProps {
  story: Story;
  index: number;
  actionIcons: boolean;
  selected: boolean;
  onClick: (index: number) => void;
  findStory: (id: number) => number
  moveStory: (storyId: number, endIndex: number) => number
  saveMoveStory: (storyId: number, startIndex: number, endIndex: number) => void
}

function StoryListItem(props: StoryListItemProps) {
  const { story, index, actionIcons, onClick, selected, findStory, moveStory, saveMoveStory  } = props;
  const originalIndex = index;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "StoryListItem",
      item: { id: story.storyId, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveStory(droppedId, originalIndex)
        }
      },
    }),
    [story.storyId, originalIndex, moveStory],
  )

  const [, drop] = useDrop(
    () => ({
      accept:  "StoryListItem",
      hover({ id: draggedId }: MoveableItem) {
        if (draggedId !== story.storyId) {
          const overIndex = findStory(story.storyId)
          moveStory(draggedId, overIndex)
        }
      },
      drop({originalIndex}: MoveableItem) {
        const overIndex = findStory(story.storyId)
        saveMoveStory(story.storyId, originalIndex, overIndex)
      },
    }),
    [findStory, saveMoveStory],
  );

  return (
        <>
          <ListItem
            disableGutters={true}
            disablePadding={true}
            ref={(node) => drag(drop(node))}
            style={getItemStyle(isDragging)}
            secondaryAction={<ActionIcons show={actionIcons} />}
          >
            <ListItemButton selected={selected} onClick={() => onClick(index)} disableGutters>
              <ListItemIcon sx={{ pr: 1, minWidth: 10 }}>
                  <MenuIcon />
              </ListItemIcon>
              <ListItemText primary={story.name} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </>
  );
}

export default StoryListItem;
