import React, { useEffect, useState } from "react";
import { Control, FieldValues, FormState, UseFormSetValue } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import ShowLoading from "components/processing/ShowLoading";
import SelectInput from "components/inputs/SelectInput";
import { useGetMyOrgTeamsListQuery } from "api/common";
import { showCreateTeamForm } from "store/dialogs";
import { useAppDispatch } from "store";

import { Team, SelectOptions } from "common/types";

interface TeamSelect {
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
  orgId: number;
}

const TeamSelect = (props: TeamSelect) => {
  const dispatch = useAppDispatch();
  const orgId = props.orgId;
  const hasOrgId = orgId && orgId > 0;

  const { data: apiData, isLoading } = useGetMyOrgTeamsListQuery({ orgId, offset: 0, limit: 1000 }, { skip: !hasOrgId });

  const [teamData, setTeamData] = useState<SelectOptions[]>([]);
  const setValue = props.setValue;
  const hasTeam = teamData.length > 0;

  useEffect(() => {
    const data: Team[] = apiData?.data || [];
    let newTeamArray: SelectOptions[] = [];
    if (data.length > 0) {
      newTeamArray = data.map((v) => {
        return { label: v.name, id: v.teamId };
      });
    }
    setTeamData(newTeamArray);
  }, [apiData]);

  useEffect(() => {
    // only set teamId if we have something
    if (teamData.length > 0 && orgId == apiData?.data[0].orgId) {
      setValue("teamId", teamData[0].id);
    }
  }, [teamData]);

  useEffect(() => {
    // clear existing teamId
    if (apiData && apiData.data.length && apiData?.data[0].orgId != orgId) {
      setValue("teamId", "");
    }
  }, [orgId]);

  function handleAddTeam() {
    dispatch(showCreateTeamForm({ orgId }));
  }

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={8}>
        <SelectInput
          control={props.control}
          formState={props.formState}
          label="Team"
          variant="outlined"
          name="teamId"
          options={teamData}
          disabled={!hasTeam}
          defaultValue={""}
        />
      </Grid>
      <Grid item xs={3} alignItems="stretch" style={{ display: "flex" }} sx={{ mt: 1, mb: 2 }}>
        <Button variant="contained" color="secondary" onClick={handleAddTeam} disabled={!hasOrgId}>
          Add Team
        </Button>
      </Grid>
    </>
  );
};

export default TeamSelect;
