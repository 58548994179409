/**
 * Authentication Slice
 *
 */
import { createSlice } from "@reduxjs/toolkit";

import { api } from "api/common";
import { authApi } from "../api/auth";
import { UserType } from "./types";
import { getUserAvatarPath } from "common/helper";

const defaultUser: UserType = {
  profile: {
    name: "Guest",
    photoUrl: "assets/images/avatars/profile.jpg",
    email: "",
    needInit: true
  },
  notifications: {
    email: true,
    text: false,
    inApp: false,
    inBrowser: false,
  },
};

/* eslint-disable no-unused-vars */
const slice = createSlice({
  name: "user",
  initialState: defaultUser,
  reducers: {
    setUserProfile(state, { payload }) {
      state.profile.name = payload.name;
      state.profile.photoUrl = payload.photoUrl;
      state.profile.email = payload.email;
      state.profile.needInit = false;
    },
    setUserNotifications(state, { payload }) {
      state.notifications.email = payload.email;
      state.notifications.text = payload.text;
      state.notifications.inApp = payload.inApp;
      state.notifications.inBrowser = payload.inBrowser;
    },
    unsetUserProfile(state) {
      state.profile = {
        name: "Guest",
        photoUrl: "assets/images/avatars/profile.jpg",
        email: "",
        needInit: false
      };
      state.notifications = {
        email: true,
        text: false,
        inApp: false,
        inBrowser: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getMyProfile.matchFulfilled, (state, { payload }) => {
      state.profile.name = payload.name;
      state.profile.photoUrl = getUserAvatarPath(payload.avatarId);
      state.profile.email = payload.email;
      state.profile.needInit = false;
    });


    builder.addMatcher(api.endpoints.getMyNotifications.matchFulfilled, (state, { payload }) => {
      state.notifications.email = payload.email;
      state.notifications.text = payload.text;
      state.notifications.inApp = payload.inApp;
      state.notifications.inBrowser = payload.inBrowser;
    });

    // builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
    //   state.profile.email = payload?.email;
    // });

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.profile = {
        name: "Guest",
        photoUrl: "assets/images/avatars/profile.jpg",
        email: "",
        needInit: false,
      };
      state.notifications = {
        email: true,
        text: false,
        inApp: false,
        inBrowser: false,
      };
    });
    // alwats remove token data, even if api request to logout fails
    builder.addMatcher(authApi.endpoints.logout.matchRejected, (state) => {
      state.profile = {
        name: "Guest",
        photoUrl: "assets/images/avatars/profile.jpg",
        email: "",
        needInit: false
      };
      state.notifications = {
        email: true,
        text: false,
        inApp: false,
        inBrowser: false,
      };
    });
  },
});
/* eslint-enable no-unused-vars */

// see if we should export the actions another way
export const setUserProfile = slice.actions.setUserProfile;
export const setUserNotifications = slice.actions.setUserNotifications;
export const unsetUserProfile = slice.actions.unsetUserProfile;
export const userActions = slice.actions;
export default slice.reducer;
