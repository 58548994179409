import * as React from "react";
import { useParams, Link } from "react-router-dom";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import StatsOverview from "./tabs/StatsOverview";
import StatsParticipants from "./tabs/StatsParticipants";
import StatsStories from "./tabs/StatsStories";

import { SessionStatsType } from "common/types";

interface PointingStatsProps {
  data: SessionStatsType;
}

function getStatView(tab: string | undefined, data: SessionStatsType) {
  switch (tab) {
    case "stories":
      return <StatsStories session={data.session} data={data.stories} />;
    case "participants":
      return <StatsParticipants session={data.session} data={data.users} />;
    case "overview":
    default:
      return <StatsOverview session={data.session} data={data.overview} />;
  }
}

const style = {
  // "&:hover": { color: "secondary.main" },
  // backgroundColor: "#ccc",
  // color: "black",
  // mx: 1,
  // borderRadius: 10,
  // border: "none",
};

export default function PointingStats({ data }: PointingStatsProps) {
  const routeParams = useParams();
  const { tab } = routeParams;
  const currentTab = tab || "overview";

  // console.log("session", data);
  const routePrefix = "/sessions/" + data.session.token;

  if (data?.session?.totalPointed === 0) {
    console.log("No stories pointed within session");
    return (
      <>
        <p>No stories pointed within session</p>
      </>
    );
  }

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Box>
        <AppBar position="relative">
          <Tabs value={currentTab} indicatorColor="secondary" textColor="inherit" aria-label="pointing stats categories">
            <Tab label="Overview" sx={style} value="overview" to={`${routePrefix}/overview`} component={Link} />
            <Tab label="Breakdown: Stories" sx={style} value="stories" to={`${routePrefix}/stories`} component={Link} />
            <Tab label="Breakdown: Participants" sx={style} value="participants" to={`${routePrefix}/participants`} component={Link} />
          </Tabs>
        </AppBar>
      </Box>
      {getStatView(currentTab, data)}
    </Box>
  );
}
