import React, { useState } from "react";
import { motion } from "framer-motion";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { SortDirection } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ShowLoading from "components/processing/ShowLoading";
import ListHeader from "./ListHeader";

import { useStoryListQuery } from "api/sessions";
import { HeaderSortOrder, QueryOffsetLimit, Story, StoryTableColumnEntry } from "common/types";

interface BaseStoryListProps {
  columns: StoryTableColumnEntry[];
  status?: string;
  limit?: number;
  offset?: number;
  showSort?: boolean;
  showPaging?: boolean;
  isMissingSession?: boolean;
}

const initialOrder: HeaderSortOrder = {
  direction: "desc",
  id: "updatedAt",
};

const BaseStoryList = ({ columns, status = "", limit = 20, offset = 0, showPaging = true, showSort = true, isMissingSession = false }: BaseStoryListProps) => {
  const [currentPos, setCurrentPos] = useState<QueryOffsetLimit>({ limit, offset });
  const [myOrder, setMyOrder] = useState(initialOrder);
  const [page, setPage] = useState(0);

  function handleSort(property: string) {
    const id = property;
    let direction: SortDirection = "desc";

    if (myOrder.id === property && myOrder.direction === "desc") {
      direction = "asc";
    }

    setMyOrder({
      direction,
      id,
    });
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setCurrentPos({
      limit: currentPos.limit,
      offset: currentPos.limit * newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setCurrentPos({
      limit: parseInt(event.target.value, limit),
      offset: 0,
    });
  };

  const { data, isError, isLoading } = useStoryListQuery(
    { status, sort: myOrder.id, sortDir: myOrder.direction, limit: currentPos.limit, offset: currentPos.offset, isMissingSession },
    { refetchOnMountOrArgChange: true }
  );

  const stories: Story[] = data?.data || [];
  const totalStories: number = data?.total || 0;

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error loading Stories</Alert>
      </Grid>
    );
  }

  if (stories.length === 0) {
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 } }} className="flex flex-1 items-center justify-center h-full">
        <Typography color="textSecondary" variant="h5">
          There are no stories
        </Typography>
      </motion.div>
    );
  }

  return (
    <div className="w-full flex flex-col">
      <TableContainer>
        <Table aria-labelledby="Story List">
          <ListHeader columns={columns} order={myOrder} onRequestSort={handleSort} showSort={showSort} />
          <TableBody>
            {stories.map((n) => {
              return (
                <TableRow key={n.storyId}>
                  {columns.map((c) => {
                    if (c?.custom != null) {
                      return (
                        <TableCell key={`col-${n.storyId}-${c.id}`} align={c.align}>
                          {c.custom(n)}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={`col-${n.storyId}-${c.id}`} align={c.align}>
                        {n[c.id as keyof Story]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {showPaging && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={totalStories}
          rowsPerPage={currentPos.limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      )}
    </div>
  );
};

export default BaseStoryList;
