import { api, addPaging } from "./common";

export const sessionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    /////////////////////////////////////////////
    //////  PointingSession & Stories  /////////
    /////////////////////////////////////////////
    createSession: builder.mutation({
      query: (data) => ({
        url: "sessions",
        method: "POST",
        body: { name: data.name, orgId: parseInt(data.orgId), teamId: parseInt(data.teamId) },
      }),
      invalidatesTags: ["PointingSession"],
    }),
    updateSession: builder.mutation({
      query: ({ sessionId, data }) => ({
        url: "sessions/" + sessionId,
        method: "PATCH",
        body: {
          ...data,
        },
      }),
      invalidatesTags: ["PointingSession"],
    }),
    
    getSessionPreview: builder.query({
      query: ({ token }) => "sessions/preview/" + token,
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["PointingSession"],
    }),
    getSessionByID: builder.query({
      query: ({ sessionId }) => "sessions/" + sessionId,
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["PointingSession"],
    }),
    getSessionByToken: builder.query({
      query: ({ token }) => "sessions/token/" + token,
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["PointingSession"],
    }),
    allSessionData: builder.query({
      query: ({ id }) => "sessions/" + id + "/all",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["PointingSession"],
    }),
    getSessionStats: builder.query({
      query: ({ id }) => "sessions/" + id + "/stats",
      keepUnusedDataFor: 1800, // cache for 30 minutes
      providesTags: ["SessionStats"],
    }),
    getSimilarSessions: builder.query({
      query: ({ sessionId, status, limit, offset, sort, sortDir }) => "sessions/" + sessionId + "/similar" + addPaging(offset, limit) + `&status=${status}&sort=${sort}&sortDir=${sortDir}`,
      keepUnusedDataFor: 1800, // cache for 30 minutes
      providesTags: ["PointingSession"],
    }),
    createSessionStory: builder.mutation({
      query: ({ sessionId, data }) => ({
        url: "sessions/" + sessionId + "/stories",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SessionStoryList"],
    }),
    editSessionStory: builder.mutation({
      query: ({ sessionId, storyId, data }) => ({
        url: "sessions/" + sessionId + "/stories/" + storyId,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: ["Stories", "SessionStoryList", "PointingSession"],
    }),
    importSessionStories: builder.mutation({
      query: ({ sessionId, data }) => ({
        url: "sessions/" + sessionId + "/stories/import",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stories", "SessionStoryList", "PointingSession"],
    }),
    listSessions: builder.query({
      query: ({ status, limit, offset, sort, sortDir }) => {
        let path = "sessions?";
        if (status != null && status !== "") {
          path += "status=" + status + "&";
        }
        if (offset != null && offset !== "") {
          path += "offset=" + offset + "&";
        }
        if (limit != null && limit !== "") {
          path += "limit=" + limit + "&";
        }
        if (sort != null && sort !== "") {
          path += "sort=" + sort + "&";
        }
        if (sortDir != null && sortDir !== "") {
          path += "sortDir=" + sortDir + "&";
        }

        return path;
      },
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["PointingSession"],
    }),
    listSessionStories: builder.query({
      query: (sessionId) => "sessions/" + sessionId + "/stories",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["SessionStoryList", "PointingSession"],
    }),
    listSessionImportStories: builder.query({
      query: ({sessionId, offset, limit, sort, sortDir, isMissingSession}) => "sessions/" + sessionId + "/stories/import" + addPaging(offset, limit) + `&sort=${sort}&sortDir=${sortDir}` + (isMissingSession ? "&isMissingSession=1" : ""),
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["SessionStoryList", "PointingSession"],
    }),
   
    changeSessionStoryOrder: builder.mutation({
      query: ({ sessionId, data }) => ({
        url: "sessions/" + sessionId + "/stories/change-order",
        method: "POST",
        body: data,
      }),
      //invalidatesTags: ["SessionStoryList", "PointingSession"],
      // async onQueryStarted({ sessionId }, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data: updatedPost } = await queryFulfilled;
      //     dispatch(
      //       api.util.updateQueryData("listSessionStories", sessionId, (draft) => {
      //         Object.assign(draft, updatedPost);
      //       })
      //     );
      //   } catch {
      //     console.log("could not update cache!");
      //   }
      // },
    }),

    sessionStart: builder.mutation({
      query: ({ sessionId, storyId }) => ({
        url: "sessions/" + sessionId + "/start",
        method: "POST",
        body: { storyId: storyId || 0 },
      }),
      invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    sessionPause: builder.mutation({
      query: ({ sessionId }) => ({
        url: "sessions/" + sessionId + "/pause",
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    sessionClose: builder.mutation({
      query: ({ sessionId, newSessionId }) => ({
        url: "sessions/" + sessionId + "/close",
        method: "POST",
        body: { newSessionId: newSessionId || 0 },
      }),
      invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    activeStorySet: builder.mutation({
      query: ({ sessionId, storyId }) => ({
        url: "sessions/" + sessionId + "/stories/set-active",
        method: "POST",
        body: { storyId: storyId || 0 },
      }),
      invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    activeStoryClear: builder.mutation({
      query: ({ sessionId }) => ({
        url: "sessions/" + sessionId + "/stories/clear-active",
        method: "POST",
      }),
      invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    startActiveStory: builder.mutation({
      query: ({ sessionId }) => ({
        url: "sessions/" + sessionId + "/stories/start-active",
        method: "POST",
      }),
      //invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    stopActiveStory: builder.mutation({
      query: ({ sessionId }) => ({
        url: "sessions/" + sessionId + "/stories/stop-active",
        method: "POST",
      }),
      //invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    pointActiveStory: builder.mutation({
      query: ({ sessionId, points }) => ({
        url: "sessions/" + sessionId + "/stories/point-active",
        method: "POST",
        body: { points },
      }),
      //invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),

    setStoryPointed: builder.mutation({
      query: ({ sessionId, points }) => ({
        url: "sessions/" + sessionId + "/stories/set-pointed",
        method: "POST",
        body: { points: parseInt(points) },
      }),
      invalidatesTags: ["SessionStoryList", "PointingSession"],
    }),
    // stories
    createStory: builder.mutation({
      query: (data) => ({
        url: "sessions/stories",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stories", "SessionStoryList", "PointingSession"],
    }),
    editStory: builder.mutation({
      query: ({ storyId, data }) => ({
        url: "sessions/stories/" + storyId,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: ["Stories", "SessionStoryList", "PointingSession"],
    }),

    getStory: builder.query({
      query: ({ storyId }) => "sessions/stories/" + storyId,
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Stories", "PointingSession"],
    }),

    sessionStoryList: builder.query({
      query: ({ sessionId, offset, limit }) => "sessions/stories?sessionId=" + sessionId + addPaging(offset, limit),
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Stories", "PointingSession"],
    }),

    setUserToObserver: builder.mutation({
      query: ({ sessionId }) => ({
        url: "sessions/" + sessionId + "/participants/set-observer",
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["PointingSession"], // test if needed?
    }),

    setUserToParticipant: builder.mutation({
      query: ({ sessionId }) => ({
        url: "sessions/" + sessionId + "/participants/set-participant",
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["PointingSession"], // test if needed?
    }),

    getQuickStats: builder.query({
      query: () => "sessions/stats",
      keepUnusedDataFor: 1800, // cache for 30 minutes
      providesTags: ["SessionStats"],
    }),

    storyList: builder.query({
      query: ({ offset, limit, sort, sortDir, status, isMissingSession }) =>
        "sessions/stories" + addPaging(offset, limit) + `&status=${status}&sort=${sort}&sortDir=${sortDir}` + (isMissingSession ? "&isMissingSession=1" : ""),
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Stories", "PointingSession"],
    }),
  }),
});

export const {
  useActiveStoryClearMutation,
  useActiveStorySetMutation,
  useStartActiveStoryMutation,
  useStopActiveStoryMutation,
  useSetStoryPointedMutation,
  useCreateSessionMutation,
  useUpdateSessionMutation,
  useGetSessionByIDQuery,
  useGetSessionByTokenQuery,
  useGetStoryQuery,
  useCreateStoryMutation,
  useCreateSessionStoryMutation,
  useChangeSessionStoryOrderMutation,
  useEditSessionStoryMutation,
  useEditStoryMutation,
  useImportSessionStoriesMutation,
  useListSessionStoriesQuery,
  useListSessionImportStoriesQuery,
  useSessionStoryListQuery,
  useListSessionsQuery,
  usePointActiveStoryMutation,
  useAllSessionDataQuery,
  useGetSessionStatsQuery,
  useGetQuickStatsQuery,
  useSessionStartMutation,
  useSessionPauseMutation,
  useSessionCloseMutation,
  useSetUserToObserverMutation,
  useSetUserToParticipantMutation,
  useStoryListQuery,
  useGetSimilarSessionsQuery,
  useGetSessionPreviewQuery,
} = sessionApi;
