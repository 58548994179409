import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import PendingStoriesList from "../PendingStoriesList";
import CompletedStoriesList from "../CompletedStoriesList";

const PointedStoriesTab = () => {
  return (
    <>
      <Typography variant="h6">Pointed Stories</Typography>
      <Box sx={{ width: "100%", mt: 4 }}>
        <CompletedStoriesList />
      </Box>
    </>
  );
};

export default PointedStoriesTab;
