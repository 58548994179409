import React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";


import { HeaderSortOrder, StoryTableColumnEntry } from "common/types";
import { getTableDirection } from "common/helper";


interface ListHeaderProps {
  columns: StoryTableColumnEntry[];
  order: HeaderSortOrder;
  showSort: boolean;
  onRequestSort: (id: string) => void;
}

function ListHeader({columns, onRequestSort, order, showSort }: ListHeaderProps) {
  const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        {columns.map((entry) => {
          const shouldSort = showSort && entry.sort;

          return (
            <TableCell
              className="p-4 md:p-16"
              key={entry.id}
              align={entry.align}
              padding={entry.disablePadding ? "none" : "normal"}
              sortDirection={order.id === entry.id ? order.direction : undefined}
            >
              {shouldSort && (
                <Tooltip
                  title="Sort"
                  placement={entry.align === "right" ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={order.id === entry.id}
                    direction={getTableDirection(order.direction)}
                    onClick={createSortHandler(entry.id)}
                  >
                    {entry.label}
                  </TableSortLabel>
                </Tooltip>
              )}
              {!shouldSort && (
                <>
                  {entry.label}
                </>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default ListHeader;
