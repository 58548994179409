import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PendingStoriesList from "../PendingStoriesList";


const PendingStoriesTab = () => {
  return (
    <>
      <Typography variant="h6">Pending Stories</Typography>
      <Box sx={{ width: "100%", mt: 4 }}>

        <PendingStoriesList />
      </Box>
    </>
  )
}

export default PendingStoriesTab