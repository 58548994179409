import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";


import ShowLoading from "components/processing/ShowLoading";
import { useGetSimilarSessionsQuery } from "api/sessions";
import { SessionData, SelectOptions } from "common/types";

interface SessionSelectProps {
  sessionId: number;
  onChange: (value: number) => void
}

const defaultSelectOptions : SelectOptions[] = [
  {
    id: 0,
    label: "No Session"
  },
]

const SessionSelection = ({ sessionId, onChange: handleChange }: SessionSelectProps) => {
  const [sessionOptions, setSessionOptions] = useState<SelectOptions[]>(defaultSelectOptions);
  const { data: apiData, isLoading } = useGetSimilarSessionsQuery({ sessionId, sort: "name", limit: 50, status: "pending" }, {skip: (!sessionId)});
  

  useEffect(() => {
    const data: SessionData[] = apiData?.data || [];
    let newSessionOptions: SelectOptions[] = [];
    if (data?.[0]) {
      newSessionOptions = data.map((v) => {
        return { label: v.name, id: v.sessionId };
      });
    }
    setSessionOptions([...defaultSelectOptions, ...newSessionOptions]);
  }, [apiData]);

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={8}>
      <FormControl fullWidth>
        <InputLabel id="move-to-session">Move pending stories to</InputLabel>
          <Select
            labelId="move-to-session"
            label="Move stories to"
            defaultValue="0"
            onChange={(event: SelectChangeEvent) => { handleChange(parseInt(event.target.value)) }}
          >
            {sessionOptions.map((option: SelectOptions) => (
              <MenuItem value={option.id} key={`move-to-session-opt-${option.id}`}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default SessionSelection;
