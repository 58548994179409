import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import useSetPageTitle from "hooks/use-set-page-title";
import ForgotPasswordForm from "components/auth/ForgotPasswordForm";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: 300,
}));


const LoginPage = () => {
  useSetPageTitle("Login");

  
  return (
    <Box sx={{ flexGrow: 1, pt: 10 }} >
      <Grid container spacing={4}  >
        <Grid item xs={3}> </Grid>
        <Grid item xs={6}>
          <Item elevation={3}>
            Forgot Password
            <ForgotPasswordForm />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
