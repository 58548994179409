import React from "react";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import PointingAvatarStatus from "../active/PointingAvatarStatus";
import { random } from "lodash";
import { SessionUser } from "common/types";

interface PointingAvatarProps {
  user: SessionUser;
  showPoints: boolean;
}

/**
 * Output an avatar for pointing
 * @param {object} user
 * @returns
 */
const AvatarCard = ({ user, showPoints }: PointingAvatarProps) => {
  const avatarId = user?.avatar || random(1, 96);

  const avatarImage = "/static/images/avatars/" + avatarId + ".png";

  return (
    <Grid item md={2}>
      <Card elevation={0} sx={{backgroundColor: "transparent"}}>
        <CardContent>
            <Badge
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={<PointingAvatarStatus status={user.status} showPoints={showPoints} points={user.points} />}
            >
          <Stack alignContent="center" textAlign="right" direction="column">
              <Avatar  alt={user.name} sx={{ width: 82, height: 82, textAlign: 'center' }} src={avatarImage} />
            <Typography sx={{textAlign: 'center'}}>{user.name}</Typography>
          </Stack>
            </Badge>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AvatarCard;
