//import permissions from "configs/permissions";

import { AuthRoles } from "common/types";

//"admin", "power user", "user", "read only"

/**
 * Authorization Roles
 */
const authRoles: AuthRoles = {
  owner: ["owner", "admin", "power user", "user", "read only", "anyUser", "all"],
  admin: ["admin", "power user", "user", "read only", "anyUser", "all"],
  powerUser: ["power user", "user", "read only", "anyUser", "all"],
  user: ["user", "read only", "anyUser", "all"],
  readOnly: ["read only"],
  guest: ["guest", "anyUser", "all"],
  public: ["public", "all"],
};

export default authRoles;
