import React from "react";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import OrgMemberTable from "./OrgMembersTable";
import ShowLoading from "components/processing/ShowLoading";
import { useAppDispatch } from "store";
import { useTeamAddUsersMutation, useTeamEligibleMembersListQuery } from "api/common";
import { Member } from "common/types";
import { TabProps } from "./types";
import { hideInviteMemberDialog } from "store/dialogs";

const OrgMembersTab = ({ orgId, teamId }: TabProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const [addUsers, { isLoading: isAdding }] = useTeamAddUsersMutation();
  const { data, isLoading, isError } = useTeamEligibleMembersListQuery({ orgId, teamId }, { skip: !(orgId > 0 && teamId > 0) });
  const usesrs: Member[] = data?.data || [];

  const handleSubmit = async () => {
    const data = {
      orgId,
      teamId,
      userIds: selected,
    };

    try {
      await addUsers(data).unwrap();
      enqueueSnackbar("Successfully Added Users", {
        variant: "success",
      });

      dispatch(hideInviteMemberDialog());
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error adding users", {
        variant: "error",
      });
    }
    // @todo should we close popup ??
    //closeComposeDialog();
  };

  if (isLoading || isAdding) {
    return <ShowLoading />;
  }

  if (isError) {
    return <>Error fetching members</>;
  }

  return (
    <>
      <OrgMemberTable users={usesrs} setSelected={setSelected} selected={selected} />
      <Box>
        <Button variant="contained" color="secondary" type="submit" disabled={selected.length == 0} onClick={handleSubmit}>
          Add Selected Users
        </Button>
      </Box>
    </>
  );
};

export default OrgMembersTab;
