/**
 * Dialog Slices
 *
 */
import { createSlice } from "@reduxjs/toolkit";
import { DialogStateType } from "./types";

// data for dialogs
const initialState: DialogStateType = {
  userForm: {
    show: false,
    userId: 0,
    account: {
      id: 0,
      name: "",
      access: "",
    },
  },
  orgForm: {
    show: false,
    teamId: 0,
    orgId: 0,
  },
  teamForm: {
    show: false,
    teamId: 0,
    orgId: 0,
  },
  setPointsForm: {
    show: false,
    sessionId: 0,
    storyId: 0,
    min: -1,
    max: -1,
    mean: -1,
    median: -1,
    mode: -1,
  },
  storyForm: {
    show: false,
    sessionId: 0,
    storyId: 0,
    editSession: false,
  },
  importStoryForm: {
    show: false,
    sessionId: 0,
  },
  archiveSession: {
    show: false,
    sessionId: 0,
  },
  memberListDialog: {
    show: false,
    orgId: 0,
    teamId: 0,
    title: ""
  },
  inviteMemberDialog: {
    show: false,
    orgId: 0,
    teamId: 0,
    title: ""
  }
};

const slice = createSlice({
  name: "dialogs",
  initialState: initialState,
  reducers: {
    /**
     * Show Create User Form Dialog
     * @param {number} accountId Optional Id for account to add user to
     */
    showCreateUserForm(state, { payload }) {
      state.userForm.show = true;
      state.userForm.userId = 0;
      state.userForm.account.id = payload?.accountId || 0;
      state.userForm.account.name = payload?.accountName;
      state.userForm.account.access = payload?.accountAccess;
    },

    /**
     * Show Edit User Form Dialog
     * @param {number} acocuntId Optional Id for account to add user to
     */
    showEditUserForm(state, { payload }) {
      state.userForm.show = true;
      state.userForm.userId = payload.userId;
      state.userForm.account.id = payload?.accountId;
      state.userForm.account.name = payload?.accountName;
      state.userForm.account.access = payload?.accountAccess;
    },

    /**
     * Hide User Form Dialog
     */
    hideUserForm(state) {
      state.userForm.show = false;
      state.userForm.userId = 0;
      state.userForm.account.id = 0;
      state.userForm.account.name = "";
      state.userForm.account.access = "";
    },

    /**
     * Show Create Org Form Dialog
     */
    showCreateOrgForm(state) {
      state.orgForm.show = true;
      state.orgForm.orgId = 0;
    },

    /**
     * Show Edit Org Form Dialog
     * @param {number} orgId Optional Id for account to add user to
     */
    showEditOrgForm(state, { payload }) {
      state.orgForm.show = true;
      state.orgForm.orgId = payload.orgId;
    },

    /**
     * Hide Org Form Dialog
     */
    hideOrgForm(state) {
      state.orgForm.show = false;
      state.orgForm.orgId = 0;
    },

    /**
     * Show Create Team Form Dialog
     */
    showCreateTeamForm(state, { payload }) {
      state.teamForm.show = true;
      state.teamForm.teamId = 0;
      state.teamForm.orgId = payload.orgId;
    },

    /**
     * Show Edit Team Form Dialog
     * @param {number} teamId Optional Id for account to add user to
     */
    showEditTeamForm(state, { payload }) {
      state.teamForm.show = true;
      state.teamForm.teamId = payload.teamId;
      state.teamForm.orgId = payload.orgId;
    },

    /**
     * Hide Team Form Dialog
     */
    hideTeamForm(state) {
      state.teamForm.show = false;
      state.teamForm.teamId = 0;
      state.teamForm.orgId = 0;
    },

    /**
     * Show Create Team Form Dialog
     */
    showSetPointsForm(state, { payload }) {
      state.setPointsForm.show = true;
      state.setPointsForm.sessionId = payload.sessionId;
      state.setPointsForm.storyId = payload.storyId;
      state.setPointsForm.min = payload.min;
      state.setPointsForm.max = payload.max;
      state.setPointsForm.mean = payload.mean;
      state.setPointsForm.median = payload.median;
      state.setPointsForm.mode = payload.mode;
    },

    /**
     * Hide Team Form Dialog
     */
    hideSetPointsForm(state) {
      state.setPointsForm.sessionId = 0;
      state.setPointsForm.storyId = 0;
      state.setPointsForm.show = false;
      state.setPointsForm.min = -1;
      state.setPointsForm.max = -1;
      state.setPointsForm.mean = -1;
      state.setPointsForm.median = -1;
      state.setPointsForm.mode = -1;
    },

    /**
     * Show New session story Form Dialog
     */
    showNewStoryForm(state) {
      state.storyForm.show = true;
      state.storyForm.sessionId = 0;
      state.storyForm.storyId = 0;
      state.storyForm.editSession = false;
    },
    /**
       * Show New session story Form Dialog
       */
    showNewSessionStoryForm(state, { payload }) {
      state.storyForm.show = true;
      state.storyForm.sessionId = payload.sessionId;
      state.storyForm.storyId = 0;
      state.storyForm.editSession = false;
    },
    
    /**
     * Show Edit session story Form Dialog
     */
    showEditStoryForm(state, { payload }) {
      state.storyForm.show = true;
      state.storyForm.sessionId = 0;
      state.storyForm.storyId = payload.storyId;
      state.storyForm.editSession = payload?.editSession || false;
    },

    /**
     * Show Edit session story Form Dialog
     */
    showEditSessionStoryForm(state, { payload }) {
      state.storyForm.show = true;
      state.storyForm.sessionId = payload.sessionId;
      state.storyForm.storyId = payload.storyId;
      state.storyForm.editSession = payload?.editSession || false;
    },

    /**
     * Hide Team Form Dialog
     */
    hideStoryForm(state) {
      state.storyForm.sessionId = 0;
      state.storyForm.storyId = 0;
      state.storyForm.show = false;
      state.storyForm.editSession = false;
    },
    
    /**
     * Show Import story to sesion  Form Dialog
     */
    showImportStoryToSession(state, { payload }) {
      state.importStoryForm.show = true;
      state.importStoryForm.sessionId = payload.sessionId;
    },

    /**
     * Hide import story to sesion Form Dialog
     */
    hideImportStoryToSession(state) {
      state.importStoryForm.sessionId = 0;
      state.importStoryForm.show = false;
    },
    
    /**
     * Show archive session dialog
     */
    showArchiveSession(state, { payload }) {
      state.archiveSession.show = true;
      state.archiveSession.sessionId = payload.sessionId;
    },

    /**
     * Hide archive session dialog
     */
    hideArchiveSession(state) {
      state.archiveSession.sessionId = 0;
      state.archiveSession.show = false;
    },
    
    /**
     * Show org list dialog
     */
    showOrgMemberListDialog(state, { payload }) {
      state.memberListDialog.show = true;
      state.memberListDialog.orgId = payload.orgId;
      state.memberListDialog.title = `${payload.name} Members`;
    },

    /**
     * Show team list dialog
     */
    showTeamMemberListDialog(state, { payload }) {
      state.memberListDialog.show = true;
      state.memberListDialog.orgId = payload.orgId;
      state.memberListDialog.teamId = payload.teamId;
      state.memberListDialog.title = `${payload.name} Team Members`;
    },

    /**
     * Hide member list dialog
     */
    hideMemberListDialog(state) {
      state.memberListDialog.show = false;
      state.memberListDialog.orgId = 0;
      state.memberListDialog.teamId = 0;
      state.memberListDialog.title = "";
    },
    

    /**
     * Show invite team dialog
     */
    showInviteMemberDialog(state, { payload }) {
      state.inviteMemberDialog.show = true;
      state.inviteMemberDialog.orgId = payload.orgId;
      state.inviteMemberDialog.teamId = payload.teamId;
      state.inviteMemberDialog.title = `Invite to ${payload.name} Team`;
    },

    /**
     * Hide invite member dialog
     */
    hideInviteMemberDialog(state) {
      state.inviteMemberDialog.show = false;
      state.inviteMemberDialog.orgId = 0;
      state.inviteMemberDialog.teamId = 0;
      state.inviteMemberDialog.title = "";
    },
  },
});

// see if we should export the actions another way
export const dialogActions = slice.actions;
export const showCreateUserForm = slice.actions.showCreateUserForm;
export const showEditUserForm = slice.actions.showEditUserForm;
export const hideUserForm = slice.actions.hideUserForm;
export const showCreateOrgForm = slice.actions.showCreateOrgForm;
export const showEditOrgForm = slice.actions.showEditOrgForm;
export const hideOrgForm = slice.actions.hideOrgForm;
export const showCreateTeamForm = slice.actions.showCreateTeamForm;
export const showEditTeamForm = slice.actions.showEditTeamForm;
export const hideTeamForm = slice.actions.hideTeamForm;
export const showSetPointsForm = slice.actions.showSetPointsForm;
export const hideSetPointsForm = slice.actions.hideSetPointsForm;
export const showNewStoryForm = slice.actions.showNewStoryForm;
export const showNewSessionStoryForm = slice.actions.showNewSessionStoryForm;
export const showEditStoryForm = slice.actions.showEditStoryForm;
export const showEditSessionStoryForm = slice.actions.showEditSessionStoryForm;
export const showImportStoryToSession = slice.actions.showImportStoryToSession;
export const hideImportStoryToSession = slice.actions.hideImportStoryToSession;
export const showArchiveSession = slice.actions.showArchiveSession;
export const hideArchiveSession = slice.actions.hideArchiveSession;
export const hideStoryForm = slice.actions.hideStoryForm;
export const hideMemberListDialog = slice.actions.hideMemberListDialog;
export const showTeamMemberListDialog = slice.actions.showTeamMemberListDialog;
export const showOrgMemberListDialog = slice.actions.showOrgMemberListDialog;
export const showInviteMemberDialog = slice.actions.showInviteMemberDialog;
export const hideInviteMemberDialog = slice.actions.hideInviteMemberDialog;
export default slice.reducer;
