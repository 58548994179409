import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth";
import dialogsReducer from "./dialogs";
import userReducer from "./user";
import sessionReducer from "./session";
import { api } from "../api/common";

const rootReducer = combineReducers({
  auth: authReducer,
  dialogs: dialogsReducer,
  user: userReducer,
  session: sessionReducer,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
