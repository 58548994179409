import React, {  useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useChangePasswordMutation } from "api/common";
import PasswordInput from "components/inputs/PasswordInput";

const schema = yup.object().shape({
  password: yup.string().required("current password is required"),
  newPassword: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  verifyPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

// see if we can default these from previous used?  or if we still need useEffect
const defaultValues = {
  password: "",
  newPassword: "",
  verifyPassword: "",
};


const PasswordForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [formError, setFormError] = useState("");

  const [updatePassword, { error: apiError }] = useChangePasswordMutation();

  const { control, formState, handleSubmit } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });


  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    console.log("model", model)
    try {
      setWasSubmitted(true);
      await updatePassword(model).unwrap();
      enqueueSnackbar("Password Successfully Changed", {
        variant: "success",
      });
      setWasSubmitted(false);
      setFormError("")
      //history.push("/create-session/" + sessionData.token + "/1");
    } catch (e) {
      setFormError("Error updating password");
      setWasSubmitted(false);
    }
  };


  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
      <Grid container spacing={3} >
        <Grid item xs={12}>
          {formError && <Alert severity="error">{formError}</Alert>}
          {(!formError && apiError) && <Alert severity="error">Error updating Password</Alert>}
          {(wasSubmitted && !formError && !apiError) && <Alert severity="info">Updating Password</Alert>}
        </Grid>
      </Grid>
      {!wasSubmitted && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PasswordInput control={control} label="Current Password" variant="outlined" formState={formState} name="password" />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput control={control} label="New Password" variant="outlined" formState={formState} name="newPassword" />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput control={control} label="Verify Password" variant="outlined" formState={formState} name="verifyPassword" />
            </Grid>

          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" type="submit" color="success" sx={{ mt: 3, ml: 1 }}>
              Update Password
            </Button>
          </Box>
        </>
      )}
    </Box>
  );

}

export default PasswordForm;