import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { Controller, useForm, SubmitHandler, FieldValues } from "react-hook-form";
import _ from "lodash";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

import ShowSaving from "components/processing/ShowSaving";
import { TabProps, InviteDialogForm } from "./types";
import { useTeamInviteEmailsMutation } from "api/common";

const schema = yup.object().shape({
  email: yup.string().email("You must enter a valid email").required("You must provide an email address"),
});
const defaultValues = {
  email: "",
};

const ExternalInviteTab = ({ orgId, teamId }: TabProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { control, reset, handleSubmit, formState } = useForm<InviteDialogForm>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });



  const [inviteUsers, { isLoading: isAdding }] = useTeamInviteEmailsMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      const result = await inviteUsers({
        orgId,
        teamId,
        email: model.email,
      }).unwrap();

      if (result.error) {
        throw Error(result.error);
      }
      reset(defaultValues);
      enqueueSnackbar("Successfully Invited Users", {
        variant: "success",
      });

    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error adding users", {
        variant: "error",
      });
    }
  };

  if (isAdding) {
    return <ShowSaving />;
  }

  const emailError = formState?.errors?.["email"]?.message?.toString() || "";
  const { isValid, dirtyFields } = formState;
  return (

    <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col md:overflow-hidden">
      <DialogContent sx={{ my: 2 }}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email Address"
              autoFocus
              type="text"
              error={emailError != ""}
              helperText={emailError}
              variant="outlined"
              fullWidth
            />
          )}
        />
      </DialogContent>
      <Box>
        <Button variant="contained" color="secondary" type="submit" disabled={_.isEmpty(dirtyFields) || !isValid}>
          Invite New Users
        </Button>
      </Box>
    </form>
  );
}

export default ExternalInviteTab;