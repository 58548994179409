import React from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { ConfirmActionProps } from "./types";


// Show confirmation screen to run an action
const ActionConfirmDialog = (props: ConfirmActionProps) => {
  const { isVisible, setIsVisible, message, buttonText, title, action } = props;

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    setIsVisible(false)
  }

  /**
   * confirm message and run action
   */
  function confirmOK() {
    action();
    closeComposeDialog();
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={isVisible === null ? false : isVisible}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>{title} </strong>
          </Typography>
        </Toolbar>
      </AppBar>
        
    <DialogContent classes={{ root: "p-24" }}>
      {message}
      </DialogContent>
      <DialogActions className="justify-between p-4 pb-16">
      <div className="px-16">
        <Button variant="contained" color="secondary" type="submit"  onClick={confirmOK}>
          {buttonText || "Submit"}
        </Button>
      </div>
      <div className="px-16">
        <Button variant="contained" color="primary" onClick={closeComposeDialog}>
          Cancel
        </Button>
      </div>
    </DialogActions>
    </Dialog>
  );
};

export default ActionConfirmDialog;
