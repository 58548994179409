import React from "react";

import { useParams, useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

import SessionDetails from "components/session/setup/SessionDetails";
import AddSessionStories from "components/session/setup/AddSessionStories";
import Review from "components/session/setup/Review";

import { useGetSessionByTokenQuery } from "api/sessions";
import ShowLoading from "components/processing/ShowLoading";
import useSetPageTitle from "hooks/use-set-page-title";
import { SessionData } from "common/types";

const steps = ["Session Details", "Add Stories", "Start Pointing"];

function getStepContent(step: number, tokenData: SessionData) {
  switch (step) {
    case 1:
      return <SessionDetails sessionData={tokenData} />;
    case 2:
      return <AddSessionStories sessionId={tokenData.sessionId} />;
    case 3:
      return <Review sessionData={tokenData} />;
    default:
      throw new Error("Unknown step");
  }
}

const SetupSessionPage = () => {
  useSetPageTitle("Setup Pointing Session");
  const navigate = useNavigate();
  const routeParams = useParams();
  const { token, currentStep = "0" } = routeParams;
  const activeStep = parseInt(currentStep);

  const handleNext = () => {
    navigate("/setup-session/" + token + "/" + (activeStep + 1));
  };

  const handlePrev = () => {
    navigate("/setup-session/" + token + "/" + (activeStep - 1));
  };

  const { data: apiSessionData, isLoading, isError } = useGetSessionByTokenQuery({ token: token });

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error Loading Session Data</Alert>
      </Grid>
    );
  }

  const tokenData = apiSessionData || {};

  return (
    <Container maxWidth="sm" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          Setup Pointing Session
        </Typography>
        <Stepper activeStep={activeStep - 1} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>
          {getStepContent(activeStep, tokenData)}

          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pt: 2 }}>
            <Button variant="contained" onClick={handlePrev} disabled={activeStep <= 1}>
              Previous
            </Button>

            <Button variant="contained" onClick={handleNext} disabled={activeStep >= 3}>
              Next
            </Button>
          </Stack>
        </>
      </Paper>
    </Container>
  );
};

export default SetupSessionPage;
