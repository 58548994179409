import React from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const curentFeatures = [
  "Point scrum stories as a team for free",
  "Statistics breakdown for each session, story, and participant",
  "Choose from Mean, Median, Max, Min, or Mode points after each round",
  "Separate sessions and statistics for each team",
  "Manage multiple Teams & Organizations",
  "Compare multiple sessions' statistics",
];
const futureFeatures = [
  "Guest Voting that does not require a full account",
  "Single Sign On using popular sites",
  "Import stories from multiple sources (e.g. CSV, project tracking...) ",
  "Export Story Points to CSV and 3rd party software",
];

const FeatureList = () => {
  return (
    <>
      <Typography variant="h4" sx={{ mt: 4 }}>
        Features
      </Typography>
      <List
        aria-label="features"
        sx={{
          textAlign: "left",
          listStyleType: "disc",

          pl: 3,
        }}
      >
        {curentFeatures.map((entry, id) => (
          <ListItem sx={{ display: "list-item", pl: 1, py: 0 }} disableGutters key={`current-features-${id}`}>
            <ListItemText primary={entry} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h5" sx={{ mt: 4 }}>
        Coming Soon
      </Typography>
      <List
        aria-label="comming soon"
        sx={{
          textAlign: "left",
          listStyleType: "disc",

          pl: 3,
        }}
      >
        {futureFeatures.map((entry, id) => (
          <ListItem sx={{ display: "list-item", pl: 1, py: 0 }} disableGutters key={`future-features-${id}`}>
            <ListItemText primary={entry} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default FeatureList;
