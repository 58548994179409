import React from "react";
import { RouteObject } from "react-router-dom";

import AdminPage from "pages/AdminPage";
import RegisterPage from "pages/RegisterPage";
import ConvertGuestPage from "pages/ConvertGuestPage";
import CreateSessionPage from "pages/CreateSessionPage";
import CreateStoryPage from "pages/CreateStoryPage";
import CompleteRegistration from "pages/CompleteRegistration";
import Dashboard from "pages/Dashboard";
import Error404Page from "pages/Error404Page";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import HomePage from "pages/HomePage";
import InviteProcessPage from "pages/InviteProcessPage";
import LogoutPage from "pages/LogoutPage";
import LoginPage from "pages/LoginPage";
import PointingPage from "pages/PointingPage";
import SessionCompletePage from "pages/SessionCompletePage";
import SessionListPage from "pages/SessionListPage";
import SettingsPage from "pages/SettingsPage";
import SetupSessionPage from "pages/SetupSessionPage";
import StoryListPage from "pages/StoryListPage";
import TestPage from "pages/TestPage";

import ShowLoading from "components/processing/ShowLoading";
import Layout from "components/Layout";
import MyStatsPage from "pages/MyStatsPage";
import TeamListPage from "pages/TeamListPage";
import UnsubscribePage from "pages/UnsubscribePage";
import { RouteConfig } from "common/types";

// make permissions int?  auth then we culd have authLevel authLevelMin ???? authLevelMax ?

// @todo update code to use https://www.robinwieruch.de/react-router-nested-routes/
// probably include session create, profile pages, team/account management
// @todo add lazty loading from https://medium.com/@ahsan-ali-mansoor/define-react-routes-with-better-approach-typescript-d07de782b517

const routeEntries: RouteConfig[] = [
  // all access pages
  {
    auth: "all",
    path: "logout",
    element: <LogoutPage />,
  },
  {
    auth: "all",
    path: "unsubscribe-email",
    element: <UnsubscribePage />,
  },
  {
    auth: "all",
    path: "loading",
    element: <ShowLoading />,
  },
  {
    auth: "all",
    path: "404",
    element: <Error404Page />,
  },
  {
    auth: "all",
    path: "*",
    element: <Error404Page />,
  },
  {
    auth: "all",
    element: <CompleteRegistration />,
    path: "complete-registration",
  },
  {
    auth: "all",
    element: <CompleteRegistration />,
    path: "complete-registration/:registrationCode",
  },
  {
    auth: "all",
    element: <TestPage />,
    path: "test",
  },
  // public only access
  {
    auth: "public",
    element: <HomePage />,
    path: "",
  },
  {
    auth: "public",
    element: <LoginPage />,
    path: "login",
  },
  {
    auth: "public",
    element: <RegisterPage />,
    path: "register",
  },
  {
    auth: "public",
    element: <ForgotPasswordPage />,
    path: "forgot-password",
  },
  // Guest Routes pages
  {
    auth: "guest",
    element: <ConvertGuestPage />,
    path: "make-guest-user",
  },
  // any user
  {
    auth: "anyUser",
    element: <Dashboard />,
    path: "dashboard",
  },
  {
    auth: "anyUser",
    element: <SessionListPage />,
    path: "sessions",
  },
  {
    auth: "anyUser",
    element: <StoryListPage />,
    path: "stories",
  },
  {
    auth: "anyUser",
    element: <StoryListPage />,
    path: "stories/:tab",
  },
  {
    auth: "anyUser",
    element: <SessionCompletePage />,
    path: "sessions/:token",
  },
  {
    auth: "anyUser",
    element: <SessionCompletePage />,
    path: "sessions/:token/:tab",
  },
  // {
  //   auth: "anyUser",
  //   element: <SessionCompletePage tab="overview" />,
  //   path: "sessions/:token/overview",
  // },
  // {
  //   auth: "anyUser",
  //   element: <SessionCompletePage tab="stories" />,
  //   path: "sessions/:token/stories",
  // },
  // {
  //   auth: "anyUser",
  //   element: <SessionCompletePage tab="participants" />,
  //   path: "sessions/:token/participants",
  // },
  {
    auth: "anyUser",
    element: <CreateSessionPage />,
    path: "create-session",
  },
  {
    auth: "anyUser",
    element: <CreateStoryPage />,
    path: "create-story",
  },
  {
    auth: "anyUser",
    element: <SetupSessionPage />,
    path: "setup-session/:token",
  },
  {
    auth: "anyUser",
    element: <SetupSessionPage />,
    path: "setup-session/:token/:currentStep",
  },
  {
    auth: "all",
    element: <PointingPage />,
    path: "pointing/:token",
  },
  {
    auth: "anyUser",
    element: <TeamListPage />,
    path: "user/teams",
  },
  {
    auth: "anyUser",
    element: <InviteProcessPage action={"accept"} />,
    path: "invite/:inviteCode/accept",
  },
  {
    auth: "anyUser",
    element: <InviteProcessPage action={"decline"} />,
    path: "invite/:inviteCode/decline",
  },
  {
    auth: "anyUser",
    element: <MyStatsPage />,
    path: "user/stats",
  },
  // User routes pages
  {
    auth: "user",
    element: <SettingsPage />,
    path: "user/settings",
  },
  // Admin Routes pages
  {
    auth: "admin",
    element: <AdminPage />,
    path: "admin",
  },
];

function createRouteParts(routeEntries: RouteConfig[]) {
  const routeObjects: RouteObject[] = [
    {
      element: <Layout />,
      children: [] as RouteObject[] | undefined,
    },
  ];
  const routePermissions = new Map<string, string>();
  for (let i = 0; i < routeEntries.length; i++) {
    if (routeObjects[0].children) {
      routeObjects[0].children.push({
        element: routeEntries[i].element,
        path: routeEntries[i].path,
      });
    }
    routePermissions.set(routeEntries[i].path, routeEntries[i].auth);
  }

  return {
    routeObjects,
    routePermissions,
  };
}

const routeParts = createRouteParts(routeEntries);

export const defaultUserPage = "/dashboard";
export const defaultNonUserPage = "/";

export const routes = routeParts.routeObjects;
export const routeAuth = routeParts.routePermissions;
