import React from "react";

import { usePointActiveStoryMutation } from "api/sessions";
import { useAppSelector } from "store";

import PlayingCard from "./PlayingCard";
import { availableCards } from "configs/playingCards";

const PointingCards = () => {
  const activePointing = useAppSelector(({ session }) => session.activePointing);
  const selectedCard = useAppSelector(({ session }) => session.activeStoryPoints);
  const sessionId = useAppSelector(({ session }) => session.data?.sessionId);
  const selectedCardString = selectedCard.toString();

  const [pointstory] = usePointActiveStoryMutation();

  const handleSelect = async (value: string) => {
    try {
      await pointstory({ sessionId: sessionId, points: parseInt(value) });
    } catch (e) {
      console.log("error starting session: ", e);
    }
  };
  return (
    <>
      {availableCards.map((entry) => (
        <PlayingCard
          key={`playing-card-list-${entry.value}`}
          label={entry.label}
          disabled={!activePointing}
          selected={selectedCardString == entry.value}
          action={() => {
            handleSelect(entry.value);
          }}
        />
      ))}
    </>
  );
};

export default PointingCards;
