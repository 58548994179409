import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import EmailInput from "components/inputs/EmailInput";
import PasswordInput from "components/inputs/PasswordInput";
import ShowLoading from "components/processing/ShowLoading";
import ShowSaving from "components/processing/ShowSaving";
import SubmitButton from "components/inputs/SubmitButton";
import TextInput from "components/inputs/TextInput";
import { ErrorAPI } from "common/types";
import { useInviteRegisterMutation, useGetInviteCodeDataQuery } from "api/common";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email("You must enter a valid email").required("You must enter a email"),
  name: yup.string().required("Please enter a name."),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  verify_password: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

type RegisterFormValues = {
  email: string;
  name: string;
  password: string;
  verify_password: string;
};

const defaultValues: RegisterFormValues = {
  email: "",
  name: "",
  password: "",
  verify_password: "",
};


interface InviteRegisterFormProps {
  inviteCode: string;
}

/**
 * Register a user from an invite code
 * @param {object} props
 * @returns
 */
const InviteRegisterForm = ({ inviteCode }: InviteRegisterFormProps) => {
  const navigate = useNavigate();
  const { control, formState, handleSubmit, setError, reset } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { data, isLoading, isError } = useGetInviteCodeDataQuery({ inviteCode })
  const [register, { isLoading: isSaving, error: apiError }] = useInviteRegisterMutation();

  useEffect(() => {
    if (data?.email) {
      reset({
        ...defaultValues,
        ...data
      });
    }
  }, [data])

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    const data = {
      name: model.name,
      email: model.email,
      password: model.password
    }
    try {
      // @todo we will need to register with invite???
      await register({ inviteCode, data }).unwrap();
      navigate("/dashboard", { replace: true });
    } catch (e) {
      if ((e as ErrorAPI).data?.error == "duplicate email") {
        setError("email", { type: "string", message: "Email already registered" });
      }
      else {
        console.log("error", (e as ErrorAPI).data?.error)
        setError("email", { type: "string", message: "Error registering" });
      }
    }
  };


  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  if (isSaving) {
    return <ShowSaving />;
  }

  if (isError) {
    return (
      <Typography >
        Error loading Invite
      </Typography>
    )
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      {apiError && <p>Register API Error</p>}

      <Typography variant="caption" color="text.secondary">
        {data.orgName}
      </Typography>
      <Typography>{data.teamName}</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmailInput control={control} label="E-Mail" formState={formState} disabled={true} />
        </Grid>
        <Grid item xs={12}>
          <TextInput control={control} label="Name" name="name" formState={formState} />
        </Grid>

        <Grid item xs={12}>
          <PasswordInput
            control={control}
            label="Password"
            formState={formState}
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordInput
            control={control}
            label="Verify Password"
            formState={formState}
            name="verify_password"
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton title="Register" formState={formState} isLoading={isSaving} />
        </Grid>
      </Grid>
    </Box >
  );
};

export default InviteRegisterForm;
