import React from "react";
import { FieldValues, FormState } from "react-hook-form";
import _ from "lodash";

import Button from "@mui/material/Button";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

interface SubmitButtonProps {
  title: string;
  variant?: "text" | "outlined" | "contained";
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  formState: FormState<FieldValues>;
}

const SubmitButton = (props: SubmitButtonProps) => {
  const variant = props?.variant || "contained";
  const sx = props?.sx || {};
  const formState = props.formState || null;
  const isLoading = props?.isLoading || false;
  const disabled = formState && (_.isEmpty(formState.dirtyFields) || !formState.isValid || isLoading);

  return (
    <Button
      variant={variant}
      color="primary"
      className="w-full mx-auto mt-16"
      aria-label={props.title}
      disabled={disabled}
      type="submit"
      sx={sx}
    >
      {props.title}
    </Button>
  );
};

export default SubmitButton;
