import React from "react";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircle from "@mui/icons-material/PlayCircle";

interface ActionIconProps {
  showControl: boolean;
  onStart: () => void;
  onEdit: () => void;
}

function ActionIcons({ showControl, onStart, onEdit }: ActionIconProps) {
  if (!showControl) {
    return (
      <>
        <IconButton aria-label="Edit" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <IconButton aria-label="Make Active" onClick={onStart}>
        <PlayCircle />
      </IconButton>
      <IconButton aria-label="Edit" onClick={onEdit}>
        <EditIcon />
      </IconButton>
    </>
  );
}
export default ActionIcons;
