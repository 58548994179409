import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";

import { ComputeActualCardPoints } from "common/helper";

interface PointingAvatarStatusProps {
  status: string;
  showPoints?: boolean;
  points?: number;
}

const PointingAvatarStatus = ({ status, showPoints, points }: PointingAvatarStatusProps) => {

  if (status == "observer") {
    
    return <VisibilityIcon />;
  }

  if (status == "started" || status == "pending") {
    return <HourglassEmptyIcon />;
  }
  
  if (status == "pointed") {
    
    if (showPoints && points !== undefined) {
      return <Typography sx={{fontSize: 20, fontWeight: "bold"}}>{ComputeActualCardPoints(points)}</Typography>;
    }

    return <CheckCircleIcon />;
  }

  return <></>;
};

export default PointingAvatarStatus;