import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useRequestRegisterCodeMutation } from "api/common";
import EmailInput from "components/inputs/EmailInput";
import SubmitButton from "components/inputs/SubmitButton";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email("You must enter a valid email").required("You must enter a email"),
});

type NewCodeFormValues = {
  email: string;
};

const defaultValues: NewCodeFormValues = {
  email: "",
};

/**
 * Get a new Form for requesting password reset
 * @returns
 */
const NewCodeForm = () => {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const { control, formState, handleSubmit, setError } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const [requestCode, { isLoading }] = useRequestRegisterCodeMutation();
  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      await requestCode(model).unwrap();
      setWasSubmitted(true);
    } catch (e) {
      setError("email", {
        type: "string",
        message: "Error requesting new code",
      });
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      {wasSubmitted && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Please check your e-mail for the registration code.</Typography>
          </Grid>
        </Grid>
      )}
      {!wasSubmitted && (
        <>
          <Typography variant="h6" gutterBottom>
            Request E-Mail Verification Code
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EmailInput control={control} label="E-Mail Address" variant="standard" formState={formState} />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <SubmitButton
                  title="Submit"
                  isLoading={isLoading}
                  formState={formState}
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default NewCodeForm;
