import React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

/*
  {n.name}
  {n.orgName}
  {n.orgName}
  {n.teamName}
  <SessionStatus name={n.status} />
  {format(parseJSON(n.createdAt), "P")}
*/

// const rows = [
//   {
//     id: "name",
//     align: "center",
//     disablePadding: true,
//     label: "Name",
//     sort: true,
//   },
//   {
//     id: "team",
//     align: "center",
//     disablePadding: false,
//     label: "Team",
//     sort: true,
//   },
//   {
//     id: "status",
//     align: "center",
//     disablePadding: false,
//     label: "Status",
//     sort: true,
//   },
//   {
//     id: "actions",
//     align: "center",
//     disablePadding: false,
//     label: "Actions",
//     sort: false,
//   },
// ];

function ListHeader() {
  return (
    <TableHead >
      <TableRow>
        <TableCell align="center" padding="none">
          Name
        </TableCell>
        <TableCell align="center" padding="none">
          Pending Stories
        </TableCell>
        <TableCell align="center" padding="none">
          Team
        </TableCell>
        <TableCell align="center" padding="none">
          Status
        </TableCell>
        <TableCell align="center" padding="none"></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ListHeader;
