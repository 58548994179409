import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Person from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import { SessionUser } from "common/types";
import PointingAvatarStatus from "../PointingAvatarStatus";

interface ParticipantEntryProps {
  user: SessionUser;
}

const ParticipantEntry = ({ user }: ParticipantEntryProps) => {

  return (
    <ListItem secondaryAction={<PointingAvatarStatus status={user.status} />}>
      <ListItemIcon>{user.isOnline ? <Person /> : <PersonOffIcon />}</ListItemIcon>
      <ListItemText primary={user.name} />
    </ListItem>
  );
};

export default ParticipantEntry;
