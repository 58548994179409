import React, {  useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { MenueItem } from "common/types";

interface SubMenuProps {
  title: string;
  items: MenueItem[];
}

const SubMenu = ({ title, items }: SubMenuProps) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const isOpen = !!(anchorElUser)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Tooltip title={`${title} Menu`}>
        <Button color="inherit" onClick={handleOpenUserMenu} sx={{ cursor: "pointer" }} >
          {title}
        </Button>
      </Tooltip>
      <Menu
        id={`desktop-menu-sub-${title}`}
        anchorEl={anchorElUser}

        open={isOpen}
        onClose={handleCloseUserMenu}
      >
        {items.map((entry) => {
          return (
            <MenuItem key={`desktop-submenu-${title}-${entry.path}`} component={RouterLink} to={entry.path as string} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{entry.name}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  );
};

export default SubMenu;
