import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useGetQuickStatsQuery } from "api/sessions";

// expand this with additional stats???
// const data = {
//   averagePoints: 5,
//   totalSessions: 20,
//   totalStories: 438,
//   frequentPoints: 3,
//   accuracy: "63%",
// };

const QuickSstats = () => {
  
  const { data, isLoading } = useGetQuickStatsQuery({});

  if (isLoading) return <p>Loading...</p>;
  
  return (
    <TableContainer>
      <Table aria-label="My Pointing Stats">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Average Points
            </TableCell>
            <TableCell align="right">{data?.averagePoints || "N/A"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Points Frequently Suggested
            </TableCell>
            <TableCell align="right">{data?.frequentPoints || "N/A"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Accuracy
            </TableCell>
            <TableCell align="right">{data?.accuracy || "N/A"}</TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell component="th" scope="row">
              Sessions
            </TableCell>
            <TableCell align="right">{data?.totalSessions || 0}</TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell component="th" scope="row">
              Stories
            </TableCell>
            <TableCell align="right">{data?.totalStories || 0}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuickSstats;
