import React from "react";

import Box from "@mui/material/Box";
import { StatsDataStoryInterface, SessionData } from "common/types";

import { TableStructInterface } from "../StatsTable/types";
import StatsTable from "../StatsTable";

/*

- replace boxpoint with line and area graph

line: actual point
area: min, max point suggested
ignore -1, 100
 
can change later


maybe use the scatter chart:
x: story
then create 3 ledgends: points, min, max
renormalize the data into that.  each story would have 3 points

*/

const tableStruct: TableStructInterface[] = [
  { id: "name", label: "Name", sort: true, align: "left" },
  { id: "points", label: "Points", sort: true, align: "right" },
  { id: "participants", label: "Participants", sort: true, align: "right" },
  { id: "mean", label: "Mean", sort: true, align: "right", fixed: 1 },
  { id: "median", label: "Median", sort: true, align: "right" },
  { id: "min", label: "Min", sort: true, align: "right" },
  { id: "max", label: "Max", sort: true, align: "right" },
  { id: "mode", label: "Mode", sort: true, align: "right" },
];

interface StatsStoryProps {
  data: StatsDataStoryInterface[];
  session: SessionData;
}

const StatsStory = ({ data }: StatsStoryProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Paper sx={{ width: "100%", mb: 2, mt: 3 }}>
        <PointScatterChart points={data.points} />
      </Paper> */}

      <StatsTable name="Story" data={data} tableStruct={tableStruct} />
    </Box>
  );
};

export default StatsStory;
