import React, {useState} from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PeopleIcon from '@mui/icons-material/People';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import DisplayTeams from './DisplayTeams';
import { Org } from 'common/types';
import { formatDate } from 'common/helper'
import { useAppDispatch } from 'store';
import { showCreateTeamForm, showEditOrgForm, showOrgMemberListDialog } from 'store/dialogs';

interface OrgDisplayProps { 
  row: Org;
  onLeaveOrg: (orgId: number) => void,
  onJoinTeam: (orgId: number, teamId: number) => void,
  onLeaveTeam: (orgId: number, teamId: number) => void
}

//onLeaveOrg={handleLeaveOrg} onJoinTeam={handleJoinTeam} onLeaveTeam={handleLeaveTeam}
const OrgRowDisplay = ({ row, onJoinTeam, onLeaveTeam }: OrgDisplayProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.totalTeams || 0}</TableCell>
        <TableCell align="right">{row.totalUsers || 0}</TableCell>
        <TableCell align="right">{formatDate(row.createdAt as string)}</TableCell>
        <TableCell align="right">
          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
            <Tooltip title="Edit Org">
              <IconButton aria-label="Edit Org" onClick={()=>{ dispatch(showEditOrgForm({orgId: row.orgId})) }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Members">
              <IconButton aria-label="Members" onClick={() => { dispatch(showOrgMemberListDialog({orgId: row.orgId, name: row.name})) }}>
                <PeopleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Create Team">
              <IconButton aria-label="Create Team" onClick={() => { dispatch(showCreateTeamForm({orgId: row.orgId})) }}>
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <DisplayTeams isOpen={open} orgId={row.orgId} onJoinTeam={onJoinTeam} onLeaveTeam={onLeaveTeam} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default OrgRowDisplay;