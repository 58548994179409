import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { userMenu } from "configs/menu";
import { useAppSelector } from "store";
import { useGetMyProfileQuery } from "api/common";

const UserMenu = () => {
  const isLoggedIn = useAppSelector(({auth}) => auth.isLoggedIn);
  const isGuest = useAppSelector(({auth}) => !!(auth.role == "guest"));
  const userProfile = useAppSelector(({user}) => user.profile);
  const initProfileData = useAppSelector(({auth}) => auth.role == "user");
  const [anchorElUser, setAnchorElUser] = useState(null);
  // console.log(isGuest || userProfile.needInit)
  // might be a better way to do this....
  useGetMyProfileQuery({}, {skip: !initProfileData})

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [menuGroup, setMenuGroup] = useState("nonUser");

  useEffect(() => {
    if (isLoggedIn) {
      if (isGuest) {
        setMenuGroup("guest");
      } else {
        setMenuGroup("user");
      }
    } else {
      setMenuGroup("nonUser");
    }
  }, [isLoggedIn, isGuest]);

  return (
    <Box sx={{ display: "block", ml: 5 }}>
      <Tooltip title="User Menu">
        <Avatar src={userProfile.photoUrl} onClick={handleOpenUserMenu} sx={{ cursor: "pointer" }} />
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {userMenu[menuGroup].map((entry) => (
          <MenuItem key={entry.path} component={RouterLink} to={entry.path as string}>
            <Typography textAlign="center">{entry.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default UserMenu;
