import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NoSessionStoriesList from "../NoSessionStoriesList";

const NoSessionStoriesTab = () => {
  return (
    <>
      <Typography variant="h6">Stories with no Session</Typography>
      <Box sx={{ width: "100%", mt: 4 }}>
        <NoSessionStoriesList />
      </Box>
    </>
  );
};

export default NoSessionStoriesTab;
