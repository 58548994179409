import { MenuTypes } from "common/types";

export const userMenu: MenuTypes = {
  user: [
    { name: "My Stats", path: "/user/stats" },
    // { name: "Profile", path: "/user/profile" },
    { name: "Teams", path: "/user/teams" },
    { name: "Settings", path: "/user/settings" },
    { name: "Logout", path: "/logout" },
  ],
  guest: [
    { name: "My Stats", path: "/my-stats" },
    { name: "Complete Signiup", path: "/make-guest-user" },
    { name: "Logout", path: "/logout" },
  ],
  nonUser: [
    { name: "Login", path: "/login" },
    { name: "Register", path: "/register" },
  ],
};

export const primaryMenu: MenuTypes = {
  user: [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Sessions", path: "/sessions" },
    { name: "Stories", path: "/stories" },
    { name: "New", children: [
      { name: "Session", path: "/create-session" },
      { name: "Story", path: "/create-story" },
    ]},
    // { name: "New Session", path: "/create-session" },
    // { name: "Logout", path: "/logout" },
  ],
  guest: [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Complete Signiup", path: "/make-guest-user" },
  ],
  nonUser: [
    { name: "Login", path: "/login" },
    { name: "Register", path: "/register" },
  ],
};

export const secondaryMenu: MenuTypes = {
  user: [
    { name: "Settings", path: "/settings" },
    { name: "Logout", path: "/logout" },
  ],
  guest: [
    { name: "Complete Signiup", path: "/make-guest-user" },
    { name: "Logout", path: "/logout" },
  ],
  nonUser: [],
};

export default {
  userMenu,
  primaryMenu,
  secondaryMenu,
};
