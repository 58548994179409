import React from "react";

import ShowLoading from "components/processing/ShowLoading";
import { useTeamMembersListQuery } from "api/common";
import MemberTable from "./MemberTable";

interface TeamMemberListProps {
  orgId: number;
  teamId: number;
}

const TeamMemberList = ({ orgId, teamId }: TeamMemberListProps) => {
  const {data, isLoading, isError } = useTeamMembersListQuery({orgId, teamId}, {skip: !(orgId > 0 && teamId > 0)})
  const members = data?.data || [];

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    return <>Error fetching members</>
  }

  return <MemberTable members={members} orgId={orgId} teamId={teamId} memberType="team" />
}

export default TeamMemberList;