import React from "react";
//import type {} from "redux-thunk/extend-redux";

import { useEffect } from "react";

import { getRefreshToken } from "common/auth/storage";
import { useLogoutMutation } from "api/auth";
import { useAppDispatch } from "store";
import { unsetAuthData } from "store/auth";

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const refreshToken = getRefreshToken();

  useEffect(() => {
    if (refreshToken) {
      logout({ refreshToken });
    }
    dispatch(unsetAuthData());
  }, []);

  return <p>You are now logged out</p>;
};

export default LogoutPage;
