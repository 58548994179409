import React from "react";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";

const ForgotPasswordLink = () => {
  return (
    <Grid item xs={12} sm={6}>
      <Link className="font-normal" to="/forgot-password">
        Forgot Password?
      </Link>
    </Grid>
  );
};

export default ForgotPasswordLink;
