import React from "react";
import { Controller, Control, FieldValues, FormState } from "react-hook-form";

import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";

import PointInputCustom from "./PointInputCustom";
import { SetPointsDialogForm, PointsOptionsType } from "./types";
import PointInput from "./PointInput";

interface SetPointsFormProps {
  control: Control<SetPointsDialogForm>;
  formState: FormState<FieldValues>;
  data: PointsOptionsType;
}

const SetPointsDialogContent = ({ control, formState, data }: SetPointsFormProps) => {
  const pointsError = formState?.errors?.["usePoints"]?.message?.toString() || "";

  const showRoundedWarning = data.dataFormated;

  return (
    <DialogContent classes={{ root: "p-24" }}>
      <FormControl>
        {pointsError}
        <Controller
          name="usePoints"
          control={control}
          render={({ field }) => {
            return (
              <RadioGroup {...field}>
                <Box sx={{ px: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <PointInput value="mean" label="Mean" points={data.mean} />
                      <PointInput value="min" label="Min" points={data.min} />
                    </Grid>
                    <Grid item xs={4}>
                      <PointInput value="median" label="Median" points={data.median} />
                      <PointInput value="max" label="Max" points={data.max} />
                    </Grid>
                    <Grid item xs={4}>
                      <PointInput value="mode" label="Mode" points={data.mode} />
                      <PointInputCustom control={control} />
                    </Grid>
                  </Grid>
                </Box>
              </RadioGroup>
            );
          }}
        />
      </FormControl>
      <Box sx={{ mt: 2 }}>
        {showRoundedWarning && (
          <FormHelperText id="rounded-warning" sx={{ color: "red" }}>
            *Mean and Median values are rounded to the nearest valid point
          </FormHelperText>
        )}
      </Box>
    </DialogContent>
  );
};

export default SetPointsDialogContent;
