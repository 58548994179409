import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import clsx from "clsx";

import useTimeout from "../../hooks/use-timeout";

type ShowSavingProps = {
  delay?: number;
};

function ShowSaving({ delay = 0 }: ShowSavingProps) {
  const [showLoading, setShowLoading] = useState(!delay);

  useTimeout(() => {
    setShowLoading(true);
  }, delay);

  return (
    <div className={clsx("flex flex-1 flex-col items-center justify-center p-24", !showLoading && "hidden")}>
      <Typography className="text-13 sm:text-20 mb-16" color="textSecondary">
        Saving...
      </Typography>
      <LinearProgress className="w-192 sm:w-320 max-w-full rounded-2" color="secondary" />
    </div>
  );
}

export default ShowSaving;
