import React from "react";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import { useAppSelector } from "store";
import SessionManageButtons from "./SessionManageButtons";
import SessionStartButtons from "./SessionStartButtons";

function SessionControlPanel() {
  const status = useAppSelector(({ session }) => session.data.status);
  const sessionId = useAppSelector(({ session }) => session.data.sessionId);
  const userInControl = useAppSelector(({ session }) => session.userInControl && session.data.status == "started");

  const showStartButton = status == "started" ? false : true;

  if (!showStartButton && !userInControl) {
    return <></>;
  }

  return (
    <Paper sx={{ mt: 3, py: 1, px: 3 }}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ my: 1, mx: 0, px: 0 }}>
        {showStartButton && <SessionStartButtons sessionId={sessionId} />}
        {!showStartButton && userInControl && <SessionManageButtons sessionId={sessionId} />}
      </Stack>
    </Paper>
  );
}

export default SessionControlPanel;
