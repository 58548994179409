import React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import OrgDialog from "components/dialogs/OrgDialog";
import TeamDialog from "components/dialogs/TeamDialog";
import SessionForm from "components/session/create/SessionForm";
import useSetPageTitle from "hooks/use-set-page-title";

const CreateSessionPage = () => {
  useSetPageTitle("Create Pointing Session");
  return (
    <>
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Create Pointing Session
          </Typography>
          <SessionForm />
        </Paper>
      </Container>

      <OrgDialog />
      <TeamDialog />
    </>
  );
};

export default CreateSessionPage;
