import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

interface OverviewBoxProps {
  name: string;
  total: number;
  color: string;
  typeName: string;
}

const OverviewBox = ({ name, total, color, typeName }: OverviewBoxProps) => {
  return (
    <Card sx={{ minWidth: "100%", borderRadius: 3 }}>
      <CardContent>
        <Typography align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {name}
        </Typography>
        <Typography variant="h5" component="div"></Typography>
        <Typography variant="h2" align="center" color={color} sx={{ fontWeight: "bold" }}>
          {total}
        </Typography>
        <Typography variant="h5" align="center" color={color}>
          {typeName}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default OverviewBox;
