import React, { useState } from "react";

import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

import ActionConfirmDialog from "components/dialogs/ActionConfirmDialog";
import { useDeleteAccountMutation } from "api/common";
import { getRefreshToken } from "common/auth/storage";
import { useLogoutMutation } from "api/auth";
import { useAppDispatch } from "store";
import { unsetAuthData } from "store/auth";


const DeleteAccountForm = () => {  const dispatch = useAppDispatch();
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);

  const [logout] = useLogoutMutation();
  const [deleteAccount] = useDeleteAccountMutation();

  const deleteaccountAction = async () => {
    try {
      await deleteAccount({});
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        logout({ refreshToken });
      }
      dispatch(unsetAuthData());
    } catch (e) {
      console.log("error deleting account: ", e);
    }
  };

  return (
    <>
      <Typography align="center" variant="h5" sx={{ mb: 0 }}>
        <Button color="error" variant="contained" onClick={() => { setShowDeleteAccount(true) }}>
          Delete Account
        </Button>
      </Typography>
      <ActionConfirmDialog
        message="This will permentaly delete your account.  This wil not remove session related stats. Are you sure you wish to continue?"
        title="Delete Account"
        buttonText="Delete Account"
        action={deleteaccountAction}
        isVisible={showDeleteAccount}
        setIsVisible={setShowDeleteAccount}
      />
    </>
  );
};

export default DeleteAccountForm;
