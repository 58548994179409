import React from "react";
import { Control, Controller, FieldValues, FormState } from "react-hook-form";

import TextField, { TextFieldVariants } from "@mui/material/TextField";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

interface PasswordInputProps {
  name?: string;
  label?: string;
  variant?: TextFieldVariants;
  sx?: SxProps<Theme>;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
}

const PasswordInput = (props: PasswordInputProps) => {
  const name = props.name || "password";
  const label = props.label || "Password";
  const errorMessage = props.formState?.errors?.[name]?.message?.toString() || "";
  const sx = props?.sx || {};
  const variant = props?.variant || "outlined";

  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field }) => (
        <TextField
          {...field}
          className="mb-16"
          label={label}
          type="password"
          error={errorMessage != ""}
          helperText={errorMessage}
          variant={variant}
          sx={sx}
          required
          fullWidth
        />
      )}
    />
  );
};

export default PasswordInput;
