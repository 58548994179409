import React from "react";

import Button from "@mui/material/Button";

import { useSessionPauseMutation, useSessionCloseMutation } from "api/sessions";

interface SessionManageButtonsProps {
  sessionId: number;
}

const SessionManageButtons = ({ sessionId }: SessionManageButtonsProps) => {
  const [sessionPause] = useSessionPauseMutation();
  const [sessionClose] = useSessionCloseMutation();

  const handlePauseSession = async () => {
    try {
      await sessionPause({ sessionId: sessionId });
    } catch (e) {
      console.log("error starting pausing: ", e);
    }
  };

  const handleCloseSession = async () => {
    // @todo we should ask what session we should move pending stories to.
    // Stop Session Confirm all unpointed stories will be removed from session and they will get option to send to sessionX or create a new session
    try {
      await sessionClose({ sessionId: sessionId });
    } catch (e) {
      console.log("error closing session: ", e);
    }
  };

  return (
    <>
      <Button type="submit" variant="contained" size="small" onClick={handlePauseSession}>
        Pause Session
      </Button>
      <Button type="submit" variant="contained" size="small" onClick={handleCloseSession}>
        Archive Session
      </Button>
    </>
  );
};

export default SessionManageButtons;
