import React, { useEffect, useState } from "react";

import ActionConfirmDialog from "../../dialogs/ActionConfirmDialog";
import { useSessionCloseMutation } from "api/sessions";
import { useAppSelector } from "store";

const AutoCloseSession = () => {
  const sessionId = useAppSelector(({ session }) => session.data.sessionId);
  const userInControl = useAppSelector(({ session }) => session.userInControl && session.data.status == "started");
  const totalStories = useAppSelector(({ session }) => session?.stories?.length || 0);

  const [showCloseSession, setShowCloseSession] = useState(false);

  const [ sessionClose ] = useSessionCloseMutation();

  useEffect(() => {
    if (userInControl && (totalStories === 0)) {
      setShowCloseSession(true);
    }
  }, [totalStories, userInControl]);

 
  const closeSessionAction = async () => {
    try {
      await sessionClose({ sessionId: sessionId });
    } catch (e) {
      console.log("error closing session: ", e);
    }
  };

  return (
    <ActionConfirmDialog
      message="No more pending stories.  Do you wish to end this session?"
      title="Close Session?"
      buttonText="Close Session"
      action={closeSessionAction}
      isVisible={showCloseSession}
      setIsVisible={setShowCloseSession}
    />
  )
}

export default AutoCloseSession;