import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import _ from "lodash";
import * as yup from "yup";

import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ShowLoading from "components/processing/ShowLoading";
import ShowSaving from "components/processing/ShowSaving";
import TeamDialogContent from "./TeamDialogContent";
import TeamDialogActions from "../DefaultDialogActions";
import { useGetTeamQuery, useUpdateTeamMutation, useNewTeamMutation } from "api/common";
import { hideTeamForm } from "store/dialogs";
import { useAppDispatch, useAppSelector } from "store";

const defaultValues = {
  teamId: null,
  name: "",
  description: "",
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required("You must enter a valid Name"),
});

const TeamDialog = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { teamId, show, orgId } = useAppSelector(({ dialogs }) => dialogs.teamForm);

  const isNewTeam = !(teamId && teamId !== 0);

  const { data: teamData, isLoading } = useGetTeamQuery(
    {
      teamId,
      orgId,
    },
    { skip: isNewTeam }
  );

  const [updateTeam, { isLoading: isSavingUpdate }] = useUpdateTeamMutation();
  const [newTeam, { isLoading: isSavingNew }] = useNewTeamMutation();

  const { control, reset, handleSubmit, formState } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (_.isEmpty(teamData)) {
      return;
    }

    reset({
      ...defaultValues,
      ...teamData,
    });
  }, [teamData, reset, isNewTeam]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideTeamForm());
  }

  /**
   * Save updated team
   */
  const saveTeamUpdate: SubmitHandler<FieldValues> = async (model) => {
    try {
      const result = await updateTeam({
        orgId,
        teamId,
        data: {
          name: model.name,
        },
      }).unwrap();

      if (result.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("Team Info Successfully Changed", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error Changing Team Info", {
        variant: "error",
      });
    }
  };

  /**
   * Save new team
   */
  const saveNewTeam: SubmitHandler<FieldValues> = async (model) => {
    try {
      const result = await newTeam({ orgId, data: { orgId, ...model } }).unwrap();

      if (result.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("New Team Successfully Created", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error creating new team", {
        variant: "error",
      });
    }
  };

  /**
   * Submit Form
   */
  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    if (isNewTeam) {
      saveNewTeam(model);
    } else {
      saveTeamUpdate(model);
    }

    reset(defaultValues);
    closeComposeDialog();
  };

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isSavingUpdate) {
    return <ShowSaving />;
  }
  if (isSavingNew) {
    return <ShowSaving />;
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={show === null ? false : show}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>{isNewTeam ? "New Team" : "Edit Team"} </strong>
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col md:overflow-hidden">
        <TeamDialogContent control={control} formState={formState} />
        <TeamDialogActions saveText={isNewTeam ? "Add Team" : "Save Team"} formState={formState} onCancel={closeComposeDialog} />
      </form>
    </Dialog>
  );
};

export default TeamDialog;
