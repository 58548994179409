import React, { useState } from 'react';

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useSetUserToObserverMutation, useSetUserToParticipantMutation } from "api/sessions";



interface ManageObserverStatusProps {
  isObserver: boolean;
  enabled: boolean;
  sessionId: number;
}

const ManageObserverStatus = ({ isObserver, enabled, sessionId }: ManageObserverStatusProps) => {
  const [alignment, setAlignment] = useState((isObserver) ? "observer" : "participant");
  const [ setSelfAsObserver ] = useSetUserToObserverMutation();
  const [ setSelfAsParticipant ] = useSetUserToParticipantMutation();
  
  const handleChange = async (_: React.MouseEvent<HTMLElement, MouseEvent>, newAlignment: string) => {
    console.log(newAlignment, alignment)
    if (newAlignment != alignment && newAlignment != null) {
      if (newAlignment == "observer") {
        setSelfAsObserver({sessionId});
      } 
      else {
        setSelfAsParticipant({sessionId});
      }
      setAlignment(newAlignment);
    }
  };

  if (!enabled) {
    return (
      <></>
    );
  }

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <ToggleButtonGroup value={alignment} exclusive onChange={handleChange} aria-label="UserStatus">
          <ToggleButton value="participant">Participant</ToggleButton>
          <ToggleButton value="observer">Observer</ToggleButton>
          {/* <ToggleButton value="captain">Captain</ToggleButton> */}
        </ToggleButtonGroup>
      </Stack>
    </Grid>
  );
}

export default ManageObserverStatus;