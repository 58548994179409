import React from 'react';

import AvatarCardList from "components/session/AvatarCardList";
import Grid from "@mui/material/Grid";

import { useAppSelector } from "store";

const PointingAvatarList = () => {
  const users = useAppSelector(({ session }) => session.participants);
  const showPoints = useAppSelector(({ session }) => (session?.activeStory?.status == "finished" && session?.data?.status == "started"));

  return (
    <Grid item xs={12}>
      <AvatarCardList users={users} showPoints={showPoints}/>
    </Grid>
  )
}

export default PointingAvatarList;