import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";

import useAutoLogout from "hooks/use-auto-logout";
import useCheckAuth from "hooks/use-check-auth";
import { useLogoutMutation, useRefreshTokenMutation } from "api/auth";
import { getRefreshToken } from "common/auth/storage";
import { useAppSelector } from "store";

interface AuthSystemProps {
  children: ReactNode;
}

/**
 * Handle tracking and confirming auth logout and access
 * @param {AuthSystemProps} props
 * @returns
 */
const AuthSystems = (props: AuthSystemProps) => {
  const location = useLocation();
  const userRole = useAppSelector((state) => state.auth.role);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  useCheckAuth(location.pathname, userRole);

  const [refreshToken] = useRefreshTokenMutation();
  const [logoutUser] = useLogoutMutation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { showConfirm, confirmHandled, setShowConfirm } = useAutoLogout();

  /**
   * Log user out now
   */
  const handleRefresh = async () => {
    confirmHandled();
    await refreshToken({
      refresh_token: getRefreshToken(),
    }).unwrap();
  };

  /**
   * Force token to refresh if a user wnats to stay logged in
   */
  const handleLogout = () => {
    confirmHandled();
    logoutUser({refresh_token: getRefreshToken(),  auto: false });
  };

  useEffect(() => {
    if (!isLoggedIn && showConfirm) {
      setShowConfirm(false);
    }
  }, [isLoggedIn, showConfirm, setShowConfirm]);

  if (!isLoggedIn) {
    return <>{props.children}</>;
  }

  return (
    <>
      <Dialog
        onClose={handleRefresh}
        open={showConfirm}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Your session is about to expire"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your current session is about to expire in <b>5 Minutes</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleRefresh}>
            Stay Logged In
          </Button>
          <Button onClick={handleLogout} autoFocus>
            Logout Now
          </Button>
        </DialogActions>
      </Dialog>

      {props.children}
    </>
  );
};

export default AuthSystems;
