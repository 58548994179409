/**
 * Authentication Slice
 *
 */
import { createSlice } from "@reduxjs/toolkit";

import { deleteTokens, saveTokens } from "../common/auth/storage";
import { authApi } from "api/auth";
import { api as userApi } from "api/common";
import { AuthStateType } from "./types";

// export const logoutUser = () => async (dispatch: AppDispatch, getState: () => RootState) => {
//   const { role } = getState().auth;

//   if (role === "public") {
//     return null;
//   }

//   //redirect( "/login")

//   dispatch(util.resetApiState());

//   dispatch(logout.initiate({ auto: false }));
//   deleteTokens();

//   redirect("/login");
//   return dispatch(unsetAuthData());
// };

const defaultState: AuthStateType = {
  accessToken: "",
  expireAt: 0,
  isLoggedIn: false,
  refreshToken: "",
  refreshExpireAt: 0,
  error: false,
  userId: 0,
  role: "public",
  email: "",
};

const slice = createSlice({
  name: "auth",
  initialState: defaultState,
  reducers: {
    setAuthData(state, action) {
      saveTokens(
        action.payload.accessToken,
        action.payload.refreshToken,
        action.payload.expireAt,
        action.payload.refreshExpireAt,
        action.payload.userId,
        action.payload.role,
        action.payload.email
      );

      state.accessToken = action.payload.accessToken;
      state.expireAt = action.payload.expireAt;
      state.isLoggedIn = true;
      state.refreshToken = action.payload.refreshToken;
      state.refreshExpireAt = action.payload.refreshExpireAt;

      state.email = action.payload.email;
      state.userId = action.payload.userId;
      state.role = action.payload.role;

      // @todo this should probably be portal wide...?  unless owner is reviewing?
      // dispatch(setDefaultSettings(user.data.settings));
    },
    unsetAuthData(state) {
      deleteTokens();
      state.accessToken = "";
      state.expireAt = 0;
      state.isLoggedIn = false;
      state.refreshToken = "";
      state.refreshExpireAt = 0;
      state.userId = 0;
      state.email = "";
      state.role = "public";
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      saveTokens(
        payload.accessToken,
        payload.refreshToken,
        payload.expireAt,
        payload.refreshExpireAt,
        payload.userId,
        payload.role,
        payload.email
      );
      state.accessToken = payload.accessToken;
      state.expireAt = payload.expireAt;
      state.isLoggedIn = true;
      state.refreshToken = payload.refreshToken;
      state.refreshExpireAt = payload.refreshExpireAt;
      state.userId = payload.userId;
      state.role = payload.role;
      state.email = payload.email;
    });

    builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, { payload }) => {
      saveTokens(
        payload.accessToken,
        payload.refreshToken,
        payload.expireAt,
        payload.refreshExpireAt,
        payload.userId,
        payload.role,
        payload.email
      );

      state.accessToken = payload.accessToken;
      state.expireAt = payload.expireAt;
      state.isLoggedIn = true;
      state.refreshToken = payload.refreshToken;
      state.refreshExpireAt = payload.refreshExpireAt;
      state.userId = payload.userId;
      state.role = payload.role;
      state.email = payload.email;
    });

    builder.addMatcher(userApi.endpoints.completeRegistration.matchFulfilled, (state, { payload }) => {
      saveTokens(
        payload.accessToken,
        payload.refreshToken,
        payload.expireAt,
        payload.refreshExpireAt,
        payload.userId,
        payload.role,
        payload.email
      );
      state.accessToken = payload.accessToken;
      state.expireAt = payload.expireAt;
      state.isLoggedIn = true;
      state.refreshToken = payload.refreshToken;
      state.refreshExpireAt = payload.refreshExpireAt;
      state.userId = payload.userId;
      state.role = payload.role;
      state.email = payload.email;
    });

    builder.addMatcher(userApi.endpoints.register.matchFulfilled, (state, { payload }) => {
      saveTokens(
        payload.accessToken,
        payload.refreshToken,
        payload.expireAt,
        payload.refreshExpireAt,
        payload.userId,
        payload.role,
        payload.email
      );
      state.accessToken = payload.accessToken;
      state.expireAt = payload.expireAt;
      state.isLoggedIn = true;
      state.refreshToken = payload.refreshToken;
      state.refreshExpireAt = payload.refreshExpireAt;
      state.userId = payload.userId;
      state.role = payload.role;
      state.email = payload.email;
    });


    builder.addMatcher(userApi.endpoints.inviteRegister.matchFulfilled, (state, { payload }) => {
      saveTokens(
        payload.accessToken,
        payload.refreshToken,
        payload.expireAt,
        payload.refreshExpireAt,
        payload.userId,
        payload.role,
        payload.email
      );
      state.accessToken = payload.accessToken;
      state.expireAt = payload.expireAt;
      state.isLoggedIn = true;
      state.refreshToken = payload.refreshToken;
      state.refreshExpireAt = payload.refreshExpireAt;
      state.userId = payload.userId;
      state.role = payload.role;
      state.email = payload.email;
    });

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state, { meta }) => {
      const autoLogout = meta?.arg?.originalArgs?.auto;

      if (autoLogout) {
        localStorage.setItem("logoutAction", "auto");
      } else {
        localStorage.setItem("logoutAction", "show");
      }

      state.accessToken = "";
      state.expireAt = 0;
      state.isLoggedIn = false;
      state.refreshToken = "";
      state.refreshExpireAt = 0;
      state.userId = 0;
      state.role = "public";
      state.email = "";

      deleteTokens();
    });
    // alwats remove token data, even if api request to logout fails
    builder.addMatcher(authApi.endpoints.logout.matchRejected, (state, { meta }) => {
      const autoLogout = meta?.arg?.originalArgs?.auto;

      if (autoLogout) {
        localStorage.setItem("logoutAction", "auto");
      } else {
        localStorage.setItem("logoutAction", "show");
      }

      state.accessToken = "";
      state.expireAt = 0;
      state.isLoggedIn = false;
      state.refreshToken = "";
      state.refreshExpireAt = 0;
      state.userId = 0;
      state.role = "public";
      state.email = "";

      deleteTokens();
    });
  },
});

// see if we should export the actions another way
export const setAuthData = slice.actions.setAuthData;
export const unsetAuthData = slice.actions.unsetAuthData;
export const authActions = slice.actions;
export default slice.reducer;
