import React from "react";
import { InView } from "react-intersection-observer";

import MyAvatar from "./MyAvatar";

interface AsyncAvatarProps {
  src: string;
  width: number;
  height: number;
}

const AsyncAvatar = ({ src, width, height }: AsyncAvatarProps) => {
  return (
    <InView triggerOnce>
      {({ ref, inView }) => (
        <div ref={ref}>
          <MyAvatar inView={inView} src={src} width={width} height={height} />
        </div>
      )}
    </InView>
  );
};

export default AsyncAvatar;
