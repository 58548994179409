import React from "react";

interface StoryNameProps {
  name: string;
  link: string;
}

const StoryName = ({ name, link }: StoryNameProps) => {
  if (link && link != "") {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {name}
      </a>
    );
  }
  return <>{name}</>;
};

export default StoryName;
