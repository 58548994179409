import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TextInput from "components/inputs/TextInput";
import OrgSelect from "components/session/inputs/OrgSelect";
import TeamSelect from "components/session/inputs/TeamSelect";
import { useCreateSessionMutation } from "api/sessions";

const schema = yup.object().shape({
  name: yup.string().required("Valid name is required"),
  orgId: yup.string().required("Valid Organization is required"),
  teamId: yup.string().required("Valid Team is required"),
});

// see if we can default these from previous used?  or if we still need useEffect
const defaultValues = {
  name: "",
  orgId: "",
  teamId: "",
};

const SessionForm = () => {
  const navigate = useNavigate();
  //@todo should default to last one used
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const [formError, setFormError] = useState("");

  //const hasTeam = teamData?.[0]?.teamId;

  const [createSession, { error: apiError }] = useCreateSessionMutation();

  const { control, watch, setValue, formState, handleSubmit } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      setWasSubmitted(true);
      const sessionData = await createSession(model).unwrap();
      //history.push("/create-session/" + sessionData.token + "/1");
      navigate("/setup-session/" + sessionData.token + "/2");
    } catch (e) {
      setFormError("Error creating new session");
      setWasSubmitted(false);
    }
  };

  const watchOrgId = watch("orgId", 0);
  const currentOrgId = watchOrgId == "" ? 0 : watchOrgId;

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Session Details
      </Typography>

      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          {formError && <Alert severity="error">{formError}</Alert>}
          {(!formError && apiError) && <Alert severity="error">Error creating Session</Alert>}
          {(wasSubmitted && !formError && !apiError) && <Alert severity="info">Creating Pointing Session</Alert>}
        </Grid>
      </Grid>
      {!wasSubmitted && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput control={control} formState={formState} label="Session Name" variant="outlined" name="name" />
            </Grid>

            <OrgSelect control={control} formState={formState} setValue={setValue} />

            <TeamSelect control={control} formState={formState} orgId={currentOrgId} setValue={setValue} />
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" type="submit" color="success" sx={{ mt: 3, ml: 1 }}>
              Create Session
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SessionForm;
