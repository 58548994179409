import React from "react";

import useSetPageTitle from "hooks/use-set-page-title";

import Pusher from "pusher-js";

const TestPage = () => {
  const client = new Pusher("app-key", {
    wsHost: "localhost", // @todo change this to env variable ???
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    cluster: "mt1",
  });

  client.subscribe("pointing").bind("message", (message: string) => {
    console.log({});
    console.log("message", message);
  });

  useSetPageTitle("Test Page");

  return <p>Test Page</p>;
};

export default TestPage;
