import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DeleteAccountForm from "components/user/profile/DeleteAccountForm";
import ProfileForm from "components/user/profile/ProfileForm";
import PasswordForm from "components/user/profile/PasswordForm";
import NotificationsForm from "components/user/profile/NotificationsForm";


const SettingsPage = () => {
  return (
    <Box sx={{mt: 2}}>
      <Grid container spacing={2} alignContent="center" justifyContent="center">

        <Grid item md={5} xs={12}>
          <Card elevation={3} sx={{ borderRadius: 3, mt: 3 }}>
            <AppBar position="static" elevation={2} sx={{ p: 1 }}>
              <Typography align="center" variant="h5" sx={{ mb: 0 }}>
                Your Profile
              </Typography>
            </AppBar>

            <CardContent>
              <ProfileForm />
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={5}>
          <Card elevation={3} sx={{ borderRadius: 3, mt: 3 }}>
            <AppBar position="static" elevation={2} sx={{ p: 1 }}>
              <Typography align="center" variant="h5" sx={{ mb: 0 }}>
                Change Password
              </Typography>
            </AppBar>

            <CardContent>
              <PasswordForm /> 
            </CardContent>
          </Card>
        </Grid>

       
        <Grid item md={5}>
          <Card elevation={3} sx={{ borderRadius: 3, mt: 3 }}>
            <AppBar position="static" elevation={2} sx={{ p: 1 }}>
              <Typography align="center" variant="h5" sx={{ mb: 0 }}>
                Notifications
              </Typography>
            </AppBar>

            <CardContent >
              <NotificationsForm />
            </CardContent>
          </Card>
        </Grid>
       
        <Grid item md={5}>
          <Card elevation={3} sx={{ borderRadius: 3, mt: 3 }}>
            <AppBar position="static" elevation={2} sx={{ p: 1 }}>
              <Typography align="center" variant="h5" sx={{ mb: 0 }}>
                Account Actions
              </Typography>
            </AppBar>

            <CardContent >
              <DeleteAccountForm />
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default SettingsPage;
