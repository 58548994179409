import React from "react";

import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import useSetPageTitle from "hooks/use-set-page-title";
import OrgTeamDisplay from "components/user/teams/OrgTeamDisplay";
import ListUserInvites from "components/user/ListUserInvites";
import { showCreateOrgForm } from "store/dialogs";
import { useAppDispatch } from "store";

const TeamListPage = () => {
  const dispatch = useAppDispatch()
  useSetPageTitle("Teams");

  function handleAddOrg() {
    dispatch(showCreateOrgForm());
  }

  return (
    <Box sx={{ flexGrow: 1, pt: 5 }}>
      
      <ListUserInvites />

      <Card elevation={3} sx={{ borderRadius: 3, mt: 3 }}>
        <AppBar position="static" elevation={2} sx={{ p: 1 }}>
          <Typography align="center" variant="h5" sx={{ mb: 0 }}>
            Organizations & Teams
          </Typography>
        </AppBar>

        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" sx={{ mt: 2, mr: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleAddOrg}>Create Organization</Button>
        </Stack>

        <CardContent>
          <OrgTeamDisplay />
        </CardContent>
      </Card>
    </Box>

  );
};

export default TeamListPage;
