import React from "react";

import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";

interface TableFilterTabsProps {
    children?: React.ReactNode;
    value: string;
  }
  
  const TableFilterTabs = styled((props: TableFilterTabsProps) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      // backgroundColor: '#635ee7',
    },
  });
  
  export default TableFilterTabs;