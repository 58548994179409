import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  "&:hover": {
    backgroundColor: grey[400],
  },
}));

const images = [
  {
    src: "/static/images/site-screen-shots/dashboard.png",
    label: "Dashboard",
  },
  {
    src: "/static/images/site-screen-shots/pointing1.png",
    label: "Pointing (pending)",
  },
  {
    src: "/static/images/site-screen-shots/pointing2.png",
    label: "Pointing (started)",
  },
  {
    src: "/static/images/site-screen-shots/pointing3.png",
    label: "Pointing (finished)",
  },
  {
    src: "/static/images/site-screen-shots/pointing4.png",
    label: "Pointing (Save)",
  },
  {
    src: "/static/images/site-screen-shots/stats.png",
    label: "Stats Overview",
  },
  {
    src: "/static/images/site-screen-shots/stats-stories.png",
    label: "Stats Stories",
  },
  {
    src: "/static/images/site-screen-shots/stats-users.png",
    label: "Stats Participants",
  },
];

const Screenshots = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 540, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 355,
                  display: "block",
                  maxWidth: 535,
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.src}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <ColorButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </ColorButton>
        }
        backButton={
          <ColorButton size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </ColorButton>
        }
      />
    </Box>
  );
};

export default Screenshots;
