import React from "react";

import ShowLoading from "components/processing/ShowLoading";
import { useOrgMembersListQuery } from "api/common";
import MemberTable from "./MemberTable";

interface OrgMemberListProps {
  orgId: number;
}


const OrgMemberList = ({ orgId }: OrgMemberListProps) => {
  const {data, isLoading, isError } = useOrgMembersListQuery({orgId}, {skip: !(orgId > 0)})
  const members = data?.data || [];

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    return <>Error fetching members</>
  }

  return <MemberTable members={members} orgId={orgId} memberType="org" />
}

export default OrgMemberList;