import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import _ from "lodash";
import * as yup from "yup";

import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ShowLoading from "components/processing/ShowLoading";
import ShowSaving from "components/processing/ShowSaving";
import OrgDialogContent from "./OrgDialogContent";
import OrgDialogActions from "../DefaultDialogActions";
import { useGetOrgQuery, useUpdateOrgMutation, useNewOrgMutation } from "api/common";
import { hideOrgForm } from "store/dialogs";
import { useAppDispatch, useAppSelector } from "store";
import { OrgDialogForm } from "./types";

const defaultValues = {
  orgId: 0,
  name: "",
};

/**
 * Form Validation Schema
 */
const schema = yup.object({
  name: yup.string().required("You must enter a valid Name"),
  orgId: yup.number(),
});

const OrgDialog = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { orgId, show } = useAppSelector(({ dialogs }) => dialogs.orgForm);

  const isNewOrg = !(orgId && orgId !== 0);

  const { data: orgData, isLoading } = useGetOrgQuery(
    {
      orgId: orgId,
    },
    { skip: isNewOrg }
  );

  const [updateOrg, { isLoading: isSavingUpdate }] = useUpdateOrgMutation();
  const [newOrg, { isLoading: isSavingNew }] = useNewOrgMutation();

  const { control, reset, handleSubmit, formState } = useForm<OrgDialogForm>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (_.isEmpty(orgData)) {
      return;
    }

    reset({
      ...defaultValues,
      ...orgData,
    });
  }, [orgData, reset]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideOrgForm());
  }

  /**
   * Save updated org
   */

  const saveOrgUpdate: SubmitHandler<FieldValues> = async (model) => {
    try {
      const result = await updateOrg({
        orgId,
        data: {
          name: model.name,
        },
      }).unwrap();

      if (result.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("Org Info Successfully Changed", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error Changing Org Info", {
        variant: "error",
      });
    }
  };

  /**
   * Save new org
   */
  const saveNewOrg: SubmitHandler<FieldValues> = async (model) => {
    try {
      const result = await newOrg(model).unwrap();

      if (result.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("New Org Successfully Created", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error creating new org", {
        variant: "error",
      });
    }
  };

  /**
   * Submit Form
   */
  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    if (isNewOrg) {
      saveNewOrg(model);
    } else {
      saveOrgUpdate(model);
    }

    closeComposeDialog();
  };

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isSavingUpdate) {
    return <ShowSaving />;
  }
  if (isSavingNew) {
    return <ShowSaving />;
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={show === null ? false : show}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>{isNewOrg ? "New Org" : "Edit Org"} </strong>
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col md:overflow-hidden">
        <OrgDialogContent control={control} formState={formState} />
        <OrgDialogActions
          saveText={isNewOrg ? "Add Org" : "Save Org"}
          formState={formState}
          onCancel={closeComposeDialog}
        />
      </form>
    </Dialog>
  );
};

export default OrgDialog;
