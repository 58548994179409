import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import SiteName from "./SiteName";
import UserMenu from "./UserMenu";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar component="nav">
        <Container maxWidth="lg">
          <Toolbar disableGutters={true}>
            <IconButton
              edge="start"
              size="large"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xs: "flex", sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <SiteName />

            <DesktopMenu />

            <UserMenu />
          </Toolbar>
        </Container>
      </AppBar>
      <MobileMenu mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle} />
    </>
  );
};

export default Header;
