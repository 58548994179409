import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { primaryMenu } from "configs/menu";
import { useAppSelector } from "store";
import { MenueItem } from "common/types";
import SubMenu from "./SubMenu";


function outputEntry(entry: MenueItem) {
  if (entry.path) {
    return (
      <Button color="inherit" component={RouterLink} to={entry.path}>
        {entry.name}
      </Button>
    )
  }
  else if (entry.children) {
    return <SubMenu title={entry.name} items={entry.children} />
  }
  
  return <>{entry.name}</>
}

/**
 * Render Main menu used in desktop view
 * @returns
 */
const DesktopMenu = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const isGuest = useAppSelector((state) => !!(state.auth.role == "guest"));

  const [menuGroup, setMenuGroup] = useState("nonUser");

  useEffect(() => {
    if (isLoggedIn) {
      if (isGuest) {
        setMenuGroup("guest");
      } else {
        setMenuGroup("user");
      }
    } else {
      setMenuGroup("nonUser");
    }
  }, [isLoggedIn, isGuest]);

  return (
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      {primaryMenu[menuGroup].map((entry) => {
        return (
          <Fragment key={`desktop-menu-entry-${entry.name}-${entry.path}`}>
            {outputEntry(entry)}
          </Fragment>
        )})}
    </Box>
  );
};

export default DesktopMenu;
