import _ from "lodash";
import { useEffect, useState } from "react";

const useSetPageTitle = (title: string) => {
  const [pageTitle, setPageTitle] = useState(title);
  /**
   * Set page title in proper format
   */
  useEffect(() => {
    const portalName = "Story Point Cards";
    if (_.isEmpty(title)) {
      document.title = portalName;
      setPageTitle(document.title);
    } else {
      document.title = title + " - " + portalName;
      setPageTitle(document.title);
    }
  }, [pageTitle, title]);

  // allow title to be changed using setState
  return { pageTitle, setPageTitle };
};

export default useSetPageTitle;
