import React from "react";
import { useParams, Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";


import useSetPageTitle from "hooks/use-set-page-title";
import TableFilterTabs from "components/tabs/TableFilterTabs";
import TableFilterTab from "components/tabs/TableFilterTab";
import NoSessionStoriesTab from "components/session/stories/tabs/NoSessionStoriesTab";
import PendingStoriesTab from "components/session/stories/tabs/PendingStoriesTab";
import PointedStoriesTab from "components/session/stories/tabs/PointedStoriesTab";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  minHeight: 150,
}));


function getStatView(tab: string | undefined) {
  switch (tab) {
    case "no-session":
      return <NoSessionStoriesTab />;
    case "pointed":
      return <PointedStoriesTab />;
    case "pending":
    default:
      return <PendingStoriesTab />;
  }
}


const StoryListPage = () => {
  useSetPageTitle("List of Stories");
  const routeParams = useParams();
  const { tab } = routeParams;
  const currentTab = tab || "pending";

  return (

    <Box sx={{ flexGrow: 1, pt: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item elevation={3}>
          <Box >
                  <TableFilterTabs value={currentTab} aria-label="pointing stoies">
                    <TableFilterTab label="Pending" value="pending" to="/stories/pending" component={Link} />
                    <TableFilterTab label="Pointed" value="pointed" to="/stories/pointed" component={Link} />
                    <TableFilterTab label="Unassigned" value="no-session" to="/stories/no-session" component={Link} />
                  </TableFilterTabs>
              </Box>
            <Box sx={{ width: "100%", my: 2 }}>
              {getStatView(currentTab)}
            </Box>

           </Item>
         </Grid>
       </Grid>
     </Box>
  );
};

export default StoryListPage;
