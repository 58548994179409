import React from "react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import OrgDialog from "components/dialogs/OrgDialog";
import TeamDialog from "components/dialogs/TeamDialog";
import useSetPageTitle from "hooks/use-set-page-title";
import AddStoryForm from "components/session/stories/AddStoryForm";

// maybe modify form comonent to remove title and/or allow session session when sessionId=0

const CreateStoryPage = () => {
  useSetPageTitle("Create Pointing Story");
  return (
    <>
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Create Pointing Story
          </Typography>
          <AddStoryForm sessionId={0} />
        </Paper>
      </Container>

      <OrgDialog />
      <TeamDialog />
    </>
  );
};

export default CreateStoryPage;
