import React from "react";

import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import InvitesDisplay from "components/user/invites/InvitesDisplay";
import { useInvitesListQuery } from "api/common";


const ListUserInvites = () => {

  const {data, isLoading} = useInvitesListQuery({});
  console.log(data, isLoading)

  if (isLoading) {
    return <></>
  }

  const noData = (!data?.data || !data.data?.length || !(data.data.length > 0))

  if (noData) {
    return <></>
  }
  
  return (

    <Card elevation={3} sx={{ borderRadius: 3, mt: 3 }}>

      <AppBar position="static" elevation={2} sx={{ p: 1 }}>
        <Typography align="center" variant="h5" sx={{ mb: 0 }}>
          Pending Invites
        </Typography>
      </AppBar>

      <CardContent>
        <InvitesDisplay invites={data.data} />
      </CardContent>
    </Card>
  )
}

export default ListUserInvites
