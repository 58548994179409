import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton  from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { primaryMenu, secondaryMenu } from "configs/menu";
import { useAppSelector } from "store";

const MenuDrawer = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const isGuest = useAppSelector((state) => !!(state.auth.role == "guest"));

  const [menuGroup, setMenuGroup] = useState("nonUser");

  useEffect(() => {
    if (isLoggedIn) {
      if (isGuest) {
        setMenuGroup("guest");
      } else {
        setMenuGroup("user");
      }
    } else {
      setMenuGroup("nonUser");
    }
  }, [isLoggedIn, isGuest]);

  return (
    <div>
      <List>
        <ListItem />
        {primaryMenu[menuGroup].map((entry) => (
          <ListItemButton to={entry.path as string} key={`mobile-menu-primary-entry-${entry.name}-${entry.path}`} component={RouterLink}>
            <ListItemText primary={entry.name} />
          </ListItemButton >
        ))}
        <ListItem />
      </List>
      <Divider />
      <List>
        <ListItem />
        {secondaryMenu[menuGroup].map((entry) => (
          <ListItemButton to={entry.path as string} key={`mobile-menu-secondary-entry-${entry.name}-${entry.path}`} component={RouterLink}>
            <ListItemText primary={entry.name} />
          </ListItemButton >
        ))}
      </List>
    </div>
  );
};

export default MenuDrawer;
