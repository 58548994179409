import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

import store from "./store";
import { setAuthData } from "./store/auth";
import { loadTokens } from "./common/auth/storage";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const authData = loadTokens(true);
if (authData) {
  store.dispatch(setAuthData(authData));
}

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
