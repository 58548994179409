import React from "react";

import List from "@mui/material/List";

import { SessionUser } from "common/types";
import ParticipantEntry from "./ParticipantEntry";
// import IconButton from "@mui/material/IconButton";

interface ParticipantListProps {
  data: SessionUser[];
}

function ParticipantList({ data }: ParticipantListProps) {
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
      }}
    >
      {data.map((entry) => (
        <ParticipantEntry key={`participant-sidebar-${entry.userId}`} user={entry} />
      ))}
    </List>
  );
}

export default ParticipantList;
