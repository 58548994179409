import React from "react";

import Button from "@mui/material/Button";

import { useSessionStartMutation, useSessionCloseMutation } from "api/sessions";
import { useAppDispatch, useAppSelector } from "store";
import { showArchiveSession } from "store/dialogs";

interface SessionStartButtonsProps {
  sessionId: number;
}

const SessionStartButtons = ({ sessionId }: SessionStartButtonsProps) => {
  const dispatch = useAppDispatch();
  const [sessionStart] = useSessionStartMutation();
  const [sessionClose] = useSessionCloseMutation();
  
  const firstStoryId = useAppSelector(({ session }) => (session?.stories[0]?.storyId) ? session.stories[0].storyId : 0);
  const totalPending = useAppSelector(({ session }) => (session?.stories.length || 0));
  
  const handleStartSession = async () => {
    try {
      await sessionStart({ sessionId: sessionId, storyId: firstStoryId});
    } catch (e) {
      console.log("error starting session: ", e);
    }
  };

  const handleCloseSession = async () => {
    // if we have pending, show dialog.  otherwise chose close here
    if (totalPending > 0) {
      dispatch(showArchiveSession({sessionId}))
    }
    else {
      try {
        await sessionClose({ sessionId: sessionId });
      } catch (e) {
        console.log("error closing session: ", e);
      }
    }
  };

  return (
    <>
      <Button type="submit" variant="contained" size="small" onClick={handleStartSession}>
        Start Session
      </Button>

      <Button type="submit" variant="contained" size="small" onClick={handleCloseSession}>
        Archive Session
      </Button>
    </>
  );
};

export default SessionStartButtons;
