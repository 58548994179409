import React from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { useAppDispatch } from "store";
import { showImportStoryToSession, showNewSessionStoryForm } from "store/dialogs";

interface StoryControlsProps {
  sessionId: number;
}

const StoryControls = ({ sessionId }: StoryControlsProps) => {
  const dispatch = useAppDispatch();
  const handleAddSessionStory = () => {
    dispatch(
      showNewSessionStoryForm({
        sessionId: sessionId,
        storyId: 0,
      })
    );
  };
  const handleImportSessionStory = () => {
    dispatch(
      showImportStoryToSession({
        sessionId: sessionId
      })
    );
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ my: 1 }}>
      <Button type="submit" variant="contained" size="small" onClick={handleAddSessionStory}>
        Add Story
      </Button>
      <Button type="submit" variant="contained" size="small" onClick={handleImportSessionStory}>
        Import Story
      </Button>
    </Stack>
  );
};

export default StoryControls;
