import React, { useCallback, useEffect, useState } from "react";
import { useDrop } from 'react-dnd'

import MuiList from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

import StoryListItem from "./StoryListItem";
import { useChangeSessionStoryOrderMutation } from "api/sessions";
import { useAppSelector } from "store";
import { Story } from "common/types";


// @todo combine this with setup version of function ...?

//
// do we pop offitem from array?
// we would have to do this any udpate
//

const List = styled(MuiList)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "gold",
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    // backgroundColor: "gold",
  },
});

interface StoryListProps {
  sessionId: number;
  actionIcons: boolean;
}

const reorder = (list: Story[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const StoryList = ({ sessionId, actionIcons }: StoryListProps) => {
  const activeStory = useAppSelector(({ session }) => session.activeStory);
  const storyList = useAppSelector(({ session }) => session.stories);
  const [stories, setStories] = useState(storyList);
  const [changeOrder] = useChangeSessionStoryOrderMutation();

  // todo check if this is besy way!!!
  useEffect(() => {
    setStories(storyList);
  }, [storyList]);

  // find story index
  const findStory = useCallback((storyId: number)  => {
    return stories.findIndex( x => x.storyId === storyId );
  }, [stories]);

  // move story in the index
  const moveStory = useCallback((storyId: number, endIndex: number) : number => {
    const startIndex = stories.findIndex( x => x.storyId === storyId );

    if (startIndex < 0 || endIndex === startIndex) {
      return -1;
    }

    const newOrder = reorder(stories, startIndex, endIndex);
    setStories(newOrder);
    return startIndex
  }, [stories]);
  
  // save move story
  const saveMoveStory = useCallback((storyId: number, startIndex: number, endIndex: number) : void => {
    if ((startIndex < 0) || (startIndex == endIndex)) {
      return;
    }
    if (!(endIndex in storyList)) {
      return;
    }
    
    const targetStoryId = storyList[endIndex].storyId;
    changeOrder({ sessionId, data: { storyId, targetStoryId} });
  }, [stories]);

  const [, drop] = useDrop(() => ({ accept: "StoryListItem" }))
  

  if (stories.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center h-full">
        <Typography color="text.secondary" variant="h5"></Typography>
      </div>
    );
  }

  // @todo  what is session control changing when moving story???

  return (
    <>
        <List dense sx={{ width: "100%", maxHeight: 320, overflow: "auto" }} disablePadding>
              <>
                <div ref={drop}>
                  {stories.map((item, index) => {
                    return (
                      <StoryListItem
                        story={item}
                        index={index}
                        key={`available-story-${item.storyId}`}
                        selected={activeStory && item?.storyId == activeStory?.storyId}
                        actionIcons={actionIcons}

                        moveStory={moveStory}
                        saveMoveStory={saveMoveStory}
                        findStory={findStory}
                      />
                    );
                  })}
                </div>
              </>
        </List>
    </>
  );
};

export default StoryList;
