import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import * as yup from "yup";

import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ShowLoading from "components/processing/ShowLoading";
import ShowSaving from "components/processing/ShowSaving";
import SessionStoryContent from "./StoryContent";
import DefaultDialogActions from "../DefaultDialogActions";
import { hideStoryForm } from "store/dialogs";
import { useAppDispatch, useAppSelector } from "store";
import { useCreateSessionStoryMutation, useCreateStoryMutation, useEditSessionStoryMutation, useEditStoryMutation, useGetStoryQuery } from "api/sessions";

const defaultValues = {
  // storyId: null,
  name: "",
  summary: "",
  link: "",
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required("You must enter a valid Name"),
});

const SessionStoryDialog = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { sessionId, show, storyId } = useAppSelector(({ dialogs }) => dialogs.storyForm);

  const isNewStory = !(storyId && storyId !== 0);

  const [createStory, { isLoading: isSavingNew1 }] = useCreateStoryMutation();
  const [editStory, { isLoading: isSavingUpdate1 }] = useEditStoryMutation();
  const [createSessionStory, { isLoading: isSavingNew2 }] = useCreateSessionStoryMutation();
  const [editSessionStory, { isLoading: isSavingUpdate2 }] = useEditSessionStoryMutation();

  const { data: storyData, isLoading } = useGetStoryQuery(
    {
      storyId,
    },
    { skip: isNewStory }
  );

  const { control, reset, handleSubmit, formState } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...storyData,
    });
  }, [storyData, reset, show]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideStoryForm());
  }

  /**
   * Save updated story
   */
  const saveStoryUpdate: SubmitHandler<FieldValues> = async (model) => {
    try {
      const useSessionId = storyData?.sessionId || sessionId;
      console.log("useSessionId", useSessionId, storyData?.sessionId , sessionId)
      const params = {
        storyId,
        sessionId: useSessionId,
        data: {
          name: model.name,
          summary: model.summary,
          link: model.link,
        },
      };

      // call api with sessionId if it exists.  this ensure active sessions get updated
      const result = (useSessionId == 0) ? await editStory(params).unwrap() : await editSessionStory(params).unwrap();

      if (result.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("Story Info Successfully Changed", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error Changing Story Info", {
        variant: "error",
      });
    }
  };

  /**
   * Save new story
   */
  const saveNewStory: SubmitHandler<FieldValues> = async (model) => {
    try {
      const params = { sessionId, data: model };
      const result = (sessionId == 0) ? await createStory(params).unwrap() : await createSessionStory(params).unwrap();

      if (result.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("New Story Successfully Created", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error creating new story", {
        variant: "error",
      });
    }
  };

  /**
   * Submit Form
   */
  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    if (isNewStory) {
      saveNewStory(model);
    } else {
      saveStoryUpdate(model);
    }

    closeComposeDialog();
  };

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isSavingUpdate1 || isSavingUpdate2) {
    return <ShowSaving />;
  }
  if (isSavingNew1 || isSavingNew2) {
    return <ShowSaving />;
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={show === null ? false : show}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>{isNewStory ? "Add new Story" : "Edit a Story"} </strong>
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col md:overflow-hidden">
        <SessionStoryContent control={control} formState={formState} />

        <DefaultDialogActions
          saveText={isNewStory ? "Create Story" : "Save Story"}
          formState={formState}
          onCancel={closeComposeDialog}
        />
      </form>
    </Dialog>
  );
};

export default SessionStoryDialog;
