import React from "react";
import TeamMemberList from "./TeamMemberList";
import OrgMemberList from "./OrgMemberList";

interface MemberListOutputProps {
  show: boolean;
  orgId: number;
  teamId: number;
}


const MemberListOutput = ({ show, orgId, teamId }: MemberListOutputProps) => {
  console.log("output", show, orgId, teamId)
  if (show) {
    if (orgId && orgId > 0) {
      if (teamId && teamId > 0) {
        return <TeamMemberList orgId={orgId} teamId={teamId} />
      }
      else {
        return <OrgMemberList orgId={orgId} />
      }
    }
  }
  return <></>
}

export default MemberListOutput;