import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Pusher, { Options } from "pusher-js";

import HeaderPanel from "components/session/active/HeaderPanel";
import ParticipantListPanel from "components/session/active/ParticipantListPanel";
import StoryBoard from "components/session/active/StoryBoard";
import StoryDialog from "components/dialogs/StoryDialog";
import SessionControlPanel from "components/session/active/SessionControlPanel";
import ShowLoading from "components/processing/ShowLoading";
import StoryListPanel from "components/session/active/StoryListPanel";

import useSetPageTitle from "hooks/use-set-page-title";
import ArchiveSessionDialog from "components/dialogs/ArchiveSessionDialog";
import AutoCloseSession from "components/session/active/AutoCloseSession";
import PointingActionGrid from "components/session/active/PointingActionGrid";
import ImportStoryDialog from "components/dialogs/ImportStoryDialog";
import { useAllSessionDataQuery, useGetSessionPreviewQuery } from "api/sessions";
import { useAppDispatch, useAppSelector } from "store";
import { actions } from "store/session";
import PreviewSessionDialog from "components/dialogs/PreviewSessionDialog/PreviewSessionDialog";

function pusherSettings() {
  let appKey = "app-key";
  let options: Options = {
    // @todo should this be global?
    wsHost: "localhost", // @todo change this to env variable ???
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    cluster: "mt1",
  };

  if (process.env.NODE_ENV == "production") {
    appKey = "f5eeb099d54de9808901";
    options = {
      cluster: "us2",
    };
  }
  return { appKey, options };
}

const StoryPointPage = () => {
  useSetPageTitle("Story Pointing Session");


  // do we switch out page?  or maybe we have two pages???????


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pusherConfig = pusherSettings();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { token } = routeParams;
  const sessionStatus = useAppSelector(({ session }) => session?.data?.status);
  const isLoggedIn = useAppSelector(({ auth }) => auth.isLoggedIn);

  // load active session data into store for other components to use
  const { isLoading, isError } = useAllSessionDataQuery({ id: token }, {skip: !isLoggedIn});
  const { isLoading: isLoading2} = useGetSessionPreviewQuery({ token }, {skip: isLoggedIn});

  useEffect(() => {
    if (sessionStatus == "finished") {
      navigate("/sessions/" + token, { replace: true });
    }
  }, [sessionStatus]);

  //@todo need to make this work per session info???? maybe just use token from url since that won't change anyway ...??????
  useEffect(() => {
    if (isLoggedIn) {
      const pusherClient = new Pusher(pusherConfig.appKey, pusherConfig.options);
      // maybe make this private with a hidden key ...?
      const channel = pusherClient.subscribe("pointing-" + token);
      // @todo convert object types into real interfaces
      channel.bind("allData", (data: object) => {
        // console.log("hook allData", data);
        dispatch(actions.setAllData(data));
      });

      channel.bind("session", (session: object) => {
        // console.log("hook session", session);
        dispatch(actions.setSessionData(session));
      });

      channel.bind("stories", (stories: object) => {
        // console.log("hook stories", stories);
        dispatch(actions.setSessionStories(stories));
      });

      channel.bind("participants", (participants: object) => {
        //participants
        // console.log("hook participants", participants);
        dispatch(actions.setParticipants(participants));
      });

      channel.bind("activeStory", (story: object) => {
        // console.log("hook activeStory", story);
        dispatch(actions.setActiveStory(story));
      });

      return () => {
        pusherClient.unsubscribe("pointing-" + token);
      };
    }
  }, [isLoggedIn]);

  if (isLoading || isLoading2) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error Loading Session Data</Alert>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={3}>
          <StoryListPanel />
          <SessionControlPanel />
          <ParticipantListPanel />
        </Grid>
        <Grid item xs={9}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={8}>
              <HeaderPanel />
            </Grid>
            <Grid item xs={10}>
              <StoryBoard />
            </Grid>
            <Grid item xs={12}>
              <PointingActionGrid />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isLoggedIn && <PreviewSessionDialog token={token as string} />}
      <StoryDialog />
      <ImportStoryDialog />
      <ArchiveSessionDialog />
      <AutoCloseSession />
    </>
  );
};

export default StoryPointPage;
