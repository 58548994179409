import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface PointCountType {
  point: number;
  count: number;
}

interface PieChartProps {
  data: PointCountType[];
}

interface PieChartState {
  options: {
    labels: string[];
    title: object;
  };
  series: number[];
}
const title = {
  text: "Point Distribution",
  align: "center",
};

const PointPieChart = (props: PieChartProps) => {
  const [data, setData] = useState<PieChartState>({ options: { labels: [], title }, series: [] });

  // const state = {
  //   options: {
  //     labels: ["1 point", "2 points", "3 points", "8 points", "13 points"],
  //   },
  //   series: [44, 55, 41, 17, 15],
  // };

  useEffect(() => {
    const labels: string[] = [];
    const series: number[] = [];
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i].count == 0) {
        continue;
      }

      if (props.data[i].point == -2) {
        labels.push("? points");
        series.push(props.data[i].count);
      } else if (props.data[i].point == -1) {
        labels.push("∞ points");
        series.push(props.data[i].count);
      } else if (props.data[i].count == 1) {
        labels.push(`${props.data[i].point} point`);
        series.push(props.data[i].count);
      } else {
        labels.push(`${props.data[i].point} points`);
        series.push(props.data[i].count);
      }
    }
    setData({
      options: {
        labels,
        title,
      },
      series,
    });
  }, []);

  return (
    <Card sx={{ minWidth: "100%", borderRadius: 3 }}>
      <CardContent>
        <Chart options={data.options} series={data.series} type="pie" width="425" />
      </CardContent>
    </Card>
  );
};

export default PointPieChart;
