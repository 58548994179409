import { SortDirection } from "@mui/material/TableCell";

import { availablePointIndex, pointsStrings } from "configs/playingCards";
import { AvailablePointIndexType, AvatarItem, SessionData, SessionPointType, SessionPointSeriesData, Story, Member } from "./types";

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

/**
 * Convert a expires_in seconds variable to a specific time
 * @param {integer} expires_in
 * @returns {number}
 */
export const calculateExpireTime = (expires_in: number): number => {
  return new Date(new Date().getTime() + +expires_in * 1000).getTime();
};

/**
 * Calulate the remain time
 * @param {integer} expirationTime
 * @returns
 */
export const calculateRemainingTime = (expirationTime: number) => {
  const currentTime = new Date().getTime();
  return expirationTime - currentTime;
};

export const objectToQueryString = (obj: { [key: string]: string }) => {
  const str: string[] = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.length ? "?" + str.join("&") : "";
};

/**
 * Make a default empty SessionData
 * @returns
 */
export function makeDefaultSessionData(): SessionData {
  return {
    sessionId: 0,
    name: "",
    teamId: 0,
    orgId: 0,
    status: "",
    token: "",
    orgName: "",
    teamName: "",
    totalPointed: 0,
    totalPending: 0,
    activeStory: 0,
    controlUserId: 0,
  };
}

/**
 * Make a default empty Story
 * @returns
 */
export function makeDefaultStory(): Story {
  return {
    storyId: 0,
    name: "",
    status: "",
    points: 0,
    summary: "",
    link: "",
    teamId: 0,
    orgId: 0,
    sessionId: 0,
    sessionOrder: 0,
    orgName: "",
    teamName: "",
  };
}

export function getTableDirection(dir: SortDirection): "asc" | "desc" | undefined {
  if (dir == "asc") {
    return "asc";
  }
  if (dir == "desc") {
    return "desc";
  }
  return undefined;
}

export function ComputeActualCardPoints(value: number): string {
  if (value == -2) {
    return "?";
  }
  if (value <= 1.5) {
    return "1";
  }
  if (value <= 2.5) {
    return "2";
  }
  if (value <= 4) {
    return "3";
  }
  if (value <= 6.5) {
    return "5";
  }
  if (value <= 6.5) {
    return "5";
  }
  if (value <= 10.5) {
    return "8";
  }
  if (value <= 17) {
    return "13";
  }
  if (value <= 28) {
    return "21";
  }
  if (value <= 45) {
    return "34";
  }
  if (value <= 72) {
    return "55";
  }
  if (value <= 99) {
    return "89";
  }

  return "∞";
}
export function sessionPoints2SeriesData(points: SessionPointType[], length: number): number[] {
  const series: number[] = new Array(length).fill(0);

  for (let i = 0; i < points.length; i++) {
    const pointIndex = `${points[i].point}`;
    const index = availablePointIndex[pointIndex as keyof AvailablePointIndexType];
    series[index] = points[i].count;
  }
  return series;
}

export function sessionPointsXAxis(maxPoint: string): string[] {
  const min = 0;
  const max = availablePointIndex[maxPoint as keyof AvailablePointIndexType] + 1;
  return pointsStrings.slice(min, max);
}

export function get3SessionSeriesData(session1: SessionPointType[], session2: SessionPointType[], session3: SessionPointType[]): SessionPointSeriesData {
  // get our xaxis outputs
  const maxPoint = Math.max(session1[session1.length - 1].point, session2[session2.length - 1].point, session3[session3.length - 1].point).toString();
  const xaxis = sessionPointsXAxis(maxPoint);

  // normalzise all the session data
  const inputLength = availablePointIndex[maxPoint as keyof AvailablePointIndexType] + 1; // without +1 we dont get primary session in last spot if its short points..?
  const series1 = sessionPoints2SeriesData(session1, inputLength);
  const series2 = sessionPoints2SeriesData(session2, inputLength);
  const series3 = sessionPoints2SeriesData(session3, inputLength);

  // remove spots that are all zero
  for (let i = inputLength - 1; i >= 0; i--) {
    if (series1[i] === 0 && series2[i] === 0 && series3[i] === 0) {
      xaxis.splice(i, 1);
      series1.splice(i, 1);
      series2.splice(i, 1);
      series3.splice(i, 1);
    }
  }

  return {
    xaxis: xaxis.filter((x) => typeof x !== "undefined"),
    series1: series1.filter((x) => typeof x !== "undefined"),
    series2: series2.filter((x) => typeof x !== "undefined"),
    series3: series3.filter((x) => typeof x !== "undefined"),
  };
}

export function getUserAvatarPath(avatarId: number) {
  if (avatarId == undefined || avatarId < 1) {
    return "assets/images/avatars/profile.jpg"
  }
  return "/static/images/avatars/" + avatarId + ".png";
}

export function formatDate(dateTime: string) {
  const myDate = new Date(dateTime);
  return myDate.toLocaleDateString();
}


export const getAvatarList = (max: number): AvatarItem[] => {
  const data: AvatarItem[] = [];
  for (let i = 1; i <= max; i++) {
    data.push({
      id: i,
      path: getUserAvatarPath(i),
    });
  }
  return data;
};

export const currentAvatarList = getAvatarList(81)

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableMemberSort<T>(array: Member[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
