import React from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ShowLoading from "components/processing/ShowLoading";
import ListHeader from "./ListHeader";
import ListStatus from "../ListStatus";

import { useListSessionsQuery } from "api/sessions";
import { useAppSelector } from "store";
import { SessionData } from "common/types";

interface MiniSessionListProps {
  status?: string;
}

const MiniSessionList = ({ status = "" }: MiniSessionListProps) => {
  const activeSession = useAppSelector(({ session }) => session.data);
  // console.log("activeSession", activeSession);

  const { data, isError, isLoading } = useListSessionsQuery({ sort: "status_created_at", limit: 4, status: status });

  const sessions: SessionData[] = data?.data || [];

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error Loading Session Stories</Alert>
      </Grid>
    );
  }

  if (sessions.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-1 items-center justify-center h-full"
      >
        <Typography color="textSecondary" variant="h5">
          There are no pending sessions
        </Typography>
      </motion.div>
    );
  }

  return (
    <div className="w-full flex flex-col">
      <Table size="small" aria-labelledby="tableTitle">
        <ListHeader />
        <TableBody>
          {sessions.map((n) => {
            return (
              <TableRow key={n.sessionId}>
                <TableCell>
                  <Tooltip title={n.name}>
                    <Typography
                      sx={{
                        maxWidth: 150,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {n.name}
                    </Typography>
                  </Tooltip>
                </TableCell>

                <TableCell align="center">{n.totalPending}</TableCell>

                <TableCell component="th" scope="row">
                  <Typography variant="caption" color="text.secondary">
                    {n.orgName}
                  </Typography>
                  <Typography>{n.teamName}</Typography>
                </TableCell>

                <TableCell>
                  {activeSession.sessionId == n.sessionId && <>*</>}
                  <ListStatus name={n.status} />
                </TableCell>

                <TableCell>
                  {n.status == "finished" && (
                    <IconButton component={Link} to={`/sessions/${n.token}`}>
                      <QueryStatsIcon />
                    </IconButton>
                  )}

                  {n.status != "finished" && (
                    <>
                      <IconButton component={Link} to={`/setup-session/${n.token}/1`}>
                        <EditIcon />
                      </IconButton>
                      <IconButton component={Link} to={`/pointing/${n.token}`}>
                        <LaunchIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default MiniSessionList;
