import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const HeaderPanel = () => {

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <Typography component="h1" sx={{ fontSize: 34 }} variant="h2">
          My Stats
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeaderPanel;
