import React from "react";
import { Outlet } from "react-router-dom";

// import AdminLayout from "./AdminLayout";
// import PublicLayout from "./PublicLayout";
import UserLayout from "./UserLayout";
import AuthSystems from "components/auth/AuthSystems";

const Layout = () => {
  // const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  // const role = useAppSelector((state) => state.auth.role);

  return (
    <AuthSystems>
      <UserLayout>
        <Outlet />
      </UserLayout>
    </AuthSystems>
  );

  // if (isLoggedIn) {
  //   if (role == "admin") {
  //     return <AdminLayout>{props.children}</AdminLayout>;
  //   }
  //   return <UserLayout>{props.children}</UserLayout>;
  // }

  // return <PublicLayout>{props.children}</PublicLayout>;
};

export default Layout;
