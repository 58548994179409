import React from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

import Logo from "./logo.png";
import { defaultNonUserPage, defaultUserPage } from "configs/routes";
import { useAppSelector } from "store";

const SiteName = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const url = isLoggedIn ? defaultUserPage : defaultNonUserPage;
  return (
    <>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1, mt: 1 }}>
        <Link to={url}>
          <img src={Logo} alt="Story Point Cards" height="70" />
        </Link>
      </Typography>
    </>
  );
};

export default SiteName;
