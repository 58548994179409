import React, { useState } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
//import TextareaAutosize from "@mui/base/TextareaAutosize";
import Typography from "@mui/material/Typography";
//import Fab from "@mui/material/Fab";
// import TextField from "@mui/material/TextField";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import { SessionData } from "common/types";

/*
const CopyToClipboardButton = () => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
      setOpen(true)
      navigator.clipboard.writeText(window.location.toString()))
    }
    
    return (
        <>
          <Button onClick={handleClick}>Share</Button>
          <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
            message="Copied to clipboard"
          />
        </>
    )
 }
*/
// export default CopyToClipboardButton

interface ReviewProps {
  sessionData: SessionData;
}

const Review = ({ sessionData }: ReviewProps) => {
  const sessionLink = window.location.origin + "/pointing/" + sessionData.token;

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(sessionLink);
  };

  return (
    <>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message="Coppied..." />
      <Typography variant="h6" gutterBottom>
        Review Pointing Session Details
      </Typography>

      <Grid container>
        <Grid item xs={4}>
          <Typography gutterBottom>Name</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{sessionData.name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Organization</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{sessionData.orgName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Team</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{sessionData.teamName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                Session Link
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <Link to={sessionLink}>{sessionLink}</Link>
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={handleClick}>Copy Link</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Review;
