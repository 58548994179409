import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TextInput from "components/inputs/TextInput";
import { useEditStoryMutation } from "api/sessions";
import { Story } from "common/types";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  summary: yup.string().optional(),
  link: yup.string().optional(),
});

// see if we can default these from previous used?  or if we still need useEffect

const defaultValues = {
  name: "",
  summary: "",
  link: "",
};

interface EditStoryFormProps {
  story: Story;
  onClose: () => void;
}

const EditStoryForm = ({ story, onClose }: EditStoryFormProps) => {
  const storyId = story.storyId;
  const [editStory] = useEditStoryMutation();
  const [formError, setFormError] = useState("");

  const { control, formState, reset, handleSubmit } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      name: story?.name || "",
      summary: story?.summary || "",
      link: story?.link || "",
    });
  }, [story]);

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      await editStory({ storyId, data: model }).unwrap();
      onClose();
    } catch {
      setFormError("Error creating new story");
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Typography variant="h6" gutterBottom align="center">
        Edit Story
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {formError && (
            <Grid item xs={12}>
              <Alert severity="error">{formError}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              control={control}
              formState={formState}
              label="Story Name"
              variant="outlined"
              name="name"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput control={control} formState={formState} label="Link" variant="outlined" name="link" />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={control}
              formState={formState}
              label="Summary"
              variant="outlined"
              name="summary"
              multiline={true}
              maxRows={3}
              minRows={3}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
            Save Changes
          </Button>
          <Button variant="contained" onClick={handleCancel} sx={{ mt: 3, ml: 1 }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditStoryForm;
