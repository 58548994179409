import React from "react";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface PlayingCardProps {
  label: string;
  action: () => void;
  disabled: boolean;
  selected: boolean;
}

const PlayingCard = ({ label, action, disabled, selected }: PlayingCardProps) => {
  const backgroundColor = selected ? "#ffe949" : "#fefefe";

  return (
    <Grid item md={2}>
      <Card sx={{ border: 1, borderColor: "grey.200", backgroundColor: backgroundColor, borderRadius: 5 }}>
        <CardActionArea onClick={action} disabled={disabled}>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {label}
            </Typography>
            <Typography gutterBottom align="center" variant="h3" component="div">
              {label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default PlayingCard;
