import { useEffect } from "react";

import { matchRoutes, useNavigate } from "react-router-dom";

import { hasPermission } from "common/auth/permissions";
import { routes, routeAuth, defaultUserPage } from "configs/routes";
import { clearLoginRedirect, setLoginRedirect } from "common/auth/storage";

/**
 *
 * @param pathname
 * @param userRole
 * @returns
 */
function isAccessGranted(pathname: string, userRole: string): boolean {
  const matched = matchRoutes(routes, pathname);
  if (matched == null) {
    return false;
  }
  if (matched.length == 0) {
    return false;
  }

  const path = matched[matched.length - 1].route.path;
  if (path == undefined) {
    return false;
  }
  const pathAuth = routeAuth.get(path);
  if (!pathAuth) {
    return false;
  }
  const accessGranted = matched ? hasPermission(pathAuth, userRole) : true;
  return accessGranted;
}

/**
 * Check if user has valid access to this page
 * @returns [ accessGranted ]
 */
const useCheckAuth = (pathname: string, userRole: string) => {
  //const redirectPath = getLoginRedirect() || defaultUserPage;
  const navigate = useNavigate();
  const accessGranted = isAccessGranted(pathname, userRole);

  useEffect(() => {
    if (!accessGranted) {
      // console.log("accessGranted", accessGranted);
      // console.log("userRole", userRole);
      // console.log("pathname", pathname);
      if (userRole === "public") {
        // save URL for login redirect
        setLoginRedirect(pathname);
        navigate("/login");
      } else {
        clearLoginRedirect();
        navigate(defaultUserPage);
      }
    }
  }, []);

  return accessGranted;
};

export default useCheckAuth;
