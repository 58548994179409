import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";


import { clearLoginRedirect, getLastEmail, setLastEmail } from "common/auth/storage";

import SubmitButton from "components/inputs/SubmitButton";
import EmailInput from "components/inputs/EmailInput";
import { useAppSelector } from "store";
import { useLoginLinkMutation } from "api/common";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email("You must enter a valid email").required("You must enter a email")
});

type EmailFormValues = {
  email: string;
};

const defaultValues: EmailFormValues = {
  email: getLastEmail() || "",
};


const LoginLinkForm = () => {

  const { control, formState, handleSubmit, setError } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: defaultValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const [showLogout, setShowLogout] = useState("");

  useEffect(() => {
    const didLogout = localStorage.getItem("logoutAction");
    if (didLogout === "show" || didLogout === "auto") {
      if (didLogout === "auto") {
        setShowLogout("You have logged out do to inactivity");
      } else {
        setShowLogout("You have successfully logged out!");
      }

      setTimeout(() => {
        setShowLogout("");
      }, 6000);

      localStorage.setItem("logoutAction", "hide");
    }
  }, [setShowLogout]);

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const [loginLink, { isLoading, error: apiError }] = useLoginLinkMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    if (model.remember) {
      setLastEmail(model.email);
    } else {
      // clear email if not set
      setLastEmail("");
    }

    try {
      await loginLink(model).unwrap();
    } catch (e) {
      setError("email", { type: "string", message: "Error logging in" });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      clearLoginRedirect();
    }
  }, [isLoggedIn, History]);

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      {apiError && <p>Forgot Password API Error</p>}
      {showLogout != "" && <p>{showLogout}</p>}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmailInput control={control} label="E-Mail" formState={formState} />
        </Grid>

        <Grid item xs={12}>
          <SubmitButton title="Submit" formState={formState} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginLinkForm;
