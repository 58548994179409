import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
//const { createLogger } = require(`redux-logger`);

import rootReducer from "./rootReducer";
import { api } from "../api/common";

if (process.env.NODE_ENV === "development" && module.hot != undefined) {
  module.hot.accept("./rootReducer", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares = [api.middleware];

// if (process.env.NODE_ENV === "development") {
//   //const logger = createLogger({ collapsed: (_getState: Function, _action: string, logEntry: object) => !logEntry.error });
//   const logger: Middleware<{}, any, Dispatch<AnyAction>>  = createLogger({ level: "info", collapsed: true, duration: true, diff: true });
//   middlewares.push(logger);
// }

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

// @todo is this code needed????

// store.asyncReducers = {};
// export const injectReducer = (key, reducer) => {
//   if (store.asyncReducers[key]) {
//     return false;
//   }
//   store.asyncReducers[key] = reducer;
//   store.replaceReducer(createReducer(store.asyncReducers));
//   return store;
// };
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
