import React, { ReactNode } from "react";
import { SnackbarProvider } from "notistack";
// import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import Header from "./Header";
//#
const theme = createTheme({
  palette: {
    background: {
      default: "#e7e8e1",
    },
    //mode: "dark",
    // type: "dark",
    // primary: {
    //   main: "#ffd600",
    // },
    // secondary: {
    //   main: "#f50057",
    // },

    primary: {
      light: "#ffffa8",
      main: "#FFD700",
      dark: "#cabf45",
      contrastText: "#000000",
    },
    secondary: {
      light: "#fbfffc",
      main: "#c8e6c9",
      dark: "#97b498",
      contrastText: "#000000",
    },
  },
});

interface UserLayoutProps {
  children: ReactNode;
}

const UserLayout = ({ children }: UserLayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{
          containerRoot: "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Header />
          <Container component="main" maxWidth="lg">
            <Toolbar />

            <Box sx={{ height: "100vh", pt: 5 }}>{children}</Box>
          </Container>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default UserLayout;
