import React from 'react';
import { useSnackbar } from "notistack";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import OrgRowDisplay from "./InviteRowDisplay"
import { useInviteAcceptMutation, useInviteDeclineMutation } from 'api/common';
import { TeamInvite } from 'common/types';


interface InvitesDisplay {
  invites: TeamInvite[];
}

const InvitesDisplay = ({ invites }: InvitesDisplay) => {
  const { enqueueSnackbar } = useSnackbar();
  const [acceptInvite] = useInviteAcceptMutation();
  const [declineInvite] = useInviteDeclineMutation();

  // should be callback ...?
  const handleAccept = async (code: string) => {
    try {
      await acceptInvite({ inviteCode: code });
      enqueueSnackbar("Accepted Invite Successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error accepting invite", {
        variant: "error",
      });
    }
  }

  const handleDecline = async (code: string) => {
    try {
      await declineInvite({ inviteCode: code });
      enqueueSnackbar("Declined Invite Successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error declining Invite", {
        variant: "error",
      });
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Organization</TableCell>
              <TableCell align="right">Teams</TableCell>
              <TableCell align="right">Invited By</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invites.map((row) => (
              <OrgRowDisplay key={`org-display-row-${row.orgId}`} row={row} onAccept={handleAccept} onDecline={handleDecline} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default InvitesDisplay;