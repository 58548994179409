import React from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import NewCodeForm from "components/completeRegistration/NewCodeForm";
import SubmitCodeForm from "components/completeRegistration/SubmitCodeForm";

const CompleteRegistration = () => {
  const routeParams = useParams();
  const { registrationCode } = routeParams;

  return (
    <Container maxWidth="sm" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          Complete Registration
        </Typography>

        <Fragment>{!!registrationCode ? <SubmitCodeForm /> : <NewCodeForm />}</Fragment>
      </Paper>
    </Container>
  );
};

export default CompleteRegistration;
