import * as React from 'react';
import { useSnackbar } from "notistack";

import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { useOrgMembersRemoveMutation, useTeamMembersRemoveMutation } from 'api/common';
import { formatDate } from 'common/helper';
import { Member } from "common/types"
import { useAppSelector } from "store";

// add list of users?
/*
  name
  joined ?
  
*/
interface MemberTableProps {
  members: Member[];
  memberType: string;
  orgId: number;
  teamId?: number;
}

const MemberTable = ({ members, memberType, orgId, teamId }: MemberTableProps) => {
  const myUserId = useAppSelector(({ auth }) => auth.userId);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [removeTeamMember] = useTeamMembersRemoveMutation();
  const [removeOrgMember] = useOrgMembersRemoveMutation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRemove = async (userId: number) => {
    try {
      if (memberType == "org") {
        await removeOrgMember({ orgId, userId });
      }
      else if (memberType == "team") {
        await removeTeamMember({ orgId, teamId, userId });
      }
      else {
        throw new Error('invalid member type');
      }
      enqueueSnackbar("Removed user successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error removing user", {
        variant: "error",
      });
    }
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="member list">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                Name
              </TableCell>
              <TableCell align="center">
                Joined
              </TableCell>
              <TableCell align="center">
                Role
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={`member-list-user-${row.userId}`}>
                    <TableCell align="left">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(row.createdAt)}
                    </TableCell>
                    <TableCell align="center">
                      User
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Remove User" onClick={() => { handleRemove(row.userId) }}>
                        <span>
                          <IconButton aria-label="Remove User" disabled={myUserId == row.userId}>
                            <LogoutIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={members.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default MemberTable;