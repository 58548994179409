import React from "react";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

interface ActionIconProps {
  show: boolean;
}

function ActionIcons({ show }: ActionIconProps) {
  if (!show) {
    return <></>;
  }

  return (
    <IconButton aria-label="comment">
      <EditIcon />
    </IconButton>
  );
}
export default ActionIcons;
