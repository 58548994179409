import React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import MenuDrawer from "./MenuDrawer";

interface MobileMenuProps {
  mobileOpen: boolean;
  onDrawerToggle: () => void;
}

const MobileMenu = (props: MobileMenuProps) => {
  const container = window !== undefined ? () => (window as Window).document.body : undefined;

  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.onDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 180,
          },
        }}
      >
        <MenuDrawer />
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
