export const availableCards = [
  {
    label: "?",
    value: "-2",
  },
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "13",
    value: "13",
  },
  {
    label: "21",
    value: "21",
  },
  {
    label: "34",
    value: "34",
  },
  {
    label: "55",
    value: "55",
  },
  {
    label: "89",
    value: "89",
  },
  {
    label: "∞",
    value: "100",
  },
];

export const availablePoints = [-2, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 100];
export const pointsStrings = ["?", "1", "2", "3", "5", "8", "13", "21", "34", "55", "89", "∞"];

export const availablePointIndex = {
  "-2": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "5": 4,
  "8": 5,
  "13": 6,
  "21": 7,
  "34": 8,
  "55": 9,
  "89": 10,
  "100": 11,
};
