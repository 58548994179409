import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useSetPageTitle from "hooks/use-set-page-title";
import MiniSessionList from "components/session/list/MiniSessionList";
import QuickSstats from "components/user/stats/QuickStats";
import PendingStoriesList from "components/session/stories/PendingStoriesList";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
  minHeight: 382,
}));

const Dashboard = () => {
  useSetPageTitle("Dashboard");

  return (
    <Box sx={{ flexGrow: 1, pt: 5 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Item elevation={3}>
            <Typography variant="h6">Pointing Stats</Typography>
            <QuickSstats />
            <Button variant="contained" sx={{ my: 2 }} component={RouterLink} to="/user/stats">
              View All Stats
            </Button>
          </Item>
        </Grid>

        <Grid item xs={12} md={8}>
          <Item elevation={3}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Pointing Sessions
            </Typography>
            <MiniSessionList status="pending" />
            <Button variant="contained" sx={{ my: 2 }} component={RouterLink} to="/sessions">
              View All Sessions
            </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item elevation={3}>
            <Typography variant="h6">Pending Stories</Typography>
            <PendingStoriesList limit={10} showSort={false} showPaging={false} />
            <Button variant="contained" sx={{ my: 2 }} component={RouterLink} to="/stories/pending">
              View All Pending Stories
            </Button>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
