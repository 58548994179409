import { calculateRemainingTime } from "../helper";

const accessTokenKey = "a:at";
const expireAtKey = "a:ea";
const refreshTokenKey = "a:rt";
const refreshExpireAtKey = "a:rea";
const userIdKey = "a:ui";
const userRoleKey = "a:ur";
const userEmailKey = "a:ue";
const rememberEmailKey = "a:re";
const loginRedirectKey = "a:lr";

/**
 * Get last used E-Mail
 * @returns {string}
 */
export const getLastEmail = () => {
  return localStorage.getItem(rememberEmailKey);
};

/**
 * Save email to autofill at next login
 * @param {string} email
 */
export const setLastEmail = (email: string) => {
  localStorage.setItem(rememberEmailKey, email);
};

/**
 * Save attempted URL for after login
 * @returns {string}
 */
export const getLoginRedirect = () => {
  return localStorage.getItem(loginRedirectKey);
};

/**
 * Save login redirect URL (if available)
 * @param {string}   localStorage.setItem(loginRedirectKey, path);

 */
export const setLoginRedirect = (path: string) => {
  if(path === null) {
    clearLoginRedirect();
  }
  else if(path.startsWith("/404") || path.startsWith("/login") || path.startsWith("/logout")) {
    clearLoginRedirect();
  }
  else {
    localStorage.setItem(loginRedirectKey, path);
  }
};

/**
 * Clear login redirect URL (if available)
 */
export const clearLoginRedirect = () => {
  localStorage.removeItem(loginRedirectKey);
};

/**
 * Save token data to storage
 * @param {string} accessToken
 * @param {string} refreshToken
 * @param {number} expireAt
 * @param {number} refreshExpireAt
 * @param {string} role
 * @param {string} email
 */
export const saveTokens = (
  accessToken: string,
  refreshToken: string,
  expireAt: number,
  refreshExpireAt: number,
  userId: number,
  role: string,
  email: string
) => {
  localStorage.setItem(accessTokenKey, accessToken);
  localStorage.setItem(expireAtKey, expireAt.toString());
  localStorage.setItem(refreshTokenKey, refreshToken);
  localStorage.setItem(refreshExpireAtKey, refreshExpireAt.toString());
  localStorage.setItem(userIdKey, userId.toString());
  localStorage.setItem(userRoleKey, role);
  localStorage.setItem(userEmailKey, email);
};

/**
 * Load the stored auth token data. Set checkExpiration to true (default) to valid
 * if expireAt is still valid.  If not then we remove currently stored data
 * @param {boolean} checkExpiration
 * @returns {object}
 */
export const loadTokens = (checkExpiration = true) => {
  // @todo ensure types are converted ?? set return type?
  const accessToken = localStorage.getItem(accessTokenKey);
  const refreshToken = localStorage.getItem(refreshTokenKey);
  const expireAt = parseInt(localStorage.getItem(expireAtKey) || "0");
  const refreshExpireAt = parseInt(localStorage.getItem(refreshExpireAtKey) || "0");
  const userId = localStorage.getItem(userIdKey) || 0;
  const role = localStorage.getItem(userRoleKey) || [];
  const email = localStorage.getItem(userEmailKey);

  if (accessToken && refreshToken && expireAt) {
    if (checkExpiration) {
      const remainingTime = calculateRemainingTime(refreshExpireAt);
      // don't use if 1 minute or less
      if (remainingTime <= 60000) {
        // clean up bad data
        deleteTokens();
        return null;
      }
    }

    return {
      refreshToken,
      accessToken,
      expireAt,
      refreshExpireAt,
      userId,
      role,
      email,
    };
  }

  return null;
};

/**
 * Remove stored auth token data
 */
export const deleteTokens = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem(expireAtKey);
  localStorage.removeItem(refreshExpireAtKey);
  localStorage.removeItem(userIdKey);
  localStorage.removeItem(userRoleKey);
  localStorage.removeItem(userEmailKey);
};

/**
 * Ge the current access token
 * @returns {string}
 */
export const getAccessToken = () => {
  return localStorage.getItem(accessTokenKey);
};

/**
 * Ge the current refresh token
 * @returns {string}
 */
export const getRefreshToken = () => {
  return localStorage.getItem(refreshTokenKey);
};

/**
 * Ge the current access token
 * @param  accessToken {string}
 */
export const updateAccessToken = (accessToken: string) => {
  localStorage.setItem(accessTokenKey, accessToken);
};

/**
 * Ge the current refresh token
 * @param  refreshToken {string}
 */
export const updateRefreshToken = (refreshToken: string) => {
  localStorage.setItem(refreshTokenKey, refreshToken);
};

/**
 * Ge the current refresh token
 * @returns {number}
 */
export const getUserId = () => {
  const userId = localStorage.getItem(userIdKey);
  if (userId == null) {
    return 0;
  }
  return parseInt(userId);
};
