import React from "react";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import Paper from "@mui/material/Paper";

import StoryList from "components/session/active/StoryList";
import StoryControls from "./StoryControls";
import { useAppSelector } from "store";

const StoryListPanel = () => {
  const sessionData = useAppSelector(({ session }) => session.data);
  const userInControl = useAppSelector(({ session }) => session.userInControl && session.data.status == "started");

  // console.log("sessionData", sessionData);

  if (!sessionData || sessionData.sessionId == 0) {
    // console.log("No session data");
    return <Paper sx={{ p: 1 }} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Paper sx={{ p: 1 }}>
        <StoryList sessionId={sessionData.sessionId} actionIcons={userInControl} />

        <StoryControls sessionId={sessionData.sessionId} />
      </Paper>
    </DndProvider>
  );
};

export default StoryListPanel;
