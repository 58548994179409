import React from "react";
import { Control, Controller, FieldValues, FormState } from "react-hook-form";

import TextField, { TextFieldVariants } from "@mui/material/TextField";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

interface TextInputProps {
  name: string;
  label?: string;
  variant?: TextFieldVariants;
  required?: boolean;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  sx?: SxProps<Theme>;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
}

const TextInput = (props: TextInputProps) => {
  const name: string = props.name;
  const errorMessage = props.formState?.errors?.[name]?.message?.toString() || "";

  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field }) => (
        <TextField
          {...field}
          className="mb-16"
          label={props.label}
          autoFocus
          type="text"
          error={errorMessage != ""}
          helperText={errorMessage}
          variant={props.variant}
          sx={props.sx}
          required={props.required}
          multiline={props.multiline}
          minRows={props.minRows}
          maxRows={props.maxRows}
          fullWidth
        />
      )}
    />
  );
};

export default TextInput;
