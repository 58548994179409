import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import LoginForm from "components/auth/LoginForm";
import RegisterForm from "components/auth/RegisterForm";
import useSetPageTitle from "hooks/use-set-page-title";
import FeatureList from "components/external/FeatureList";
import Screenshots from "components/external/Screenshots";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: 300,
}));

const HomePage = () => {
  useSetPageTitle("");

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Item elevation={0}>
            <FeatureList />
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item elevation={0}>
            <Screenshots />
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item elevation={3}>
            Register
            <RegisterForm />
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item elevation={3}>
            Login
            <LoginForm />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;
