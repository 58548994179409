import _ from "lodash";

import authRoles from "./roles";

/**
 * Check if user has permission for requested page
 * @param {string} requiredRole
 * @param {string} userRole
 * @returns
 */
export const hasPermission = (requiredRole: string, userRole: string) => {
  const checkRoles = authRoles[userRole] ? authRoles[userRole] : null;

  // if no auth requirment, pass
  if (_.isEmpty(requiredRole)) {
    return true;
  }

  if (!checkRoles) {
    console.log("Invalid userRole for hasPermission()");
    return false;
  }

  return checkRoles.includes(requiredRole);
};
