import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useSetPageTitle from "hooks/use-set-page-title";
import SessionList from "components/session/list/SessionList";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
  minHeight: 150,
}));

const SessionListPage = () => {
  useSetPageTitle("Pointing Sessions");

  return (
    <Box sx={{ flexGrow: 1, pt: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item elevation={3}>
            <Typography variant="h6">Pointing Sessions</Typography>
            <SessionList />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SessionListPage;
