import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// import { useAppSelector } from "store";
import { SessionData } from "common/types";

interface HeaderPanelProps {
  session: SessionData;
}

const HeaderPanel = ({ session }: HeaderPanelProps) => {
  // const sessionData = useAppSelector(({ session }) => session.data);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Box>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {session?.orgName}
          </Typography>
          <Typography component="h2" sx={{ fontSize: 26 }} variant="h4">
            {session?.teamName}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={8}>
        <Typography component="h1" sx={{ fontSize: 34 }} variant="h2">
          {session?.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeaderPanel;
