import React from "react";
import clsx from "clsx";

function getStatusValues(name: string) {
  if (name == "pending") {
    return {
      label: "Pending",
      color: "bg-blue text-white",
    };
  }
  if (name == "paused") {
    return {
      label: "Paused",
      color: "bg-purple text-white",
    };
  }
  if (name == "started") {
    return {
      label: "Started",
      color: "bg-green text-white",
    };
  }
  if (name == "finished") {
    return {
      label: "Finished",
      color: "bg-orange text-black",
    };
  }

  return { color: "", label: "" };
}

interface SessionStatusProps {
  name: string;
}

const SessionStatus = ({ name }: SessionStatusProps) => {
  const { color, label } = getStatusValues(name);

  return <div className={clsx("inline text-12 font-semibold py-4 px-12 rounded-full truncate", color)}>{label}</div>;
};

export default SessionStatus;
