import React from "react";
import { Controller, Control, FieldValues, FormState  } from "react-hook-form";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import RadioGroup from "@mui/material/RadioGroup";

import AvatarOption from "./AvatarOption";
import { currentAvatarList } from "common/helper";


export interface ConfirmActionProps {
  currentAvatarId: number;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
}

// Show confirmation screen to run an action
const AvatarSelection = (props: ConfirmActionProps) => {
  const { control } = props;

  return (
    <Controller
      name="avatarId"
      control={control}
      render={({ field }) => {
        return (
          <RadioGroup {...field}>
            <ImageList sx={{ width: 350, height: 220, overflowY: "scroll" }} cols={3} rowHeight={50} >
              {currentAvatarList.map((entry) => (
                <ImageListItem key={`avatar-selection-${entry.id}`}>
                  <AvatarOption value={entry.id} src={entry.path} />
                </ImageListItem>
              ))}
            </ImageList>
          </RadioGroup>
        );
      }}
    />
  )
};

export default AvatarSelection;
