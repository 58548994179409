import React from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MemberListOutput from "./MemberListOutput";
import { useAppDispatch, useAppSelector } from "store";
import { hideMemberListDialog } from "store/dialogs";

// Show confirmation screen to run an action
const MemberListDialog = () => {
  const dispatch = useAppDispatch();
  const { title, show, orgId, teamId } = useAppSelector(({ dialogs }) => dialogs.memberListDialog);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideMemberListDialog());
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={show}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>{title} </strong>
          </Typography>
        </Toolbar>
      </AppBar>
        
      <DialogContent classes={{ root: "p-24" }}>
        <MemberListOutput orgId={orgId} teamId={teamId} show={show} />
      </DialogContent>
      <DialogActions className="justify-between p-4 pb-16">
      <div className="px-16">
        <Button variant="contained" color="primary" onClick={closeComposeDialog}>
          Close
        </Button>
      </div>
    </DialogActions>
    </Dialog>
  );
};

export default MemberListDialog;
