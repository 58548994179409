import React from "react";

import Box from "@mui/material/Box";

import StatsTable from "../StatsTable";

import { TableStructInterface } from "../StatsTable/types";
import { SessionData, StatsDataParticipantInterface } from "common/types";

const tableStruct: TableStructInterface[] = [
  { id: "name", label: "Name", sort: true, align: "left" },
  { id: "stories", label: "Stories", sort: true, align: "right" },
  { id: "mean", label: "Mean", sort: true, align: "right" },
  { id: "median", label: "Median", sort: true, align: "right" },
  { id: "min", label: "Min", sort: true, align: "right" },
  { id: "max", label: "Max", sort: true, align: "right" },
  { id: "mode", label: "Mode", sort: true, align: "right" },
];

interface StatsParticipantsProps {
  data: StatsDataParticipantInterface[];
  session: SessionData;
}

const StatsParticipants = ({ session, data }: StatsParticipantsProps) => {
  console.log("session: participant", session, data);

  return (
    <Box sx={{ width: "100%" }}>
      {/* <Paper sx={{ width: "100%", mb: 2, mt: 3 }}>
        <PointScatterChart points={data.points} labelPoints="Average Points" />
      </Paper> */}

      <StatsTable name="Participant"  data={data} tableStruct={tableStruct} />
    </Box>
  );
};

export default StatsParticipants;
