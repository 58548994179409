import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TextInput from "components/inputs/TextInput";
import { useCreateSessionStoryMutation } from "api/sessions";
import { Story } from "common/types";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  summary: yup.string().optional(),
  link: yup.string().optional(),
});

// see if we can default these from previous used?  or if we still need useEffect

interface AddStoryFormProps {
  sessionId: number;
  onAdd?: (story: Story) => void;
}

const AddStoryForm = ({ sessionId, onAdd }: AddStoryFormProps) => {
  const [formError, setFormError] = useState("");
  const [createStory] = useCreateSessionStoryMutation();

  const defaultValues = {
    name: "",
    summary: "",
    link: "",
  };

  const { control, formState, reset, handleSubmit } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      const newStory = await createStory({ sessionId, data: model }).unwrap();
      if (onAdd) {
        onAdd(newStory);
      }
      setFormError("");
      reset();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.data?.error) {
        setFormError(e?.data?.error);
      } else {
        setFormError("Error creating new story");
      }
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom align="center">
        Add Story
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {formError && (
            <Grid item xs={12}>
              <Alert severity="error">{formError}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              control={control}
              formState={formState}
              label="Story Name"
              variant="outlined"
              name="name"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput control={control} formState={formState} label="Link" variant="outlined" name="link" />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={control}
              formState={formState}
              label="Summary"
              variant="outlined"
              name="summary"
              multiline={true}
              maxRows={3}
              minRows={3}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
            Add Story
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddStoryForm;
