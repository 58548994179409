import React from "react";

import Typography from "@mui/material/Typography";

interface StoryNameProps {
  name: string;
  link: string;
}

const StoryName = ({ name, link }: StoryNameProps) => {
  if (link) {
    return (
      <Typography component="div" variant="h5" alignContent="center">
        <a href={link} target="_blank" rel="noreferrer">
          {name}
        </a>
      </Typography>
    );
  }
  return (
    <Typography component="div" variant="h5">
      {name}
    </Typography>
  );
};

export default StoryName;
