import React, { useCallback, useEffect, useState } from "react";
import { useDrop } from 'react-dnd'

import MuiList from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

import StoryListItem from "components/session/setup/StoryList/StoryListItem";
import { useChangeSessionStoryOrderMutation } from "api/sessions";
import { Story } from "common/types";

const List = styled(MuiList)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "gold",
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "gold",
  },
});

const reorder = (list: Story[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface StoryListProps {
  data: Story[];
  sessionId: number;
  setSelectedStory: React.Dispatch<React.SetStateAction<number>>;
  selectedStory: number;
  actionIcons: boolean;
}

const StoryList = ({ data, sessionId, setSelectedStory, selectedStory, actionIcons }: StoryListProps) => {
  const [stories, setStories] = useState(data);

  const [changeOrder] = useChangeSessionStoryOrderMutation();

  // todo check if this is besy way!!!
  useEffect(() => {
    setStories(data);
  }, [data]);

  // find story index
  const findStory = useCallback((storyId: number)  => {
    return stories.findIndex( x => x.storyId === storyId );
  }, [stories]);

  // move story in the index
  const moveStory = useCallback((storyId: number, endIndex: number) : number => {
    const startIndex = stories.findIndex( x => x.storyId === storyId );

    if (startIndex < 0 || endIndex === startIndex) {
      return -1;
    }

    const newOrder = reorder(stories, startIndex, endIndex);
    setStories(newOrder);
    return startIndex
  }, [stories]);
  

  // save move story
  const saveMoveStory = useCallback((storyId: number, startIndex: number, endIndex: number) : void => {
    if ((startIndex < 0) || (startIndex == endIndex)) {
      return;
    }
    if (!(endIndex in data)) {
      return;
    }
    
    const targetStoryId = data[endIndex].storyId;
    changeOrder({ sessionId, data: { storyId, targetStoryId} });
  }, [stories]);

  const [, drop] = useDrop(() => ({ accept: "StoryListItem" }))
  
  const handleStorySelect = (index: number) => {
    if (index == null || index == selectedStory) {
      setSelectedStory(-1);
    } else {
      setSelectedStory(index);
    }
  };

  if (stories.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center h-full">
        <Typography color="text.secondary" variant="h5"></Typography>
      </div>
    );
  }

  return (
    <List dense sx={{ width: "100%", maxHeight: 320, overflow: "auto" }} disablePadding>
      <>
        <div ref={drop}>
          {stories.map((item, index) => {
            const isSelected = selectedStory == index;
            return (
              <StoryListItem
                story={item}
                index={index}
                key={item.storyId}
                onClick={handleStorySelect}
                selected={isSelected}
                actionIcons={actionIcons}
                moveStory={moveStory}
                saveMoveStory={saveMoveStory}
                findStory={findStory}
              />
            );
          })}
        </div>
      </>
    </List>
  );
};

export default StoryList;
