/**
 * Dialog Slices
 *
 */
import { createSlice } from "@reduxjs/toolkit";
import { sessionApi } from "api/sessions";
import { getUserId } from "../common/auth/storage";
import { SessionUser, Story } from "common/types";
import { makeDefaultSessionData, makeDefaultStory } from "common/helper";

import { SessionStateType } from "./types";

function getMyMemberData(members: SessionUser[]): SessionUser | null {
  const userId = getUserId();
  for (let i = 0; i < members.length; i++) {
    if (members[i].userId == userId) {
      return members[i];
    }
  }

  return null;
}

// data for dialogs
const initialState: SessionStateType = {
  activeStory: makeDefaultStory(),
  userInControl: false, // let us know if this user is in control of the pointing
  data: makeDefaultSessionData(),
  stories: [],
  participants: [],
  activePointing: false,
  userPointed: false,
  isObserver: false,
  activeStoryPoints: 0,
};

const slice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    /**
     * Set all the data for pusher Alldata update
     */
    setAllData(state, { payload }) {
      state.data = payload.session || {};
      state.stories = payload.stories || [];
      state.activeStory = payload.activeStory || null;
      state.activePointing = payload?.activeStory?.status == "started";
      state.participants = payload.members || [];
      const userData = getMyMemberData(payload.members || [])
      if (userData != null) {
        state.activeStoryPoints = userData.points;
        state.isObserver = userData.status == "observer";
        state.userInControl = payload.session.controlUserId == getUserId();
      }

      
      //state.isObserver = 0;
      //state.userInControl = 0;
      //state.userId = 0;
    },

    /**
     * Set session Data
     * @param {number} accountId Optional Id for account to add user to
     */
    setSessionData(state, { payload }) {
      state.data = payload;
    },
    /**
     * Set session Data
     * @param {number} accountId Optional Id for account to add user to
     */
    setSessionStories(state, { payload }) {
      state.stories = payload;
      if (state.activeStory) {
        state.activeStory = payload.find((story: Story) => story.storyId == state.activeStory.storyId);
      }
    },

    /**
     * Clear current session and related data
     */
    clearSession(state) {
      state.data = makeDefaultSessionData();
      state.stories = [];
      state.activeStory = makeDefaultStory();
      state.userInControl = false;
      state.participants = [];
      state.activePointing = false;
      state.userPointed = false;
      state.isObserver = false;
      state.activeStoryPoints = 0;
    },

    /**
     * Set the current active story
     * @param {number} teamId Optional Id for account to add user to
     */
    setActiveStory(state, { payload }) {
      state.activeStory = payload;
      state.activePointing = payload?.status == "started";
    },
    /**
     * Clear the current active story
     * @param {number} teamId Optional Id for account to add user to
     */
    clearActiveStory(state, { payload }) {
      state.activeStory = payload;
    },

    /**
     * Clear the current active story
     * @param {number} teamId Optional Id for account to add user to
     */
    clearActiveParticipants(state) {
      state.participants = [];
    },

    /**
     * set Participants for active story
     * @param {number} teamId Optional Id for account to add user to
     */
    setParticipants(state, { payload }) {
      state.participants = payload;
      const userData = getMyMemberData(payload || [])
      if (userData != null) {
        state.activeStoryPoints = userData.points;
        state.isObserver = userData.status == "observer";
      }
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(sessionApi.endpoints.allSessionData.matchFulfilled, (state, { payload }) => {
      state.data = payload?.session || {};
      state.stories = payload?.stories || [];
      state.participants = payload?.members || [];
      // set our active story
      state.activeStory = payload?.activeStory || null;
      state.activePointing = payload?.activeStory?.status == "started";
      state.userPointed = false; // @todo change this once we know if we have pointed
      state.isObserver = false;
      // are we in chage ?
      state.userInControl = payload?.isPilot || false;
      const userData = getMyMemberData(payload.members)
      if (userData != null) {
        state.activeStoryPoints = userData.points;
        state.isObserver = userData.status == "observer";
      }
    });
    
    builder.addMatcher(sessionApi.endpoints.getSessionPreview.matchFulfilled, (state, { payload }) => {
      state.data = payload?.session || {};
      state.stories = payload?.stories || [];
      state.participants = payload?.members || [];
      // set our active story
      state.activeStory = payload?.activeStory || null;
      state.activePointing = payload?.activeStory?.status == "started";
      state.userPointed = false; // @todo change this once we know if we have pointed
      state.isObserver = false;
      // are we in chage ?
      state.userInControl = payload?.isPilot || false;
      const userData = getMyMemberData(payload.members)
      if (userData != null) {
        state.activeStoryPoints = userData.points;
        state.isObserver = userData.status == "observer";
      }
    });
  },
});

// see if we should export the actions another way
export const actions = slice.actions;
export const setSessionData = slice.actions.setSessionData;
export const setSessionStories = slice.actions.setSessionStories;
export const clearSession = slice.actions.clearSession;
export const setActiveStory = slice.actions.setActiveStory;
export const clearActiveStory = slice.actions.clearActiveStory;
export const setParticipants = slice.actions.setParticipants;
export default slice.reducer;

// maybe combine events so we have single store updates??
// -session wtih story list
// - session with active story and maybe story list as well
// - session wtih participants
