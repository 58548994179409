import React from "react";
import _ from "lodash";
import { FormState, FieldValues } from "react-hook-form";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

interface DefaultDialogActionsProps {
  onCancel: () => void;
  saveText: string;
  formState: FormState<FieldValues>;
}

const DefaultDialogActions = ({ saveText, formState, onCancel }: DefaultDialogActionsProps) => {
  const { isValid, dirtyFields } = formState;

  return (
    <DialogActions className="justify-between p-4 pb-16">
      <div className="px-16">
        <Button variant="contained" color="secondary" type="submit" disabled={_.isEmpty(dirtyFields) || !isValid}>
          {saveText || "Submit"}
        </Button>
      </div>
      <div className="px-16">
        <Button variant="contained" color="primary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </DialogActions>
  );
};

export default DefaultDialogActions;
