import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import { Story } from "common/types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface StoryStats {
  story: Story;
}

function getModePoints(mode: number | undefined): number | string {
  if (mode === undefined || mode === null || mode <= 0) {
    return "N/A";
  }
  if (mode === 0) {
    return "Multiple";
  }
  return mode;
}

interface StatsOutputInterface {
  participants: string | number;
  meanPoints: string | number;
  medianPoints: string | number;
  modePoints: string | number;
  minPoints: string | number;
  maxPoints: string | number;
}

function getStatsOutput(story: Story) {
  const data: StatsOutputInterface = {
    participants: "N/A",
    meanPoints: "N/A",
    medianPoints: "N/A",
    modePoints: "N/A",
    minPoints: "N/A",
    maxPoints: "N/A",
  };

  if (story.totalParticipants && story.totalParticipants > 0) {
    data.participants = story.totalParticipants;
  }

  if (story.meanPoints && story.meanPoints > 0) {
    data.meanPoints = story.meanPoints;
  }

  if (story.medianPoints && story.medianPoints > 0) {
    data.medianPoints = story.medianPoints;
  }

  if (story.minPoints && story.minPoints > 0) {
    data.minPoints = story.minPoints;
  }

  if (story.maxPoints && story.maxPoints > 0) {
    if (story.maxPoints == 100) {
      data.maxPoints = "∞";
    } else {
      data.maxPoints = story.maxPoints;
    }
  }

  data.modePoints = getModePoints(story.modePoints);

  return data;
}

const StoryStats = ({ story }: StoryStats) => {
  // const setPoints = story?.points >= 0 ? story.points : "N/A";
  const stats = getStatsOutput(story);

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" align="center">
              Median
            </StyledTableCell>
            <StyledTableCell align="center">Mean</StyledTableCell>
            <StyledTableCell component="th" scope="row" align="center">
              Mode
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">{stats.medianPoints}</StyledTableCell>
            <StyledTableCell align="center">{stats.meanPoints}</StyledTableCell>
            <StyledTableCell align="center">{stats.modePoints}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>

      <Table size="small">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell align="center">Minimum</StyledTableCell>
            <StyledTableCell align="center">Maximum</StyledTableCell>
            <StyledTableCell align="center">Participants</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">{stats.minPoints}</StyledTableCell>
            <StyledTableCell align="center">{stats.maxPoints}</StyledTableCell>
            <StyledTableCell align="center">{stats.participants}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StoryStats;
