import React from "react";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import BaseStoryList from "./BaseStoryList";
import StoryDialog from "components/dialogs/StoryDialog";
import StoryName from "./StoryName";
import { Story, StoryTableColumnEntry } from "common/types";

interface CompletedStoriesListProps {
  limit?: number;
  showSort?: boolean;
  showPaging?: boolean;
}

// maybe hide summary and createdby?  have hourglass to view and show all data in popup ???

const CompletedStoriesList = ({ limit = 20, showPaging = true, showSort = true }: CompletedStoriesListProps) => {
  const columns: StoryTableColumnEntry[] = [
    {
      id: "name",
      align: "center",
      disablePadding: false,
      label: "Name",
      sort: true,
      custom: (data: Story) => {
        return (
          <Tooltip title={data.name}>
            <Typography>
              <StoryName name={data.name} link={data.link} />
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "summary",
      align: "center",
      disablePadding: false,
      label: "Summary",
      sort: false,
      custom: (data) => {
        return (
          <Typography
            sx={{
              maxWidth: 150,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.summary}
          </Typography>
        );
      },
    },
    {
      id: "teamName",
      align: "left",
      disablePadding: false,
      label: "Team",
      sort: true,
      custom: (data) => {
        return (
          <>
            <Typography variant="caption" color="text.secondary">
              {data.orgName}
            </Typography>
            <Typography>{data.teamName}</Typography>
          </>
        );
      },
    },
    {
      id: "sessionName",
      align: "center",
      disablePadding: false,
      label: "Session",
      sort: true,
      custom: (data: Story) => {
        return (
          <Tooltip title={data.name}>
            <Typography>
              <StoryName name={data.name} link={`/sessions/${data.sessionId}`} />
            </Typography>
          </Tooltip>
        );
      },
    },

    { id: "points", label: "Points", sort: true, align: "right", disablePadding: false },
    { id: "totalParticipants", label: "Participants", sort: true, align: "right", disablePadding: false },
    // { id: "meanPoints", label: "Mean", sort: true, align: "right", disablePadding: false },
    {
      id: "createdByName",
      align: "center",
      disablePadding: false,
      label: "Created By",
      sort: false,
    },
    {
      id: "updatedAt",
      align: "center",
      disablePadding: false,
      label: "Last Updated",
      sort: true,
      custom: (data) => {
        const myDate = new Date(data.updatedAt as string);
        return <>{myDate.toLocaleString()}</>;
      },
    },

    // { id: "median", label: "Median", sort: true, align: "right", disablePadding: false },
    // { id: "min", label: "Min", sort: true, align: "right", disablePadding: false },
    // { id: "max", label: "Max", sort: true, align: "right", disablePadding: false },
    // { id: "mode", label: "Mode", sort: true, align: "right", disablePadding: false },

    // {
    //   id: "actions",
    //   align: "center",
    //   disablePadding: false,
    //   label: "",
    //   sort: false,
    //   custom: (data) => {
    //     return (
    //       <>
    //         <IconButton aria-label="Edit" onClick={() => { handleEdit(data.storyId)}}>
    //           <EditIcon />
    //         </IconButton>
    //         <IconButton>
    //           <TableViewIcon />
    //         </IconButton>
    //         <IconButton component={Link} to={`/pointing/${data.storyId}`}>
    //           <Delete />
    //         </IconButton>
    //       </>
    //     )
    //   }
    // },
  ];

  return (
    <>
      <BaseStoryList status="pointed" columns={columns} limit={limit} showPaging={showPaging} showSort={showSort} />
      <StoryDialog />
    </>
  );
};

export default CompletedStoriesList;
