import React from "react";

import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import AsyncAvatar from "components/media/AsyncAvatar";

export interface AvatarOptionProps {
  value: number;
  src: string;
}

const AvatarOption = ({ value, src }: AvatarOptionProps) => {
  const radioName = `radio-${value}`;
  return (
    <FormControlLabel value={value} control={<Radio id={radioName} />} label={<AsyncAvatar width={50} height={50} src={src} />} />
  );
};

export default AvatarOption;
