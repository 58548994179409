import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { TeamInvite } from 'common/types';
import { formatDate } from 'common/helper'

interface InviteRowDisplayProps {
  row: TeamInvite;
  onAccept: (code: string) => void,
  onDecline: (code: string) => void,
}

const InviteRowDisplay = ({ row, onAccept, onDecline }: InviteRowDisplayProps) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {row.orgName}
        </TableCell>
        <TableCell align="right">{row.teamName}</TableCell>
        <TableCell align="right">{row.actorName} ({row.actorEmail})</TableCell>
        <TableCell align="right">{formatDate(row.createdAt as string)}</TableCell>
        <TableCell align="right">
          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
            <Tooltip title="Accept invite to team and org">
              <IconButton aria-label="Accept Invite" color="success" onClick={() => { onAccept(row.code) }}>
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Decline invite to team and org">
              <IconButton aria-label="Decline Invite" color="error" onClick={() => { onDecline(row.code) }}>
                <CancelIcon />
              </IconButton>
            </Tooltip>

          </Stack>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default InviteRowDisplay;