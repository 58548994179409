import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Toolbar from "@mui/material/Toolbar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";

import ExternalInviteTab from "./ExternalInviteTab";
import OrgMembersTab from "./OrgMembersTab";
import { useAppDispatch, useAppSelector } from "store";
import { hideInviteMemberDialog } from "store/dialogs";


function renderTab(value: number, orgId: number, teamId: number) {
  switch (value) {
    case 1:
      return <ExternalInviteTab orgId={orgId} teamId={teamId} />
    default:
      return <OrgMembersTab orgId={orgId} teamId={teamId} />
  }
}


// Show confirmation screen to run an action
const InviteMemberDialog = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const dispatch = useAppDispatch();
  const { title, show, orgId, teamId } = useAppSelector(({ dialogs }) => dialogs.inviteMemberDialog);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideInviteMemberDialog());
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={show}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>{title} </strong>
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent classes={{ root: "p-24" }}>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={handleChange} aria-label="invite user tabs">
              <Tab label="Add From Organization" />
              <Tab label="Invite External" />
            </Tabs>
          </Box>
          {renderTab(selectedTab, orgId, teamId)}
        </Box>

      </DialogContent>
      <DialogActions className="justify-between p-4 pb-16">
        <div className="px-16">
          <Button variant="contained" color="primary" onClick={closeComposeDialog}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default InviteMemberDialog;
