import React from 'react';
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InviteMemberDialog from 'components/dialogs/InviteMemberDialog';
import MemberListDialog from 'components/dialogs/MemberListDialog';
import OrgRowDisplay from "./OrgRowDisplay"
import OrgDialog from "components/dialogs/OrgDialog";
import TeamDialog from "components/dialogs/TeamDialog";
import ShowLoading from "components/processing/ShowLoading";
import { useGetOrgListQuery, useTeamMembersJoinMutation, useTeamMembersLeaveMutation } from 'api/common';
import { Org } from 'common/types';


const OrgTeamDisplay = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [ joinTeam ] = useTeamMembersJoinMutation();
  const [ leaveTeam ] = useTeamMembersLeaveMutation();
  // const [ leaveOrg ] = useOrgMembersLeaveMutation();
  const { data, isLoading, isError } = useGetOrgListQuery({ withExtra: true });
  const orgs: Org[] = data?.data || [];

  // should be callback ...?
  const handleJoinTeam = async (orgId: number, teamId: number) => {
    try {
      await joinTeam({orgId, teamId });
      enqueueSnackbar("Team Successfully Joined", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error joining Team", {
        variant: "error",
      });
    }
  }

  const handleLeaveTeam = async (orgId: number, teamId: number) => {
    try {
      await leaveTeam({orgId, teamId });
      enqueueSnackbar("Left Team Successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error leaving Team", {
        variant: "error",
      });
    }
  }

  const handleLeaveOrg = async (orgId: number) => {
    console.log("leave org", orgId)
    // leave the org, assumes user has already had confirmation
    // try {
    //   await leaveTeam({orgId});
    //   enqueueSnackbar("Left Team Successfully", {
    //     variant: "success",
    //   });
    // } catch (err) {
    //   enqueueSnackbar("Error leaving Team", {
    //     variant: "error",
    //   });
    // }
  }

  if (isLoading) {
    return <ShowLoading />;
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error Loading Session Stories</Alert>
      </Grid>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Organization</TableCell>
              <TableCell align="right">Teams</TableCell>
              <TableCell align="right">Users</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgs.map((row) => (
              <OrgRowDisplay key={`org-display-row-${row.orgId}`} row={row} onLeaveOrg={handleLeaveOrg} onJoinTeam={handleJoinTeam} onLeaveTeam={handleLeaveTeam} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OrgDialog />
      <TeamDialog />
      <MemberListDialog />
      <InviteMemberDialog />
    </>
  );
}

export default OrgTeamDisplay;