import React from "react";
import { useSearchParams } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import useSetPageTitle from "hooks/use-set-page-title";
import RegisterForm from "components/auth/RegisterForm";
import InviteRegisterForm from "components/auth/InviteRegisterForm";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: 300,
}));


const LoginPage = () => {
  useSetPageTitle("Register");
  const [ searchParams ] = useSearchParams();

  const inviteCode = searchParams.get("invite");
  
  return (
    <Box sx={{ flexGrow: 1, pt: 10 }} >
      <Grid container spacing={4}  >
        <Grid item xs={3}> </Grid>
        <Grid item xs={6}>
          <Item elevation={3}>
            Register
            {inviteCode && <InviteRegisterForm inviteCode={inviteCode as string} />}
            {!inviteCode && <RegisterForm />}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
