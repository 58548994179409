import React, { useEffect, useState } from "react";
import { Control, FieldValues, FormState, UseFormSetValue } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import SelectInput from "components/inputs/SelectInput";
import { useGetOrgListQuery } from "api/common";
import ShowLoading from "components/processing/ShowLoading";
import { showCreateOrgForm } from "store/dialogs";
import { useAppDispatch } from "store";
import { Org, SelectOptions } from "common/types";

interface OrgSelectProps {
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
}

const OrgSelect = ({ setValue, control, formState }: OrgSelectProps) => {
  const dispatch = useAppDispatch();
  const [orgData, setOrgData] = useState<SelectOptions[]>([]);
  const { data: apiData, isLoading } = useGetOrgListQuery({ offset: 0, limit: 1000 });

  const hasOrgs: boolean = orgData.length > 0;

  useEffect(() => {
    const data: Org[] = apiData?.data || [];
    let newOrgArray: SelectOptions[] = [];
    if (data?.[0]) {
      newOrgArray = data.map((v) => {
        return { label: v.name, id: v.orgId };
      });
      setValue("orgId", data[0].orgId);
    }
    setOrgData(newOrgArray);
  }, [apiData]);

  function handleAddOrg() {
    dispatch(showCreateOrgForm());
  }

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={8}>
        <SelectInput
          control={control}
          formState={formState}
          label="Organization"
          variant="outlined"
          name="orgId"
          options={orgData}
          disabled={!hasOrgs}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={3} alignItems="stretch" style={{ display: "flex" }} sx={{ mt: 1, mb: 2 }}>
        <Button variant="contained" color="secondary" onClick={handleAddOrg}>
          Add Org
        </Button>
      </Grid>
    </>
  );
};

export default OrgSelect;
