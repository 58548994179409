import React, { useEffect, useState } from "react";
import * as yup from "yup";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import AvatarSelection from "components/user/profile/AvatarSelection";
import ShowLoading from "components/processing/ShowLoading";
import ShowSaving from "components/processing/ShowSaving";
import TextInput from "components/inputs/TextInput";
import { useGetMyProfileQuery, useUpdateMyProfileMutation } from "api/common";
import { getUserAvatarPath } from "common/helper";
import { Stack, Typography } from "@mui/material";

const schema = yup.object().shape({
  name: yup.string().required("Valid name is required"),
  avatarId: yup.string(),
});

// see if we can default these from previous used?  or if we still need useEffect
const defaultValues = {
  name: "",
  avatarId: 0,
};

const ProfileForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAvatarId, setSelectedAvatarId] = useState(0);
  const [formError, setFormError] = useState("");

  const { data: profileData, isLoading } = useGetMyProfileQuery({});
  const [updateProfile, { error: apiError, isLoading: isSaving }] = useUpdateMyProfileMutation();

  const { control, formState, handleSubmit, reset } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (profileData) {
      setSelectedAvatarId(profileData.avatarId);
      reset({
        name: profileData.name,
        avatarId: profileData.avatarId,
      });
    }
  }, [profileData]);


  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    const data = {
      name: model.name,
      avatarId: parseInt(model.avatarId),
    };
    try {
      await updateProfile(data).unwrap();
      enqueueSnackbar("Profile Successfully Updated", {
        variant: "success",
      });
      setFormError("")
    } catch (e) {
      setFormError(e as string)
      enqueueSnackbar("Error Changing Profile", {
        variant: "error",
      });
    }
  };

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  if (isSaving) {
    return <ShowSaving />;
  }

  const { isValid, dirtyFields } = formState;
  
  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {formError && <Alert severity="error">{formError}</Alert>}
          {!formError && apiError && <Alert severity="error">Error updating profile</Alert>}
        </Grid>
      </Grid>
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput control={control} formState={formState} label="Name" variant="outlined" name="name" />
            </Grid>

            <Grid item xs={9}>
              <AvatarSelection currentAvatarId={selectedAvatarId} control={control} formState={formState} />
            </Grid>

            <Grid item xs={3} alignItems="stretch" style={{ display: "flex" }} sx={{ mt: 1, mb: 2 }}>
              <Stack sx={{ml: 2}}>
              <Typography variant="h6">Current</Typography>
              <Avatar sx={{ width: 56, height: 56 }} src={getUserAvatarPath(selectedAvatarId)} />
              </Stack>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" type="submit" color="success" sx={{ mt: 3, ml: 1 }} disabled={_.isEmpty(dirtyFields) || !isValid}>
              Update Profile
            </Button>
          </Box>
        </>
    </Box>
  );
};

export default ProfileForm;
