import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RegisterForm from "components/auth/RegisterForm";
import LoginForm from "components/auth/LoginForm";
import { setLoginRedirect } from "common/auth/storage";

export interface ConfirmationDialogRawProps {
  value: string;
  open: boolean;
  token: string;
  onClose: (value?: string) => void;
}

interface PreviewSessionDialogProps {
  token: string;
}

const PreviewSessionDialog = ({ token }: PreviewSessionDialogProps) => {
  // const [open, setOpen] = React.useState(true);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  useEffect(() => {
    setLoginRedirect("/pointing/" + token);
  }, []);

  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }} maxWidth="xs" TransitionProps={{ onEntering: handleEntering }} open={true}>
      <DialogTitle>Phone Ringtone</DialogTitle>
      <DialogContent classes={{ root: "p-24" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invite user tabs">
              <Tab label="Register" />
              <Tab label="Login" />
            </Tabs>
          </Box>

          {selectedTab == 1 && <LoginForm token={token} />}
          {selectedTab != 1 && <RegisterForm token={token} />}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewSessionDialog;
