import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

import { Story } from "common/types"
import { useListSessionImportStoriesQuery } from "api/sessions";
import StoryTableHeader from './StoryTableHeader';


interface StoryTableProps {
  sessionFilter: number | string;
  sessionId: number;
  onUpdateSelected: (selected: number[]) => void;
}

const StoryTable = ({sessionFilter, onUpdateSelected, sessionId}: StoryTableProps) => {
  const [selected, setSelected] = React.useState<readonly number[]>([]);


  const isMissingSession = (sessionFilter == -1)
  const ourSessionId = (sessionFilter == 'no-session' || sessionFilter == 'all') ? 0 : parseInt(sessionFilter as string);
  console.log("ourSessionId", ourSessionId)
  // if we have "ourSessionId" should we just call different api?  or limit by session?
  // sort: myOrder.id, sortDir: myOrder.direction, limit: currentPos.limit, offset: currentPos.offset, 

  const { data } = useListSessionImportStoriesQuery(
    { isMissingSession, sessionId, offset:0, limit: 20 },
    { refetchOnMountOrArgChange: true, skip: (!sessionId) }
  );
  const stories = (data?.data) ? data.data : [];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = stories.map((n: Story) => n.storyId);
      setSelected(newSelected);
      onUpdateSelected(newSelected)
      return;
    }
    setSelected([]);
    onUpdateSelected([])
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    onUpdateSelected(newSelected)
  };


  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
        <TableContainer sx={{mt: 2}}>
          <Table
            aria-labelledby="stories"
            size='small'
          >
            <StoryTableHeader
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={stories.length}
            />
            <TableBody>
              {stories.map((row: Story, index: number) => {
                const isItemSelected = isSelected(row.storyId);
                const labelId = `story-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.storyId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.storyId}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        name="storyIds"
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.sessionName}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
  );
}

export default StoryTable;