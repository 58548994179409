import React, { useState } from "react";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AddStoryForm from "components/session/stories/AddStoryForm";
import EditStoryForm from "components/session/stories/EditStoryForm";
import ShowLoading from "components/processing/ShowLoading";
import StoryList from "components/session/setup/StoryList";

import { useListSessionStoriesQuery } from "api/sessions";

interface AddSessionStoriesProps {
  sessionId: number;
}

const AddSessionStories = ({ sessionId }: AddSessionStoriesProps) => {
  const [selectedStory, setSelectedStory] = useState(-1);
  const { data: apiStoryList, isLoading, isError } = useListSessionStoriesQuery(sessionId);
  const storyList = apiStoryList?.data || [];

  const showEditForm = selectedStory >= 0;

  function handleDoneEditStory() {
    // deselect current story
    setSelectedStory(-1);
  }

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">Error Loading Session Stories</Alert>
      </Grid>
    );
  }

  return (
    <>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom align="center">
            Session Stories
          </Typography>
          <DndProvider backend={HTML5Backend}>
            <StoryList
              sessionId={sessionId}
              setSelectedStory={setSelectedStory}
              selectedStory={selectedStory}
              data={storyList}
              actionIcons={false}
            />
          </DndProvider>
        </Grid>
        <Grid item xs={8} sx={{ pl: 2 }}>
          {!showEditForm && <AddStoryForm sessionId={sessionId} />}
          {showEditForm && <EditStoryForm onClose={handleDoneEditStory} story={storyList[selectedStory]} />}
        </Grid>
      </Grid>
    </>
  );
};

export default AddSessionStories;
