import React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";
import { HeaderSortOrder, SessionTableColumnEntry } from "common/types";
import { getTableDirection } from "common/helper";


const rows: SessionTableColumnEntry[] = [
  {
    id: "name",
    align: "center",
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "total_pending",
    align: "center",
    disablePadding: false,
    label: "Pending Stories",
    sort: true,
  },
  {
    id: "total_pointed",
    align: "center",
    disablePadding: false,
    label: "Total Pointed",
    sort: true,
  },
  {
    id: "team_id",
    align: "center",
    disablePadding: false,
    label: "Team",
    sort: true,
  },
  {
    id: "status",
    align: "center",
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "actions",
    align: "center",
    disablePadding: false,
    label: "Actions",
    sort: false,
  },
];

interface ListHeaderProps {
  order: HeaderSortOrder;
  onRequestSort: (id: string) => void;
}

function ListHeader({ onRequestSort, order }: ListHeaderProps) {
  const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onRequestSort(property);
  };

  // const {onSelectAllClick, order, orderBy, numSelected, rowCount} = props;

  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        {rows.map((row) => {
          return (
            <TableCell
              className="p-4 md:p-16"
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? "none" : "normal"}
              sortDirection={order.id === row.id ? order.direction : undefined}
            >
              {row.sort && (
                <Tooltip
                  title="Sort"
                  placement={row.align === "right" ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={order.id === row.id}
                    direction={getTableDirection(order.direction)}
                    onClick={createSortHandler(row.id)}
                    className="font-semibold"
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              )}
            </TableCell>
          );
        })}
        {/* }, this)} */}
      </TableRow>
    </TableHead>
  );
}

export default ListHeader;
