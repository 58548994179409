import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

//import { getRefreshToken } from "common/auth/storage";
import { setAuthData, unsetAuthData } from "../store/auth";
import { calculateExpireTime } from "../common/helper";
import { getRefreshToken } from "../common/auth/storage";
import { RootState } from "store";

interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  expireIn: number;
  refreshExpireIn: number;
  userEmail: string;
  userId: number;
  userRole: string;
}

/**
 * Transform response from auth token's to a common needed format
 *
 * @param {object} reponse  API Response Object
 * @returns
 */
export const authUserDataTransform = (response: AuthResponse) => {
  const expireAt = calculateExpireTime(response.expireIn);
  const refreshExpireAt = calculateExpireTime(response.refreshExpireIn);

  return {
    accessToken: response.accessToken,
    refreshToken: response.refreshToken,
    expireAt: expireAt,
    refreshExpireAt: refreshExpireAt,
    role: response.userRole,
    email: response.userEmail,
    userId: response.userId,
  };
};

function getApiHost() {
  if (process.env.NODE_ENV == "production") {
    return "https://api.storypointcards.com";
  }
  if (process.env.NODE_ENV == "test") {
    return "value";
  }
  return "http://localhost:8000";
}

const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseUrl: getApiHost() + "/v1/",

  prepareHeaders: (headers, { getState }) => {
    // if we have a token add to header
    const accessToken = (getState() as RootState).auth.accessToken;
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const addPaging = (offset: string, limit: string) => {
  if (parseInt(offset) > -1 && parseInt(limit) > 0) {
    return "?offset=" + offset + "&limit=" + limit;
  }

  return "?";
};

// interface QueryParms {
//   name: string;
//   value: string;
// }

// export const addQueryParams = (params: Array<Array<string, any>>) => {
//   let values = [];
//   for (let i in params) {
//     if (!_.isEmpty(params[i])) {
//       values.push(i + "=" + params[i]);
//     }
//   }

//   if (values.length > 0) {
//     return "?" + values.join("&");
//   }

//   return "";
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: "auth/refresh",
        method: "POST",
        body: {
          refreshToken: getRefreshToken(),
        },
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      const { data } = refreshResult;
      const tokenData = authUserDataTransform(data as AuthResponse);
      // store the new token
      api.dispatch(setAuthData(tokenData));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(unsetAuthData());
    }
  }
  return result;
};

// Define our single API slice object
export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Teams", "Stories", "Orgs", "User", "UserData", "UserList", "MyUserData", "PointingSession", "SessionStoryList", "SessionStats", "NotifySettings"],
  endpoints: (builder) => ({
    
    unsubscribeEmail: builder.mutation({
      query: ({ email }) => ({
        url: "notify/unsubscribe/email",
        method: "POST",
        body: {
          email: email,
        },
      }),
    }),
    
    getMyNotifications: builder.query({
      query: () =>"notify/settings",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["NotifySettings"],
    }),

    updateMyNotifications: builder.mutation({
      query: (data) => ({
        url: "notify/settings",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NotifySettings"],
    }),

    register: builder.mutation({
      query: (data) => ({
        url: "accounts/register",
        method: "POST",
        body: data,
      }),
      transformResponse: authUserDataTransform,
    }),

    inviteRegister: builder.mutation({
      query: ({ inviteCode, data }) => ({
        url: "accounts/register/invite/" + inviteCode,
        method: "POST",
        body: data,
      }),
      transformResponse: authUserDataTransform,
    }),

    getInviteCodeData: builder.query({
      query: ({ inviteCode }) => "accounts/register/invite/" + inviteCode,
      keepUnusedDataFor: 300, // cache for 5 min
      providesTags: ["UserData"],
    }),

    completeRegistration: builder.mutation({
      query: (data) => ({
        url: "accounts/register/complete",
        method: "POST",
        body: data,
      }),
      transformResponse: authUserDataTransform,
    }),

    // verify email for registeration
    requestRegisterCode: builder.mutation({
      query: (data) => ({
        url: "accounts/register/request-new-code",
        method: "POST",
        body: data,
      }),
    }),

    changePassword: builder.mutation({
      query: ({ password, newPassword }) => ({
        url: "accounts/change-password",
        method: "POST",
        body: {
          password: password,
          newPassword: newPassword,
        },
      }),
    }),

    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: "accounts/forgot-password",
        method: "POST",
        body: {
          email,
        },
      }),
    }),

    loginLink: builder.mutation({
      query: ({ email }) => ({
        url: "accounts/login-link",
        method: "POST",
        body: {
          email,
        },
      }),
    }),

    deleteAccount: builder.mutation({
      query: () => ({
        url: "accounts/delete",
        method: "POST",
      }),
    }),

    requestReset: builder.mutation({
      query: ({ email }) => ({
        url: "accounts/reset-password/generate-code",
        method: "POST",
        body: {
          email: email,
        },
      }),
    }),


    resetPassword: builder.mutation({
      query: ({ resetCode, password }) => ({
        url: "accounts/reset-password",
        method: "POST",
        body: {
          code: resetCode,
          password: password,
        },
      }),
    }),

    getMyProfile: builder.query({
      query: () => "accounts/profile",
      keepUnusedDataFor: 300, // cache for 5 min
      providesTags: ["UserData"],
    }),

    listUsers: builder.query({
      query: () => "accounts/list?limit=10000",
      keepUnusedDataFor: 600, // cache for 10 min
      providesTags: ["UserList"],
    }),

    getUserData: builder.query({
      query: ({ userId }) => "accounts/" + userId + "/all",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["UserData"],
    }),

    updateMyProfile: builder.mutation({
      query: (data) => ({
        url: "accounts/profile",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UserData", "UserList"],
    }),

    updateUserProfile: builder.mutation({
      query: ({ userId, data }) => ({
        url: "accounts/profile/" + userId,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [{ type: "User", id: "Profile" }, "UserList"],
    }),

    getOrgList: builder.query({
      query: ({ offset, limit, withExtra }) => "orgs" + addPaging(offset, limit) + (withExtra ? "&withExtra=1" : ""),
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Orgs"],
    }),

    getOrgTeamsList: builder.query({
      query: ({ orgId, offset, limit, withExtra }) => "orgs/" + orgId + "/teams" + addPaging(offset, limit) + (withExtra ? "&withExtra=1" : ""),
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Orgs", "Teams"],
    }),

    getMyOrgTeamsList: builder.query({
      query: ({ orgId, offset, limit }) => "orgs/" + orgId + "/teams/mine" + addPaging(offset, limit),
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Orgs", "Teams"],
    }),

    getOrgAndTeamList: builder.query({
      query: () => "orgs/teams",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Orgs"],
    }),

    getOrg: builder.query({
      query: ({ orgId }) => "orgs/" + orgId,
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Orgs"],
    }),

    invitesList: builder.query({
      query: () => "orgs/invites",
      keepUnusedDataFor: 600,
      providesTags: ["Orgs"],
    }),

    inviteAccept: builder.mutation({
      query: ({ inviteCode }) => ({
        url: "orgs/invites/" + inviteCode + "/accept",
        method: "POST",
      }),
      invalidatesTags: ["UserData", "Orgs", "Teams"],
    }),

    inviteDecline: builder.mutation({
      query: ({ inviteCode }) => ({
        url: "orgs/invites/" + inviteCode + "/decline",
        method: "POST",
      }),
      invalidatesTags: ["Orgs"],
    }),

    newOrg: builder.mutation({
      query: (data) => ({
        url: "orgs",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Orgs"],
    }),
    updateOrg: builder.mutation({
      query: ({ orgId, data }) => ({
        url: "orgs/" + orgId,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: ["Orgs"],
    }),

    getTeamList: builder.query({
      query: () => "teams",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Teams"],
    }),

    getTeam: builder.query({
      query: ({ orgId, teamId }) => "orgs/" + orgId + "/teams/" + teamId,
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Teams"],
    }),

    newTeam: builder.mutation({
      query: ({ orgId, data }) => ({
        url: "orgs/" + orgId + "/teams",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Teams"],
    }),
    updateTeam: builder.mutation({
      query: ({ orgId, teamId, data }) => ({
        url: "orgs/" + orgId + "/teams/" + teamId,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: ["Teams"],
    }),
    orgMembersList: builder.query({
      query: ({ orgId }) => "orgs/" + orgId + "/members",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Orgs"],
    }),

    orgMembersLeave: builder.mutation({
      query: ({ orgId }) => ({
        url: "orgs/" + orgId + "/members",
        method: "DELETE",
      }),
      invalidatesTags: ["Orgs", "Teams"],
    }),
    teamMembersList: builder.query({
      query: ({ orgId, teamId }) => "orgs/" + orgId + "/teams/" + teamId + "/members",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Teams"],
    }),
    teamEligibleMembersList: builder.query({
      query: ({ orgId, teamId }) => "orgs/" + orgId + "/teams/" + teamId + "/members/eligible-to-join",
      keepUnusedDataFor: 600, // cache for 10 minutes
      providesTags: ["Teams"],
    }),
    orgMembersAdd: builder.mutation({
      query: ({ orgId, userId }) => ({
        url: "orgs/" + orgId + "/members/" + userId,
        method: "POST",
      }),
      invalidatesTags: ["Orgs", "Teams"],
    }),
    orgMembersRemove: builder.mutation({
      query: ({ orgId, userId }) => ({
        url: "orgs/" + orgId + "/members/" + userId,
        method: "DELETE",
      }),
      invalidatesTags: ["Orgs", "Teams"],
    }),
    teamMembersJoin: builder.mutation({
      query: ({ orgId, teamId }) => ({
        url: "orgs/" + orgId + "/teams/" + teamId + "/members",
        method: "POST",
      }),
      invalidatesTags: ["Teams"],
    }),
    teamMembersLeave: builder.mutation({
      query: ({ orgId, teamId }) => ({
        url: "orgs/" + orgId + "/teams/" + teamId + "/members",
        method: "DELETE",
      }),
      invalidatesTags: ["Teams"],
    }),

    teamAddUsers: builder.mutation({
      query: ({ orgId, teamId, userIds }) => ({
        url: "orgs/" + orgId + "/teams/" + teamId + "/members/add-users",
        method: "POST",
        body: {
          userIds: userIds,
        },
      }),
      invalidatesTags: ["Teams"],
    }),

    teamInviteEmails: builder.mutation({
      query: ({ orgId, teamId, email }) => ({
        url: "orgs/" + orgId + "/teams/" + teamId + "/members/invite",
        method: "POST",
        body: {
          email: email,
        },
      }),
      invalidatesTags: ["Teams"],
    }),

    teamMembersAdd: builder.mutation({
      query: ({ orgId, teamId, userId }) => ({
        url: "orgs/" + orgId + "/teams/" + teamId + "/members/" + userId,
        method: "POST",
      }),
      invalidatesTags: ["Teams"],
    }),
    teamMembersRemove: builder.mutation({
      query: ({ orgId, teamId, userId }) => ({
        url: "orgs/" + orgId + "/teams/" + teamId + "/members/" + userId,
        method: "DELETE",
      }),
      invalidatesTags: ["Teams"],
    }),
  }),
});

export const util = api.util;
export const {
  // user realted
  useChangePasswordMutation,
  useCompleteRegistrationMutation,
  useDeleteAccountMutation,
  useGetInviteCodeDataQuery,
  useGetMyNotificationsQuery,
  useGetMyProfileQuery,
  useGetUserDataQuery,
  useInviteRegisterMutation,
  useListUsersQuery,
  useRegisterMutation,
  useRequestResetMutation,
  useRequestRegisterCodeMutation,
  useResetPasswordMutation,
  useUpdateMyNotificationsMutation,
  useUpdateMyProfileMutation,
  useUpdateUserProfileMutation,
  useForgotPasswordMutation,
  useLoginLinkMutation,
  useUnsubscribeEmailMutation,

  // Org & Team Functions
  useGetOrgListQuery,
  useGetOrgTeamsListQuery,
  useGetOrgAndTeamListQuery,
  useGetOrgQuery,
  useNewOrgMutation,
  useUpdateOrgMutation,
  useGetTeamListQuery,
  useGetTeamQuery,
  useInvitesListQuery,
  useInviteAcceptMutation,
  useInviteDeclineMutation,
  useOrgMembersAddMutation,
  useOrgMembersLeaveMutation,
  useOrgMembersListQuery,
  useOrgMembersRemoveMutation,
  useTeamEligibleMembersListQuery,
  useTeamAddUsersMutation,
  useTeamInviteEmailsMutation,
  useTeamMembersAddMutation,
  useTeamMembersJoinMutation,
  useTeamMembersLeaveMutation,
  useTeamMembersListQuery,
  useTeamMembersRemoveMutation,
  useGetMyOrgTeamsListQuery,
  useNewTeamMutation,
  useUpdateTeamMutation,
} = api;
