import React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import { visuallyHidden } from "@mui/utils";

import { StatsDataStoryInterface, StatsDataParticipantInterface } from "common/types";
import { TableStructInterface } from "./types";

interface StatsTableProps {
  data: StatsDataStoryInterface[] | StatsDataParticipantInterface[];
  tableStruct: TableStructInterface[];
  name: string;
}


type Order = "asc" | "desc";

function tableColumnPrint(field: string, value: string | number) {
  if (field === "name") {
    return value;
  }

  if (value === -1) {
    return "?";
  }

  if (value === -2) {
    return "N/A";
  }
  //@todo for mode we should have 99,98 ? as constant fields for data?
  if (value === 100) {
    return "∞";
  }
  return value;
}


const StatsTable = ({ name, data, tableStruct }: StatsTableProps) => {

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("name");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (

    <Paper sx={{ width: "100%", mb: 2, mt: 3 }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          {name} Pointing Stats
        </Typography>

        <Tooltip title={`Export ${name} Stats`}>
          <IconButton>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Print ${name} Stats`}>
          <IconButton>
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="stats table">
          <TableHead>
            <TableRow>
              {tableStruct.map((entry) => (
                <TableCell key={entry.id} align={entry.align} sortDirection={orderBy === entry.id ? order : false}>
                  <TableSortLabel active={orderBy === entry.id} direction={orderBy === entry.id ? order : "asc"} onClick={() => handleRequestSort(entry.id)}>
                    {entry.label}
                    {orderBy === entry.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={row.name} hover>
                {tableStruct.map((entry) => (
                  <TableCell key={`stats-row-${rowIndex}-${entry.id}`} align={entry.align}>
                    {tableColumnPrint(entry.id, row[entry.id as keyof typeof row])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )

}

export default StatsTable;