import React from "react";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

interface PointInputProps {
  value: string;
  label: string;
  points: string | number;
}

const PointInput = ({ value, label, points }: PointInputProps) => {
  const radioName = `radio-${value}`;
  return (
    <>
      <FormHelperText id={radioName}>{label}</FormHelperText>
      <FormControlLabel value={value} control={<Radio id={radioName} />} disabled={points == "N/A" || points == "∞"} label={points} />
    </>
  );
};

export default PointInput;
