import React from "react";

import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";


interface TableFilterTabProps {
  label: string;
  value: string;
  to: string;
  component: React.ElementType;
}

const TableFilterTab = styled((props: TableFilterTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.7)',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#666',
    borderRadius: 15,
    borderColor: '#FFD700'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

export default TableFilterTab