import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import { useInviteAcceptMutation, useInviteDeclineMutation } from 'api/common';

interface InviteProcessPageProp {
  action: "accept" | "decline"
}

const InviteProcessPage = ({action}: InviteProcessPageProp) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { inviteCode } = routeParams;
    
  const [acceptInvite] = useInviteAcceptMutation();
  const [declineInvite] = useInviteDeclineMutation();

  const handleAccept = async (code: string) => {
    try {
      await acceptInvite({ inviteCode: code });
      enqueueSnackbar("Accepted Invite Successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error accepting invite", {
        variant: "error",
      });
    }
  }

  const handleDecline = async (code: string) => {
    try {
      await declineInvite({ inviteCode: code });
      enqueueSnackbar("Declined Invite Successfully", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error declining Invite", {
        variant: "error",
      });
    }
  }


  useEffect(() => {
     if ( action == "accept") {
      handleAccept(inviteCode as string)
    }
    else {
      handleDecline(inviteCode as string)
    }
    navigate("/user/teams", { replace: true })
  }, [action, inviteCode])
  
  return <></>
};

export default InviteProcessPage;
