import React, { useEffect, useState } from "react";
import * as yup from "yup";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

import ShowLoading from "components/processing/ShowLoading";
import ShowSaving from "components/processing/ShowSaving";
import { useGetMyNotificationsQuery, useUpdateMyNotificationsMutation } from "api/common";

const schema = yup.object().shape({
  email: yup.boolean(),
  text: yup.boolean(),
  inApp: yup.boolean(),
  inBrowser: yup.boolean(),
});

// see if we can default these from previous used?  or if we still need useEffect
const defaultValues = {
  email: true,
  text: false,
  inApp: false,
  inBrowser: false,
};

const NotificationsForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState("");

  const { data: notifyData, isLoading } = useGetMyNotificationsQuery({});
  const [updateNotify, { error: apiError, isLoading: isSaving }] = useUpdateMyNotificationsMutation();

  const { control, formState, handleSubmit, reset } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (notifyData) {
      reset({
        email: notifyData.email,
        text: notifyData.text,
        inApp: notifyData.inApp,
        inBrowser: notifyData.inBrowser,
      });
    }
  }, [notifyData]);


  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      await updateNotify(model).unwrap();
      
      enqueueSnackbar("Notifications Successfully Updated", {
        variant: "success",
      });

      setFormError("")
    } catch (e) {
      console.log(e)
      setFormError(e as string)
      enqueueSnackbar("Error Changing Notifications", {
        variant: "error",
      });
    }
  };

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <ShowLoading />
      </Grid>
    );
  }

  if (isSaving) {
    return <ShowSaving />;
  }

  const { isValid, dirtyFields } = formState;

  return (

    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
       <Grid container spacing={3}>
        <Grid item xs={12}>
          {formError && <Alert severity="error">{formError}</Alert>}
          {!formError && apiError && <Alert severity="error">Error updating notifications</Alert>}
        </Grid>
      </Grid>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Enable / Disable Forms of Notifications</FormLabel>
        <FormGroup>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label="Receive Email"
              />
            )}
          />
          <Controller
            name="text"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label="Receive Mobile Text"
              />
            )}
          />
          <Controller
            name="inApp"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label="App Notifications"
              />
            )}
          />
          <Controller
            name="inBrowser"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label="Browser Notifications"
              />
            )}
          />
        </FormGroup>
        <FormHelperText>Notifications are limited to account action and sessions. We do not send marketing notifications</FormHelperText>
      </FormControl>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" type="submit" color="success" sx={{ mt: 3, ml: 1 }} disabled={_.isEmpty(dirtyFields) || !isValid}>
          Update Settings
        </Button>
      </Box>
    </Box>
  );
};

export default NotificationsForm;
