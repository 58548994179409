import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import ButtonToOutput from "./ButtonToOutput";
import StoryName from "./StoryName";
import StoryStats from "./StoryStats";

import { useAppSelector } from "store";
import SetPointsDialog from "components/dialogs/SetPointsDialog";

const StoryBoard = () => {
  const userInControl = useAppSelector(({ session }) => session.userInControl);
  const story = useAppSelector(({ session }) => session.activeStory);

  if (!story) {
    return (
      <Card sx={{ mx: 10, mt: 3 }}>
        <CardContent>
          <Typography textAlign="center">No Active Story</Typography>
        </CardContent>
      </Card>
    );
  }

  /*
  TODO
  - make left side larger.
  - add summary to bottom
  - add link to name (if present)
  - add stats to right column
  - default N/A or 0 where possible

  */

  return (
    <>
      <Card sx={{ display: "flex", mx: 9, mt: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: 430 }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <StoryName name={story.name} link={story.link} />
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {story.summary}
            </Typography>
          </CardContent>
          {userInControl && (
            <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
              <ButtonToOutput story={story} />
            </Box>
          )}
        </Box>
        <StoryStats story={story} />
      </Card>

      <SetPointsDialog />
    </>
  );
};

export default StoryBoard;
