import React from 'react';

import Grid from "@mui/material/Grid";
import PointingCards from "components/session/active/PointingCards";
import PointingAvatarList from "components/session/active/PointingAvatarList";

import { useAppSelector } from "store";
import ManageObserverStatus from './ManageObserverStatus';

const PointingActionGrid = () => {
  const sessionId = useAppSelector(({ session }) => session?.data?.sessionId || 0);
  const isObserver = useAppSelector(({ session }) => session?.isObserver);
  const userInControl = useAppSelector(({ session }) => session.userInControl);
  const storyStatus = useAppSelector(({ session }) => session?.activeStory?.status || "pending");

 
  const mainOutput = (!isObserver && storyStatus === "started") ? <PointingCards /> : <PointingAvatarList />;
  return (
    <Grid container spacing={5} sx={{ mt: 0 }}>
      <ManageObserverStatus isObserver={isObserver} enabled={!userInControl} sessionId={sessionId} />
      {mainOutput}
    </Grid>
  )
}


export default PointingActionGrid;