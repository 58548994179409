import React from "react";
import Grid from "@mui/material/Grid";

import AvatarCard from "./AvatarCard";
import { SessionUser } from "common/types";

interface AvatarCardListProps {
  users: SessionUser[];
  showPoints?: boolean;
}

function sortIfNeeded(users: SessionUser[], showPoints: boolean) {
  if (showPoints === true) {
    if(users.length > 1) {
      return [...users].sort((a: SessionUser, b: SessionUser) => {
        const aPoints = (a.points === undefined || a.points < 0) ? 1000 : a.points;
        const bPoints = (b.points === undefined || b.points < 0) ? 1000 : b.points;
        return aPoints - bPoints
      });
    }
  }
  return users || [];
}


const AvatarCardList = ({users, showPoints} : AvatarCardListProps) => {

  const data = sortIfNeeded(users, !!showPoints);

  return (
    <>
      <Grid container spacing={1}>
        {data.map((entry: SessionUser) => (
          <AvatarCard key={`pointing-avatar-list-${entry.userId}`} user={entry} showPoints={!!showPoints} />
        ))}
      </Grid>
    </>
  );
};

export default AvatarCardList;
