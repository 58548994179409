import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import LoginForm from "components/auth/LoginForm";
import useSetPageTitle from "hooks/use-set-page-title";
import LoginLinkForm from "components/auth/LoginLinkForm";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: 300,
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const LoginPage = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useSetPageTitle("Login");


  return (
    <Box sx={{ flexGrow: 1, pt: 10 }} >
      <Grid container spacing={4}  >
        <Grid item xs={3}> </Grid>
        <Grid item xs={6}>
          <Item elevation={3}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Login" {...a11yProps(0)} />
                  <Tab label="Generate Link" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <LoginForm />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Typography variant="subtitle1">Request an email be sent with a one time use login link.</Typography>
                <LoginLinkForm />
              </CustomTabPanel>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
