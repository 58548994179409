import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useUnsubscribeEmailMutation } from "api/common";

const UnsubscribePage = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [unsubscribe] = useUnsubscribeEmailMutation();

  useEffect(() => {
    if (email) {
      unsubscribe({ email });
    }
  }, []);

  return <p>You are now unsubscribed from all future emails</p>;
};

export default UnsubscribePage;
