import React from "react";
import { Control, FieldValues, FormState } from "react-hook-form";

import DialogContent from "@mui/material/DialogContent";

import TextInput from "components/inputs/TextInput";

interface UserDialogContentProps {
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
}
const UserDialogContent = ({ control, formState }: UserDialogContentProps) => {
  return (
    <DialogContent classes={{ root: "p-24" }}>
      <TextInput control={control} formState={formState} label="Team Name" variant="outlined" name="name" />
    </DialogContent>
  );
};

export default UserDialogContent;
