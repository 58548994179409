import { useEffect, useCallback, useState } from "react";

import { calculateRemainingTime } from "../common/helper";
import { useLogoutMutation } from "api/auth";
import { util } from "api/common";
import { useAppDispatch, useAppSelector } from "store";
import { getRefreshToken } from "common/auth/storage";

interface LogOutTimer {
  timerConfirm: NodeJS.Timeout | undefined; // holds reference to get confirmation of pending auto logout
  timerAct: NodeJS.Timeout | undefined; // holds reference to active timer to auto logout
  expireAt: number; // holds the time the couter was set to
}

const DEBUG = false;

// ask to confirm 5 minutes before auto logout (in milliseconds )
const confirmTimeout = 5 * 60 * 1000;

const logOutTimer: LogOutTimer = {
  timerConfirm: undefined,
  timerAct: undefined,
  expireAt: 0,
};

/**
 * Send and handle api requests
 * @param {string} authToken  The auth token to use when an API neeeds it
 * @returns [stayLoggedIn, logoutNow()]
 */
const useAutoLogout = () => {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  // fetch individually so they are only rerendered when the value changes
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const expireAt: number = useAppSelector((state) => state.auth.refreshExpireAt);

  const [showConfirm, setShowConfirm] = useState(false);

  const setAutoLogoutTimer = useCallback(
    (expireAt: number) => {
      if (DEBUG) console.log("::AUTO-LOGIN:: create auto logout timer");
      if (logOutTimer.timerAct) {
        if (DEBUG) console.log("::AUTO-LOGIN:: clear timeOutTimer");
        clearTimeout(logOutTimer.timerAct);
        clearTimeout(logOutTimer.timerConfirm);
      }
      // @todo this needs to be set to expire token
      const expireIn = calculateRemainingTime(expireAt);
      logOutTimer.expireAt = expireAt;

      logOutTimer.timerAct = setTimeout(() => {
        if (DEBUG) console.log("::AUTO-LOGIN:: Do logout timerAct");
        logout({ refresh_token: getRefreshToken(), auto: true });
        dispatch(util.resetApiState());
      }, expireIn);

      const confirmTime = expireIn - confirmTimeout;
      // set state to show confirmation
      logOutTimer.timerConfirm = setTimeout(() => {
        if (DEBUG) console.log("::AUTO-LOGIN:: Show logout timerConfirm");
        setShowConfirm(true);
      }, confirmTime);
    },
    [dispatch]
  );

  useEffect(() => {
    // if either isLoggedIn or expireAt changed
    // than we either logged out, logged in, or
    // refreshed the token.  based on those set
    // or clear logout timer

    if (isLoggedIn) {
      if (logOutTimer.timerAct) {
        // if logged in and have a timer, see if setAt changed
        if (logOutTimer.expireAt !== expireAt) {
          // timer changed, recreate
          if (DEBUG) console.log("::AUTO-LOGIN:: create timer, refresh token");
          setAutoLogoutTimer(expireAt);
        }
      } else {
        if (DEBUG) console.log("::AUTO-LOGIN:: create timer, new login");
        // we dont have a timer, so create it
        setAutoLogoutTimer(expireAt);
      }
    } else {
      if (DEBUG) console.log("::AUTO-LOGIN:: Not logged in");
      // if not logged in clear a timer if it exists
      if (logOutTimer.timerAct) {
        if (DEBUG) console.log("::AUTO-LOGIN:: Not logged in, remove timers");
        clearTimeout(logOutTimer.timerAct);
        clearTimeout(logOutTimer.timerConfirm);
        logOutTimer.timerAct = undefined;
        logOutTimer.expireAt = 0;
      }
    }
  }, [isLoggedIn, expireAt, setAutoLogoutTimer]);

  /**
   * user wants to logout, trigger code
   */
  const confirmHandled = () => {
    setShowConfirm(false);
  };

  return { showConfirm, confirmHandled, setShowConfirm };
};

export default useAutoLogout;
