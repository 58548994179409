import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";

import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

import ShowSaving from "components/processing/ShowSaving";
import { useImportSessionStoriesMutation } from "api/sessions";
import { hideImportStoryToSession } from "store/dialogs";
import { useAppDispatch, useAppSelector } from "store";

import SessionFilter from "./SessionSelectFilter";
import StoryTable from "./StoryTable";


const ImportStoryDialog = () => {
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [sessionFilter, setSessionFilter] = useState('no-session');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { sessionId, show } = useAppSelector(({ dialogs }) => dialogs.importStoryForm);

  const [importStories, { isLoading: isSavingNew }] = useImportSessionStoriesMutation();


  const handleChange = useCallback((value: string) => {
    console.log("changed: ", value)
    // should sessionId be a trigger???
    setSessionFilter(value);
  }, [sessionId]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideImportStoryToSession());
  }


  /**
   * Submit Form
   */
  const handleSubmit = async () => {
    const data = { 
      storyIds: selected
    }
    
    try {
      const result = await importStories({ sessionId, data }).unwrap();
      if (result && result?.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("Successfully Import stories", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error importing stories", {
        variant: "error",
      });
    }
    closeComposeDialog();
  };

  
  if (isSavingNew) {
    return <ShowSaving />;
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={show === null ? false : show}
      onClose={closeComposeDialog}
      fullWidth
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>Import Story to Session </strong>
          </Typography>
        </Toolbar>
      </AppBar>



        <DialogContent classes={{ root: "p-24" }}>

          <SessionFilter onChange={handleChange} sessionId={sessionId} />
          <StoryTable sessionFilter={sessionFilter} onUpdateSelected={setSelected} sessionId={sessionId} />

        </DialogContent>


        <DialogActions className="justify-between p-4 pb-16">
          <div className="px-16">
            <Button variant="contained" color="secondary" type="submit" disabled={(selected.length == 0)} onClick={handleSubmit}>
              Import Selected
            </Button>
          </div>
          <div className="px-16">
            <Button variant="contained" color="primary" onClick={closeComposeDialog}>
              Cancel
            </Button>
          </div>
        </DialogActions>
    </Dialog>
  );
};

export default ImportStoryDialog;
