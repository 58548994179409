import React from "react";

import Grid from "@mui/material/Grid";

import SimpleDataBox from "../SimpleDataBox";
import PointPieChart from "../graphs/PointPieChart";
import SessionPointsChart from "../graphs/SessionPointsChart";
import { SessionData, SessionOverviewStats } from "common/types";

/*
- issues this session copared to last
- avg session points
- avg session points compared ot last session

- avg participants per story
- total participants
- total stories
- total points


Line Chart:
y: count (total stories)
x: points
this session vs last lession (maybe 3 ?)

Scatter or Bubble Chart
all points and frequency used
y: total times suggested
x: points

Maybe:
candlestick chart
range of points per story
y: points
x: story
also maybe make above with range area chart and could have this and last session?  or use that fore the above???

TODO: move boxplot into stories and participant pages???

*/

//@todo add logic for data size... know which is primary and which is secondary count
interface StatsOverviewProps {
  session: SessionData;
  data: SessionOverviewStats[];
}

const StatsOverview = ({ data }: StatsOverviewProps) => {
  return (
    <Grid container spacing={3} sx={{ mt: 0 }} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Grid container spacing={1} sx={{ mt: 0, p: 0 }}>
          <Grid item xs={6}>
            <SimpleDataBox name="Average Story Point" total={data[0].average} typeName="points" color="#52baff" />
          </Grid>
          <Grid item xs={6}>
            <SimpleDataBox name="Total Stories" total={data[0].totalStories} typeName="stories" color="#1ad678" />
          </Grid>
          <Grid item xs={6}>
            <SimpleDataBox name="Participants" total={data[0].participants} typeName="users" color="#ff711f" />
          </Grid>
          <Grid item xs={6}>
            <SimpleDataBox name="Total Points" total={data[0].totalPoints} typeName="points" color="#b55afa" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <PointPieChart data={data[0].points} />
      </Grid>

      <Grid item xs={12}>
        <SessionPointsChart session1={data[0]} session2={data[1]} session3={data[2]} />
      </Grid>
      {/* <Grid item xs={4}>
        <PointPieChart data={pointPie2} />
      </Grid>

      <Grid item xs={4}>
        <PointPieChart data={pointPie3} />
      </Grid>

      <Grid item xs={4}>
        <PointPieChart data={pointPie4} />
      </Grid>

      <Grid item xs={4}>
        <PointPieChart data={pointPie5} />
      </Grid> */}

      {/* <Grid item xs={4}>
        <PointPieChart data={{}} />
      </Grid>

      <Grid item xs={4}>
        <PieChart data={{}} />
      </Grid> */}
    </Grid>
  );
};

export default StatsOverview;
