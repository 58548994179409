import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import SubmitButton from "components/inputs/SubmitButton";
import EmailInput from "components/inputs/EmailInput";
import TextInput from "components/inputs/TextInput";
import { ErrorAPI } from "common/types";
import { useRegisterMutation } from "api/common";
import { clearLoginRedirect, getLoginRedirect } from "common/auth/storage";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email("You must enter a valid email").required("You must enter a email"),
  name: yup.string().required("Please enter a name."),
});

type RegisterFormValues = {
  email: string;
  name: string;
};

const defaultValues: RegisterFormValues = {
  email: "",
  name: "",
};

interface RegisterForm {
  token?: string;
}

/**
 * Register a new user
 * @param {object} props
 * @returns
 */
const RegisterForm = (props: RegisterForm) => {
  const navigate = useNavigate();
  const redirectPath = getLoginRedirect() || "/dashboard";
  const { control, formState, handleSubmit, setError } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const sessionToken = props?.token || "";

  const [register, { isLoading, error: apiError }] = useRegisterMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    try {
      await register({ ...model, sessionToken }).unwrap();

      // navigate("/dashboard", { replace: true });
      clearLoginRedirect();
      navigate(redirectPath, { replace: true });
    } catch (e) {
      if ((e as ErrorAPI).data?.error == "duplicate email") {
        setError("email", { type: "string", message: "Email already registered" });
      } else {
        setError("email", { type: "string", message: "Error registering" });
      }
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      {apiError && <p>Register API Error</p>}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput control={control} label="Name" name="name" formState={formState} />
        </Grid>
        <Grid item xs={12}>
          <EmailInput control={control} label="E-Mail" formState={formState} />
        </Grid>

        {/* <Grid item xs={12}>
          <PasswordInput
            control={control}
            label="Password"
            errors={errors.password}
          />
        </Grid> */}

        <Grid item xs={12}>
          <SubmitButton title="Register" formState={formState} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterForm;
