import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import ParticipantList from "./ParticipantList";
import { useAppSelector } from "store";

function ParticipantListPanel() {
  const data = useAppSelector(({ session }) => session.participants);

  return (
    <Paper sx={{ my: 5, p: 1 }}>
      <ParticipantList data={data} />
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ my: 1 }}>
        <Button type="submit" variant="contained" size="small">
          Add Participant
        </Button>
      </Stack>
    </Paper>
  );
}

export default ParticipantListPanel;
