import React, { useState } from "react";
import { useSnackbar } from "notistack";

import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

import ShowSaving from "components/processing/ShowSaving";
import { useSessionCloseMutation } from "api/sessions";
import { hideArchiveSession } from "store/dialogs";
import { useAppDispatch, useAppSelector } from "store";

import SessionSelection from "./SessionSelection";


const ArchiveSessionDialog = () => {
  const [selected, setSelected] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { sessionId, show } = useAppSelector(({ dialogs }) => dialogs.archiveSession);

  const [closeSession, { isLoading: isSavingNew }] = useSessionCloseMutation();

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideArchiveSession());
  }


  /**
   * Submit Form
   */
  const handleSubmit = async () => {
    try {
      const result = await closeSession({ sessionId, newSessionId: selected }).unwrap();
      if (result && result?.error) {
        throw Error(result.error);
      }

      enqueueSnackbar("Successfully Archived Session", {
        variant: "success",
      });
    } catch (err) {
      console.log("err save", err);
      enqueueSnackbar("Error archiving session", {
        variant: "error",
      });
    }
    closeComposeDialog();
  };

  
  if (isSavingNew) {
    return <ShowSaving />;
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={show === null ? false : show}
      onClose={closeComposeDialog}
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>Archive Session</strong>
          </Typography>
        </Toolbar>
      </AppBar>



        <DialogContent classes={{ root: "p-24" }}>
          <Typography sx={{mb: 2}}>There are pending stories in this session.  What do you wish to do with them?</Typography>
          <SessionSelection onChange={setSelected} sessionId={sessionId} />

        </DialogContent>


        <DialogActions className="justify-between p-4 pb-16">
          <div className="px-16">
            <Button variant="contained" color="secondary" type="submit" onClick={handleSubmit}>
              Archive 
            </Button>
          </div>
          <div className="px-16">
            <Button variant="contained" color="primary" onClick={closeComposeDialog}>
              Cancel
            </Button>
          </div>
        </DialogActions>
    </Dialog>
  );
};

export default ArchiveSessionDialog;
