import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { SessionPointType, SessionPointSeriesData } from "common/types";
import { get3SessionSeriesData } from "common/helper";
import options from "./apexOptions";

interface SessionPointEntry {
  name: string;
  points: SessionPointType[];
}

interface SessionPointsProps {
  session1: SessionPointEntry;
  session2: SessionPointEntry;
  session3: SessionPointEntry;
}

// @todo change to work on an array of sessions.  we could show X ammount
const SessionPointsChart = ({ session1, session2, session3 }: SessionPointsProps) => {
  const [data, setData] = useState<SessionPointSeriesData>({
    xaxis: [],
    series1: [],
    series2: [],
    series3: [],
  });

  useEffect(() => {
    if (session1.points.length > 0) {
      // ensure we always have initial point data even if its blank
      const other1 = session2?.points || [{point: 1, count: 0}];
      const other2 = session3?.points || [{point: 1, count: 0}];
      const seriesData = get3SessionSeriesData(session1.points, other1, other2);
      setData(seriesData);
    }
  }, []);

  return (
    <Card sx={{ minWidth: "100%", borderRadius: 3 }}>
      <CardContent>
        <ReactApexChart
          options={{
            ...options,
            xaxis: {
              title: {
                text: "Points",
              },
              categories: data.xaxis,
            },
          }}
          series={[
            {
              name: session1?.name || "unknown session",
              data: data.series1,
            },
            {
              name: session2?.name || "unknown session",
              data: data.series2,
            },
            {
              name: session3?.name || "unknown session",
              data: data.series3,
            },
          ]}
          type="bar"
          height={350}
        />
      </CardContent>
    </Card>
  );
};

export default SessionPointsChart;
