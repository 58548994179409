import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import * as yup from "yup";

import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ShowSaving from "components/processing/ShowSaving";
import SetPointsDialogContent from "./SetPointsDialogContent";
import PointsDialogActions from "../DefaultDialogActions";
import { useSetStoryPointedMutation } from "api/sessions";
import { hideSetPointsForm } from "store/dialogs";
import { useAppDispatch, useAppSelector } from "store";
import { SetPointsDialogForm, PointsOptionsType } from "./types";
import { ComputeActualCardPoints } from "common/helper";
import { availablePointIndex } from "configs/playingCards";

const defaultValues = {
  customPoints: 0,
  usePoints: "",
};

// preselect mode or average?

/**
 * Form Validation Schema
 */
const schema = yup.object({
  usePoints: yup.string().required("You must choose a valid point value"),
  useCustom: yup.number(),
});

const iniitalPointValues: PointsOptionsType = {
  min: "0",
  max: "0",
  mean: "0",
  median: "0",
  mode: "0",
  dataFormated: false,
};

const SetPointsDialog = () => {
  // @todo move to selector function
  const setPointsDialogState = useAppSelector(({ dialogs }) => dialogs.setPointsForm);
  //
  // const pointValues = useAppSelector(GetPointsValues);
  const [pointValues, setPointValues] = useState(iniitalPointValues);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [saveStoryPoints, { isLoading: isSaving }] = useSetStoryPointedMutation();

  const { control, reset, handleSubmit, formState } = useForm<SetPointsDialogForm>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
    // const setPointsDialogState = useAppSelector(({ dialogs }) => dialogs.setPointsDialogState);
  }, [reset, setPointsDialogState.show]);

  useEffect(() => {
    if (setPointsDialogState.show) {
      let ourMode = "N/A";
      if (setPointsDialogState.mode > 0) {
        ourMode = ComputeActualCardPoints(setPointsDialogState.mode);
      }

      const formattedData = {
        min: ComputeActualCardPoints(setPointsDialogState.min),
        max: ComputeActualCardPoints(setPointsDialogState.max),
        mean: ComputeActualCardPoints(setPointsDialogState.mean),
        median: ComputeActualCardPoints(setPointsDialogState.median),
        mode: ourMode,
        dataFormated: false,
      };
      // add warning if data is formatted
      if (setPointsDialogState.mean.toString() != formattedData.mean || setPointsDialogState.median.toString() != formattedData.median) {
        formattedData.dataFormated = true;
      }

      setPointValues(formattedData);
    }
  }, [setPointsDialogState]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    dispatch(hideSetPointsForm());
  }

  /**
   * Submit Form
   */
  const onSubmit: SubmitHandler<FieldValues> = async (model) => {
    let points;
    if (model.usePoints === "custom") {
      if (model.customPoints > 0) {
        points = model.customPoints;
        const pointNum = parseInt(points);
        if (pointNum < 1) {
          enqueueSnackbar("You must enter a positive point value", {
            variant: "error",
          });
          return;
        } else if (!(points in availablePointIndex)) {
          enqueueSnackbar("You must enter a valid point value", {
            variant: "error",
          });
          return;
        }
      }
    } else if (model.usePoints == "min") {
      points = pointValues.min;
    } else if (model.usePoints == "max") {
      points = pointValues.max;
    } else if (model.usePoints == "mean") {
      points = pointValues.mean;
    } else if (model.usePoints == "median") {
      points = pointValues.median;
    } else if (model.usePoints == "mode") {
      points = pointValues.mode;
    }

    if (points === undefined) {
      enqueueSnackbar("You must enter a valid point value", {
        variant: "error",
      });
      return;
    }

    try {
      const result = await saveStoryPoints({
        sessionId: setPointsDialogState.sessionId,
        storyId: setPointsDialogState.storyId,
        points,
      }).unwrap();

      if (result) {
        if (result?.message != "no more stories") {
          // console.log("result", result);
          throw Error(result?.error);
        }
      }

      /*
      works first time.  not after that
      */

      enqueueSnackbar("Points Successfully Set", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Error Setting Points", {
        variant: "error",
      });
    }

    closeComposeDialog();
  };

  if (isSaving) {
    return <ShowSaving />;
  }

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      open={setPointsDialogState.show === null ? false : setPointsDialogState.show}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            <strong>Set Story Points</strong>
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col md:overflow-hidden">
        <SetPointsDialogContent control={control} formState={formState} data={pointValues} />
        <PointsDialogActions saveText="Save Points" formState={formState} onCancel={closeComposeDialog} />
      </form>
    </Dialog>
  );
};

export default SetPointsDialog;
