import React from "react";
import { Control, Controller, FieldValues, FormState } from "react-hook-form";
import { Theme } from "@emotion/react";
import { TextFieldVariants, SxProps } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

import { SelectOptions } from "common/types";

interface SelectInputProps {
  name: string;
  label?: string;
  variant?: TextFieldVariants;
  defaultValue: string | number;
  options: Array<SelectOptions>;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
}

const SelectInput = (props: SelectInputProps) => {
  const name = props.name;
  const label = props.label || false;
  const errorMessage = props.formState?.errors?.[name]?.message?.toString() || "";
  const sx = props?.sx || {};
  const variant = props?.variant || "outlined";
  const options = props.options;
  const labelId = `${name}-label`;
  const disabled = props.disabled || false;

  return (
    <FormControl fullWidth>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Controller
        render={({ field }) => (
          <>
            <Select
              {...field}
              labelId={labelId}
              label={label}
              error={errorMessage != ""}
              variant={variant}
              sx={sx}
              disabled={disabled}
            >
              {options.map((option: SelectOptions) => (
                <MenuItem value={option.id} key={`${name}-opt-${option.id}`}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>

            {errorMessage != "" && <FormHelperText>{errorMessage}</FormHelperText>}
          </>
        )}
        name={name}
        control={props.control}
        defaultValue={props.defaultValue}
      />
    </FormControl>
  );
};

export default SelectInput;
