import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";

interface RememberMeProps {
  control: Control<FieldValues>;
}

const RememberMeInput = (props: RememberMeProps) => {
  return (
    <Grid item xs={12} sm={6}>
      <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
        <Controller
          name="remember"
          control={props.control}
          render={({ field }) => (
            <FormControl>
              <FormControlLabel label="Remember Me" control={<Checkbox {...field} defaultChecked={true} />} />
            </FormControl>
          )}
        />
      </div>
    </Grid>
  );
};

export default RememberMeInput;
