import React from "react";
import { Control, FieldValues, FormState } from "react-hook-form";

import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";

import TextInput from "components/inputs/TextInput";

interface SessionStoryContentProps {
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
}
const SessionStoryContent = ({ control, formState }: SessionStoryContentProps) => {
  return (
    <DialogContent classes={{ root: "p-24" }}>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1 },
        }}
      >
        <TextInput control={control} formState={formState} label="Story Name" variant="outlined" name="name" required />
        <TextInput control={control} formState={formState} label="Link" variant="outlined" name="link" />
        <TextInput
          control={control}
          formState={formState}
          label="Summary"
          variant="outlined"
          name="summary"
          multiline={true}
          maxRows={3}
          minRows={3}
        />
      </Box>
    </DialogContent>
  );
};

export default SessionStoryContent;
