import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SessionData } from "common/types";

interface SessionDetailsProps {
  sessionData: SessionData;
}

const SessionDetails = ({ sessionData }: SessionDetailsProps) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Session Details
      </Typography>

      <Grid container>
        <Grid item xs={4}>
          <Typography gutterBottom>Name</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{sessionData.name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Organization</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{sessionData.orgName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Team</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{sessionData.teamName}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SessionDetails;
