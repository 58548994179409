import React from "react";
import { Link } from "react-router-dom";

import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import TableViewIcon from "@mui/icons-material/TableView";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import BaseStoryList from "./BaseStoryList";
import StoryDialog from "components/dialogs/StoryDialog";
import StoryName from "./StoryName";
import { showEditSessionStoryForm } from "store/dialogs";
import { Story, StoryTableColumnEntry } from "common/types";
import { useAppDispatch } from "store";

interface StoryDetailListtProps {
  limit?: number;
  showSort?: boolean;
  showPaging?: boolean;
}

const PendingStoriesList = ({ limit = 20, showPaging = true, showSort = true }: StoryDetailListtProps) => {
  const dispatch = useAppDispatch();
  const handleEdit = (storyId: number) => {
    console.log("show edit story popup");
    dispatch(
      showEditSessionStoryForm({
        storyId: storyId, //@todo could just pass in story data?
        editSession: true,
      })
    );
  };

  const columns: StoryTableColumnEntry[] = [
    {
      id: "name",
      align: "center",
      disablePadding: false,
      label: "Name",
      sort: true,
      custom: (data: Story) => {
        return (
          <Tooltip title={data.name}>
            <Typography>
              <StoryName name={data.name} link={data.link} />
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "summary",
      align: "center",
      disablePadding: false,
      label: "Summary",
      sort: false,
      custom: (data) => {
        return (
          <Typography
            sx={{
              maxWidth: 150,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.summary}
          </Typography>
        );
      },
    },
    {
      id: "teamName",
      align: "left",
      disablePadding: false,
      label: "Team",
      sort: true,
      custom: (data) => {
        return (
          <>
            <Typography variant="caption" color="text.secondary">
              {data.orgName}
            </Typography>
            <Typography>{data.teamName}</Typography>
          </>
        );
      },
    },
    {
      id: "sessionName",
      align: "center",
      disablePadding: false,
      label: "Session",
      sort: true,
      custom: (data: Story) => {
        const link = data.sessionId == 0 ? "" : `/pointing/${data.sessionId}`;
        return (
          <Tooltip title={data.sessionName}>
            <Typography>
              <StoryName name={data.sessionName as string} link={link} />
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "createdByName",
      align: "center",
      disablePadding: false,
      label: "Created By",
      sort: false,
    },
    {
      id: "updatedAt",
      align: "center",
      disablePadding: false,
      label: "Last updated",
      sort: true,
      custom: (data) => {
        const myDate = new Date(data.updatedAt as string);
        return <>{myDate.toLocaleString()}</>;
      },
    },
    {
      id: "actions",
      align: "center",
      disablePadding: false,
      label: "",
      sort: false,
      custom: (data) => {
        return (
          <>
            <IconButton
              aria-label="Edit"
              onClick={() => {
                handleEdit(data.storyId);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton>
              <TableViewIcon />
            </IconButton>
            <IconButton component={Link} to={`/pointing/${data.storyId}`}>
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <BaseStoryList status="pending" columns={columns} limit={limit} showPaging={showPaging} showSort={showSort} />
      <StoryDialog />
    </>
  );
};

export default PendingStoriesList;
