import React from "react";
import { Control, Controller, FieldValues, FormState } from "react-hook-form";

import TextField, { TextFieldVariants } from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material";

interface EmailInputProps {
  name?: string;
  label?: string;
  variant?: TextFieldVariants;
  sx?: SxProps<Theme>;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
  disabled?: boolean;
}

const EmailInput = (props: EmailInputProps) => {
  const name = props?.name || "email";
  const disabled = props?.disabled || false;
  const label = props?.label || "Email";
  const variant = props?.variant || "outlined";
  const sx = props?.sx || {};
  const errorMessage = props.formState?.errors?.[name]?.message?.toString() || "";

  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field }) => (
        <TextField
          {...field}
          className="mb-16"
          label={label}
          autoFocus
          type="email"
          error={errorMessage != ""}
          helperText={errorMessage}
          variant={variant}
          sx={sx}
          disabled={disabled}
          required
          fullWidth
        />
      )}
    />
  );
};

export default EmailInput;
