import { ApexOptions } from "apexcharts";

const options: ApexOptions = {
  chart: {
    type: "bar",
    height: 400,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "60%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    title: {
      text: "Times used",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return "Used " + val + " times";
      },
    },
    x: {
      formatter: function (val) {
        return "Chosen Point: " + val;
      },
    },
  },
  title: {
    text: "Session Point Occurrence",
    align: "center",
  },
};

export default options;
